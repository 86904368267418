import React from 'react';
import { Container, Row, Col, Badge, ProgressBar } from 'react-bootstrap';
import { BsCheckLg } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import '../../App.css';

function ProcessingScreen() {
  const overallState = useSelector((state) => state);
  const {
    trueId,
    personalInfo,
    contactInfo,
    residenceInfo,
    vehicleInfo,
    financingInfo,
    contractAck,
  } = overallState.processingStatus;
  const { processingStatus, otpProcess } = overallState;
  const processingColors = {
    begin: 'secondary',
    inProgress: 'primary',
    completed: 'success',
  };
  return (
    <Container >
      <Row>
        <Col md={{ span: 12, offset: 0 }}>
          <div className="row mb-4">
            <div
              className="d-flex justify-content-center flex-wrap"
              style={{ border: '1px solid transparent', padding: '5px' }}
            >
              {/* {otpProcess.TrueIDRequired==1 && <div key={1} className="d-flex ">
                <div >

                  <div id="statusBarBadge">
                    <Badge bg={"completed"}> <BsCheckLg /></Badge>
                  </div>
                  <div className="status-bar-title">
                    <small className="status-bar-font">
                      True Id
                    </small>
                  </div>
                </div>
                <div className="d-flex align-items-flex-end">
                  <ProgressBar
                    className="status-pipe"
                    variant={
                      "completed"
                    }
                    now={100}
                  />
                </div>
              </div>} */}
              {Object.values(processingStatus).map((processingStatusSub, index) => {
                return (
                  <div key={index + 1} className="d-flex ">
                    <div >
                      <div id="statusBarBadge">
                        <Badge bg={processingColors[
                          processingStatusSub.status
                        ]}>{(processingColors[
                          processingStatusSub.status
                        ] === "success") ? <BsCheckLg /> : index + 1}</Badge>
                      </div>
                      <div className="status-bar-title">
                        <small className="status-bar-font">
                          {processingStatusSub.title}
                        </small>
                      </div>
                    </div>
                    {(index !== 5) ?
                      <div className="d-flex align-items-flex-end">
                        <ProgressBar
                          className="status-pipe"
                          variant={
                            processingColors[
                            processingStatusSub.status
                            ]
                          }
                          now={100}
                        />
                      </div> : null
                    }

                  </div>
                );
              })}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProcessingScreen;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { postAllFormsInfo, saveResidenceInfoForm } from "../../Redux/nextForms/AllFormsActions";
import { getAllFormsData, saveAllFormsData } from "../../api/Services";
import AutoLoanContactInfo from "./AutoLoanContactInfo";
//import '../App.css'
import { useSelector, useDispatch } from "react-redux";
import NextStepsButton from "./NextStepsButton";
import ProcessingScreen from "./ProcessingScreen";
import { setResidenceInfoStatus } from "../../Redux/nextForms/NextFormActions";
import {  getRDPEventLogger } from '../../api/Services';
import NotificationBar from "./NotificationBar";

const ResidenceInfo = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const dispatch = useDispatch();
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { residenceInfoForm } = allFormsInfo;
  const { ApplicationNumber, ApplicantID } = otpProcess;
  const [residenceStatus, setResidenceStatus] = useState(residenceInfoForm.residenceStatus);
  const [residenceStatusOther, setResidenceStatusOther] = useState(residenceInfoForm.residenceStatusOther);
  const [monthlyPayment, setMonthlyPayment] = useState(residenceInfoForm.monthlyRentPayment);
  const [amountResposible, setAmountResponsible] = useState(residenceInfoForm.amountResponsibleFor);
  const [isEntireMonthlyPayment, setIsEntireMonthlyPayment] = useState(residenceInfoForm.isEntireMonthlyPayment);
  const [showMessage, setShowMessage] = useState(false);

  const [errors, setErrors] = useState({
    residenceStatus: "",
    residenceStatusOther: '',
    monthlyPayment: '',
    amountResposible: '',
    isEntireMonthlyPayment: '',
  });
  const onPreviousClick = () => {
    history.push('/contactinfo')
  }
  useEffect(() => {
    setTimeout(function () {
  setShowMessage(false);
}, 2000);
}, [showMessage]);
  const saveResidenceInfoForm = async (currentPage) => {

    const residenceInfo = {
      residenceStatus,
      residenceStatusOther: residenceStatus === "7" ? residenceStatusOther : '',
      // responsibleForEntirePayment: '',

      monthlyRentPayment: monthlyPayment,
      amountResponsibleFor: amountResposible,
      isEntireMonthlyPayment,

    };

    const formsData = {
      ...allFormsInfo,
      residenceInfoForm: residenceInfo
    };
    const payload = {
      allFormsInfo: formsData,
      currentPage,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({
        ...allFormsInfo,
        residenceInfoForm: residenceInfo
      })
    );
    const response = await saveAllFormsData(payload);
    return response;
  }
  const onSaveClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "residenceInfoSaved",
      eventDescription: '',
      eventData: "",
  }
    rdpEventHandler(eventPayload)

    saveResidenceInfoForm('residenceInfo').then(res => {
      if (res === "Success") {
        setShowMessage(true)
      }
    });
  }

  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
        console.log(eventPayload.eventName);
    });
}
  const onNextClick = (e) => {
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "residenceInfoCompleted",
      eventDescription: '',
      eventData: "",
  }
    e.preventDefault();
    if (validate()) {
      rdpEventHandler(eventPayload)

      saveResidenceInfoForm('vehicleinfo').then(res => {
        const { residenceInfo: { status } } = processingStatus;
        if (status !== "completed") {
          dispatch(setResidenceInfoStatus("completed"));
        }
        eventPayload.eventName='vehicleInfoStarted'
        rdpEventHandler(eventPayload)
        history.push('/vehicleinfo')

      });

    }
  }




  const validate = () => {
    const errors = {};


    if (!residenceStatus) {
      errors.residenceStatus = "Residence Status required.";
    }
    if (residenceStatus === "7" && !residenceStatusOther) {
      errors.residenceStatusOther = "Residence Status Other required.";
    }

    // if (!residenceStatusOther) {
    //   errors.residenceStatusOther = "Please enter your Residence Status";
    // } else if (residenceStatusOther.length < 2) {
    //   errors.residenceStatusOther = "Must be 2 characters or more";
    // }
    if (!monthlyPayment) {
      errors.monthlyPayment = "Monthly payment required.";
    }
    if (isEntireMonthlyPayment == '' || isEntireMonthlyPayment === undefined) {
      // errors.isEntireMonthlyPayment = 'Selection required'
    }
    // if (!isEntireMonthlyPayment) {
    //   errors.model = "Please enter your vehicle model";
    // }

    // if (!amountResposible) {
    //   errors.amountResposible = "Required amount responsible";
    // }

    setErrors(errors)

    if (errors.residenceStatus || errors.residenceStatusOther || errors.monthlyPayment || errors.isEntireMonthlyPayment) {
      return false;
    }

    return true
  };

  return (
    <Container className='min-vh-100'>
      <div className='mb-3'>
        <ProcessingScreen />
        <Row>
          <h3 className='mt-3 mb-4'> Residence Information Form</h3>
        </Row>

        <Row className=''>
          <Col md={{ span: 6, offset: 3 }} style={{ marginBottom: "3rem" }}>
            <Form>
              <AutoLoanContactInfo />
              {/* <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <Form.Label className='formLabel'>
                    <small>
                      Please bare with us as we confirm information from your Auto
                      Loan Contracts. Having your contract available will speed up
                      this process
                    </small>
                  </Form.Label>
                </Form.Group>
              </Row> */}

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='residenceStatus'>
                  <Form.Label className='formLabel'>
                    What is your residence status ?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Select
                    name='residenceStatus'
                    // placeholder="Choose an option"
                    value={residenceStatus}
                    onChange={(e) => {
                      setResidenceStatus(e.target.value);
                      setErrors({ ...errors, residenceStatus: !e.target.value ? 'Please select residence' : '' })
                    }}

                    data-id='residencestatus'
                    // onBlur={formik.handleBlur}
                    style={{ display: "block" }}
                  >
                    <option value=''  >Choose an option</option>
                    <option value='1'  >Family</option>
                    <option value='2' >Live with Relatives</option>
                    <option value='3' >Military</option>
                    <option value='4' >Mortgage</option>
                    <option value='7' >Other</option>
                    <option value='8' >Own</option>
                    <option value='5' >Rent</option>
                    <option value='6' >Undefined</option>
                  </Form.Select>

                  <div className='invalid-feedback1 formLabel'>
                    {errors.residenceStatus}
                  </div>
                </Form.Group>
              </Row>
              {residenceStatus === "7" && <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='residenceStatusOther'>
                  <Form.Label className='formLabel'>
                    Residence Status Other - Please describe.
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='residenceStatusOther'
                    type='text'
                    onChange={(e) => {
                      setResidenceStatusOther(e.target.value);
                      setErrors({ ...errors, residenceStatusOther: !e.target.value ? 'Please enter Residence Status.' : '' })
                    }}
                    value={residenceStatusOther}
                    data-id='residencestatusother'
                  />
                  <div className='invalid-feedback1 formLabel'>

                    {errors.residenceStatusOther}

                  </div>
                </Form.Group>
              </Row>}
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='monthlyPayment'>
                  <Form.Label className='formLabel'>
                    What is your monthly payment amount ?<span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='monthlyPayment'
                    type='number'
                    onChange={(e) => {
                      setMonthlyPayment(e.target.value);
                      setErrors({ ...errors, monthlyPayment: !e.target.value ? 'Please enter monthly payment.' : '' })
                    }}
                    data-id='montlypayment'
                    value={monthlyPayment}
                  />
                  <div className='invalid-feedback1 formLabel'>

                    {errors.monthlyPayment}
                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} >

                  <Form.Label className='formLabel' md='8'>
                    Are you responsible for the entire monthly payment?
                  </Form.Label>
                  <div className='d-flex'>
                    <Form.Check
                      name='isEntireMonthlyPayment'
                      type='radio'
                      label='Yes'
                      id='responsible'
                      value='Yes'
                      checked={isEntireMonthlyPayment == 'yes' ? true : false}
                      onChange={(e) => {
                        setIsEntireMonthlyPayment('yes')
                        // setErrors({ ...errors, isEntireMonthlyPayment: !e.target.value ? 'Selection Required' : '' })
                          ;
                      }}
                      // className='mt-4'
                      style={{ display: "inline-block", paddingRight: "25px" }}

                    />
                    <Form.Check
                      name='isEntireMonthlyPayment'
                      type='radio'
                      label='No'
                      id='notResponsible'
                      value='No'
                      style={{ display: "inline-block", paddingRight: "20px" }}
                      onChange={(e) => {
                        setIsEntireMonthlyPayment("No")
                      }}
                      checked={isEntireMonthlyPayment == 'No' ? true : false}
                    />

                  </div>
                  <div className='invalid-feedback1 formLabel'>

                    {errors.isEntireMonthlyPayment}
                  </div>
                </Form.Group>
              </Row>
              {isEntireMonthlyPayment === "No" && <>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='lastName'>
                    <Form.Label className='formLabel'>
                      How much are you responsible for?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      placeholder=''
                      name='rentAmountResponsibleFor'
                      type='number'
                      onChange={(e) => {
                        setAmountResponsible(e.target.value);
                        setErrors({ ...errors, amountResposible: !e.target.value ? 'Please enter amount' : '' })
                      }}
                      data-id='amountresposible'
                      value={amountResposible}
                    />
                    <div className='invalid-feedback1 formLabel'>

                      {errors.amountResposible}

                    </div>
                  </Form.Group>
                </Row>
              </>}

              <Row style={{ marginBottom: '10px' }}>
                <Col className='d-flex justify-content-between'>
                  <Button type='submit' onClick={onSaveClick}>Save</Button>
                </Col>
              </Row>
              {showMessage && <Row>
              <NotificationBar variant="success" message="Your data is saved!" />
            </Row>}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
            <NextStepsButton text="Previous" isDisable={false} onNextClick={onPreviousClick} />
            <NextStepsButton text="Next" isDisable={false} onNextClick={onNextClick} />
          </Col>
        </Row>
      </div>
    </Container >
  );
};
export default ResidenceInfo;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
//import axios from "axios";
//import '../App.css'
import AutoLoanContactInfo from "./AutoLoanContactInfo";

import { setFinancingInfoStatus } from "../../Redux/nextForms/NextFormActions";
import { getAllFormsData, getMakeModel, saveAllFormsData } from "../../api/Services";
import {
  saveFinancingInfoForm,
  postAllFormsInfo,
} from "../../Redux/nextForms/AllFormsActions";
import { useSelector, useDispatch } from "react-redux";
import ProcessingScreen from "./ProcessingScreen";
import NextStepsButton from "./NextStepsButton";
import { getRDPEventLogger } from '../../api/Services';
import NotificationBar from "./NotificationBar";


const FinancingInfo = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const dispatch = useDispatch();

  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { financingInfoForm, personalInfoForm } = allFormsInfo;

  const { ApplicationNumber, ApplicantID, DealerState } = otpProcess;
  const [contractSignLocation, setContractSignLocation] = useState(financingInfoForm.contractSignLocation);

  const [downPaymentAmount, setDownPaymentAmount] = useState(financingInfoForm.downPaymentAmount);
  const [isPaidInFull, setIsPaidInFull] = useState(financingInfoForm.isPaidInFull);
  const [isRebateObatined, setIsRebateObatined] = useState(financingInfoForm.isRebateObatined);
  const [rebateAmount, setRebateAmount] = useState(financingInfoForm.rebateAmount);
  const [isCashDownFromRebate, setIsCashDownFromRebate] = useState(financingInfoForm.isCashDownFromRebate);
  const [isoweDownPayment, setIsoweDownPayment] = useState(financingInfoForm.isoweDownPayment);
  const [xDeferredDownPayment, setXDeferredDownPayment] = useState(financingInfoForm.xDeferredDownPayment);
  const [oweDownPaymentToWhom, setOweDownPaymentToWhom] = useState(financingInfoForm.oweDownPaymentToWhom);

  const [isPostOrHoldCheck, setIsPostOrHoldCheck] = useState(financingInfoForm.isPostOrHoldCheck);
  const [holdCheckAmount, setHoldCheckAmount] = useState(financingInfoForm.holdCheckAmount);
  const [holdCheckDate, setHoldCheckDate] = useState(financingInfoForm.holdCheckDate);
  const [isSalesTaxAcknowledgement, setIsSalesTaxAcknowledgement] = useState(financingInfoForm.isSalesTaxAcknowledgement);
  const [isPennDotSalesTax, setIsPennDotSalesTax] = useState(financingInfoForm.isPennDotSalesTax);
  const [isTradeInVehicle, setIsTradeInVehicle] = useState(financingInfoForm.isTradeInVehicle);
  const [tradeInYear, setTradeInYear] = useState(financingInfoForm.tradeInYear);
  const [tradeInMake, setTradeInMake] = useState(financingInfoForm.tradeInMake);
  const [tradeInModel, setTradeInModel] = useState(financingInfoForm.tradeInModel);
  const [tradeInLienholder, setTradeInLienholder] = useState(financingInfoForm.tradeInLienholder);
  const [fullPaymentDate, setFullPaymentDate] = useState(financingInfoForm.fullPaymentDate);
  const [amountNotPaid, setAmountNotPaid] = useState(financingInfoForm.amountNotPaid);
  const [whenWillItBePaid, setWhenWillItBePaid] = useState(financingInfoForm.whenWillItBePaid)
  let optionYears = [
    { year: 2029, id: 43, vinyearcode: "W" },
    { year: 2028, id: 44, vinyearcode: "V" },
    { year: 2027, id: 45, vinyearcode: "T" },
    { year: 2026, id: 46, vinyearcode: "S" },
    { year: 2025, id: 47, vinyearcode: "R" },
    { year: 2024, id: 48, vinyearcode: "P" },
    { year: 2023, id: 49, vinyearcode: "N" },
    { year: 2022, id: 1, vinyearcode: "M" },
    { year: 2021, id: 2, vinyearcode: "L" },
    { year: 2020, id: 3, vinyearcode: "K" },
    { year: 2019, id: 4, vinyearcode: "J" },
    { year: 2018, id: 5, vinyearcode: "H" },
    { year: 2017, id: 6, vinyearcode: "G" },
    { year: 2015, id: 7, vinyearcode: "F" },
    { year: 2014, id: 8, vinyearcode: "E" },
    { year: 2013, id: 9, vinyearcode: "D" },
    { year: 2012, id: 10, vinyearcode: "C" },
    { year: 2011, id: 11, vinyearcode: "B" },
    { year: 2010, id: 12, vinyearcode: "A" },
    { year: 2009, id: 13, vinyearcode: "9" },
    { year: 2008, id: 14, vinyearcode: "8" },
    { year: 2007, id: 15, vinyearcode: "7" },
    { year: 2006, id: 16, vinyearcode: "6" },
    { year: 2005, id: 17, vinyearcode: "5" },
    { year: 2004, id: 18, vinyearcode: "4" },
    { year: 2003, id: 19, vinyearcode: "3" },
    { year: 2002, id: 20, vinyearcode: "2" },
    { year: 2001, id: 21, vinyearcode: "1" },
    { year: 2000, id: 22, vinyearcode: "Y" },
    { year: 1999, id: 23, vinyearcode: "X" },
    { year: 1998, id: 24, vinyearcode: "W" },
    { year: 1997, id: 25, vinyearcode: "V" },
    { year: 1996, id: 26, vinyearcode: "T" },
    { year: 1995, id: 27, vinyearcode: "S" },
    { year: 1994, id: 28, vinyearcode: "R" },
    { year: 1993, id: 29, vinyearcode: "P" },
    { year: 1992, id: 30, vinyearcode: "N" },
    { year: 1991, id: 31, vinyearcode: "M" },
    { year: 1990, id: 32, vinyearcode: "L" },
    { year: 1989, id: 33, vinyearcode: "K" },
    { year: 1988, id: 34, vinyearcode: "J" },
    { year: 1987, id: 35, vinyearcode: "H" },
    { year: 1986, id: 36, vinyearcode: "G" },
    { year: 1995, id: 37, vinyearcode: "F" },
    { year: 1984, id: 38, vinyearcode: "E" },
    { year: 1983, id: 39, vinyearcode: "D" },
    { year: 1982, id: 40, vinyearcode: "C" },
    { year: 1981, id: 41, vinyearcode: "B" },
    { year: 1980, id: 42, vinyearcode: "A" },

  ];
  const [optionYear, setOptionYear] = useState([]);

  const [makeModels, setMakeModels] = useState([]);
  const [filteredModels, setFilteredModels] = useState([]);

  const [maxDate, setMaxDate] = useState(new Date());
  const [errors, setErrors] = useState({
    contractSignLocation: "",
    downPaymentAmount: '',
    isTradeInVehicle: '',
    isRebateObatined: '',
    xDeferredDownPayment: '',
    oweDownPaymentToWhom: '',
    whenWillItBePaid:''
  });
  const [showMessage, setShowMessage] = useState(false);

  const onPreviousClick = () => {
    history.push("/vehicleinfo")
  }

  useEffect(() => {
    let currentYear = new Date()
    let filteredYears = optionYears.filter(item => item.year <= currentYear.getFullYear());
    if (tradeInYear) {
      let selectedYear = optionYears.find(item => item.year === Number(tradeInYear));
      getUniqueMakeModels(selectedYear.vinyearcode)
    }
    setOptionYear(filteredYears)
  }, []);

  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 2000);
  }, [showMessage]);

  const tradeInYearHandler = (e) => {
    const index = e.target.selectedIndex;
    const ele = e.target.childNodes[index];
    const code = ele.getAttribute('id');
    const value = ele.getAttribute('value')
    if (e.target.value === "")
      return;
    setTradeInYear(value)
    setTradeInModel('')
    setTradeInMake('')
    getUniqueMakeModels(code)
  }
  const getUniqueMakeModels = (code) => {
    const payload = {
      code
    }
    getMakeModel(payload).then(res => {
      if (res) {
        let uniqueMakeModels = [];
        res.map((list) => {
          if (!(uniqueMakeModels).some((x) => x.make == list.make)) {
            let makeModelObject = {
              make: '', model: []
            }
            makeModelObject.make = list.make
            uniqueMakeModels.push(makeModelObject)
          }
        });
        uniqueMakeModels.map(makemodel => {
          let filteredmakes = res.filter(makeModelList => {
            return makemodel.make == makeModelList.make
          })
          filteredmakes.map(x => makemodel.model.push(x.model));
        })
        if (tradeInMake) {
          let models = uniqueMakeModels.find((makeModel) => {
            return makeModel.make == tradeInMake
          })
          setFilteredModels(models ? models.model : [])
        }
        setMakeModels(uniqueMakeModels)
      }
    }

    );
  }


  const tradeInMakeHandler = (e) => {
    setTradeInMake(e.target.value)
    var models = makeModels.filter((makeModel) => {
      return makeModel.make == e.target.value
    })
    setFilteredModels(models[0].model)

  }

  const saveFinancingInfoForm = async (currentPage) => {

    const financingInfo = {
      contractSignLocation,
      downPaymentAmount,
      isPaidInFull,
      whenWillItBePaid,
      amountNotPaid,
      isRebateObatined,
      rebateAmount,
      isCashDownFromRebate,
      isoweDownPayment,
      xDeferredDownPayment,
      oweDownPaymentToWhom,
      isPostOrHoldCheck,
      holdCheckAmount,
      holdCheckDate,
      isSalesTaxAcknowledgement,
      isPennDotSalesTax,
      isTradeInVehicle,
      tradeInYear,
      tradeInMake,
      tradeInModel,
      tradeInLienholder,

    };

    const formsData = {
      ...allFormsInfo,
      financingInfoForm: financingInfo
    };
    const payload = {
      allFormsInfo: formsData,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({
        ...allFormsInfo,
        financingInfoForm: financingInfo
      })
    );
    const response = await saveAllFormsData(payload);
    return response;
  }

  const onSaveClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "financingInfoSaved",
      eventDescription: '',
      eventData: "",
    }
    rdpEventHandler(eventPayload)
    saveFinancingInfoForm('financinginfo').then(res => {
      if (res === "Success") {
        setShowMessage(true);
      }
    });
  }
  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
    });
  }
  const onNextClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "financingInfoCompleted",
      eventDescription: '',
      eventData: "",
    }
    if (validate()) {
      rdpEventHandler(eventPayload)
      saveFinancingInfoForm('contractack').then(res => {
        if (res === "Success") {
          const { financingInfo: { status } } = processingStatus;
          if (status !== "completed") {
            dispatch(setFinancingInfoStatus("completed"));
          }
          eventPayload.eventName = 'contractInfoStarted'
          rdpEventHandler(eventPayload)
          history.push("/contractack")
        }
      });
    }
  }
  const validate = () => {
    debugger
    const errors = {};
    if (!contractSignLocation) {
      errors.contractSignLocation = "Please select signed Location.";
    }

    if (!downPaymentAmount) {
      errors.downPaymentAmount = "Please enter downpayment amount";
    }
    if (isRebateObatined === "" || isRebateObatined === undefined) {
      errors.isRebateObatined = "Selection Required";
    }
    if (isTradeInVehicle === "" || isTradeInVehicle === undefined) {
      errors.isTradeInVehicle = "Selection Required";
    }
    if (isoweDownPayment === 'Yes' &&DealerState == 'CA' && !xDeferredDownPayment) {
      errors.xDeferredDownPayment = "Selection Required";
    }
    if (isoweDownPayment === 'Yes'&& !oweDownPaymentToWhom) {
      errors.oweDownPaymentToWhom = "Selection Required";
    }
    if(!isoweDownPayment){
      errors.isoweDownPayment="Selection Required";
    }
    if (!isPaidInFull) {
      errors.isPaidInFull = "Selection Required";
    }
    if(isPaidInFull === 'No' && !whenWillItBePaid){
      errors.whenWillItBePaid = "Please enter payment date";

    }
    setErrors(errors)
    if (errors.contractSignLocation || errors.downPaymentAmount ||errors.whenWillItBePaid||errors.isoweDownPayment|| errors.oweDownPaymentToWhom || errors.isPaidInFull || errors.isRebateObatined || errors.isTradeInVehicle || errors.xDeferredDownPayment) {
      return false;
    }
    return true
  };

  const handleDateChange = (event) => {
    const input = event.target.value;
    let formattedDate = input;

    // Remove any forward slashes from the input
    formattedDate = formattedDate.replace(/\//g, '');

    // Add forward slashes in the appropriate positions (e.g., MM/DD/YYYY)
    if (formattedDate.length > 1) {
      formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
    }
    if (formattedDate.length > 4) {
      formattedDate = formattedDate.slice(0, 5) + '/' + formattedDate.slice(5, 9);
    }
    return formattedDate;
    //setErrors({ ...errors, selectsedDate: !formattedDate ? 'Please select DOB' : '' })

  };
  return (
    <Container className='min-vh-100'>
      <div className='mb-3'>
        <ProcessingScreen />
        <Row>
          <h3 className='mt-3 mb-4'> Financing Information Form</h3>
        </Row>
        <Row className='' style={{ marginBottom: "3rem" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Form >
              <AutoLoanContactInfo />

              {/* <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <Form.Label className='formLabel'>
                    <small>
                      Please bare with us as we confirm information from your Auto
                      Loan Contracts. Having your contract available will speed up
                      this process
                    </small>
                  </Form.Label>
                </Form.Group>
              </Row> */}

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId=''>
                  <Form.Label className='formLabel'>
                    Where did you sign the contract?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Select
                    name='contractSignLocation'
                    value={contractSignLocation}
                    onChange={(e) => {
                      setContractSignLocation(e.target.value)
                      setErrors({ ...errors, contractSignLocation: !e.target.value ? 'Selection Required' : '' })
                    }}
                    style={{ display: "block" }}
                  >
                    <option value='' >Select an option</option>
                    <option value='2'>In Dealership State</option>
                    <option value='3' >Out Of State</option>
                  </Form.Select>
                  <div className='invalid-feedback1 formLabel'>
                    {errors.contractSignLocation}
                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='downPaymentAmount'>
                  <Form.Label className='formLabel'>
                    What was the amount of your cash down payment, if any?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='downPaymentAmount'
                    type='number'
                    value={downPaymentAmount}
                    onChange={(e) => {
                      setDownPaymentAmount(e.target.value)
                      setErrors({ ...errors, downPaymentAmount: !e.target.value ? 'Please enter Downpayment amount' : '' })
                    }
                    }
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.downPaymentAmount}
                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Have you  paid the down payment in full? If you still owe a portion of the down payment, then choose No.
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='isPaidInFull'
                        type='radio'
                        label="Yes"
                        id='mobile'
                        checked={isPaidInFull === 'Yes' ? true : false}
                        onChange={(e) => {
                          setIsPaidInFull('Yes');
                          setErrors({ ...errors, isPaidInFull: !e.target.value ? 'Selection Required' : '' })
                        }}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='isPaidInFull'
                        type='radio'
                        label="No"
                        id='mobile'
                        checked={isPaidInFull === 'No' ? true : false}
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        onChange={(e) => {
                          setIsPaidInFull('No');
                          setErrors({ ...errors, isPaidInFull: !e.target.value ? 'Selection Required' : '' })
                        }}
                      // className='mt-4'
                      />
                    </div>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.isPaidInFull}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              {isPaidInFull === 'No' && <><Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      When will it be paid?<span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Control
                        placeholder='MM/DD/YYYY'
                        name='dob'
                        type='text'
                        data-id='dpicker'
                        value={whenWillItBePaid}
                        onChange={(e) => {
                          const formatteddate = handleDateChange(e); setWhenWillItBePaid(formatteddate);
                          setErrors({ ...errors, whenWillItBePaid: !formatteddate ? 'Please enter payment Date' : '' })
                        }}
                      />
                    </div>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.whenWillItBePaid}
                    </div>
                  </div>
                </Form.Group>
              </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12'>
                    <div
                      id='radioGroup1'
                      role='group'
                      aria-labelledby='my-radio-group'
                    >
                      <Form.Label className='formLabel'>
                        Amount not Paid?                      <span className='star-required'>&nbsp;</span>
                      </Form.Label>
                      <div className='d-flex'>

                        <Form.Control
                          placeholder=''
                          name='amountNotPaid'
                          type='number'
                          value={amountNotPaid}
                          onChange={(e) => {
                            setAmountNotPaid(e.target.value)
                          }
                          }
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </>}
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Did you receive a rebate that was applied toward the purchase of the vehicle?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='isRebateObtained'
                        type='Radio'
                        label="Yes"
                        id='mobile'
                        checked={isRebateObatined == 'Yes' ? true : false}
                        onChange={(e) => {
                          setIsRebateObatined('Yes')
                          setErrors({ ...errors, isRebateObatined: !e.target.value ? 'Selection Required' : '' })
                        }
                        }
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='isRebateObtained'
                        type='Radio'
                        label="No"
                        id='mobile'
                        checked={isRebateObatined == 'No' ? true : false}
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        onChange={(e) => {
                          setIsRebateObatined('No')
                          setErrors({ ...errors, isRebateObatined: !e.target.value ? 'Selection Required' : '' })
                        }}
                      // className='mt-4'
                      />
                    </div>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.isRebateObatined}
                    </div>
                  </div>
                </Form.Group>
              </Row>

              {isRebateObatined === 'Yes' && <>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='rebateAmount'>
                    <Form.Label className='formLabel'>
                      What was the amount of the rebate?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      placeholder=''
                      name='rebateAmount'
                      type='number'
                      // disabled={!isRebateObatined}
                      onChange={(e) => setRebateAmount(e.target.value)}
                      // onBlur={formik.handleBlur}
                      value={rebateAmount}
                    />
                    <div className='invalid-feedback1 formLabel'>
                      {/* {errors.rebateAmount} */}
                    </div>
                  </Form.Group>
                </Row>

              </>}
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Did your cash down payment include the rebate amount, if any?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='isCashDownFromRebate'
                        type='radio'
                        label="Yes"
                        id='mobile'
                        onChange={() => setIsCashDownFromRebate('Yes')}
                        checked={isCashDownFromRebate === 'Yes' ? true : false}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='isCashDownFromRebate'
                        type='radio'
                        label="No"
                        id='mobile'
                        onChange={() => setIsCashDownFromRebate('No')}
                        checked={isCashDownFromRebate === 'No' ? true : false}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Do you owe any portion of the down payment to anyone?
                      <span className='star-required'>&nbsp;*</span>

                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='oweDownPayment'
                        type='radio'
                        label="Yes"
                        id='mobile'
                        checked={isoweDownPayment === 'Yes' ? true : false}
                        onChange={(e) => {setIsoweDownPayment('Yes');setErrors({ ...errors, isoweDownPayment: !e.target.value ? 'Selection Required' : '' })  }}
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='oweDownPayment'
                        type='radio'
                        label="No"
                        id='mobile'
                        style={{ display: "inline-block", paddingRight: "25px" }}

                        checked={isoweDownPayment === 'No' ? true : false}
                        onChange={(e) => {setIsoweDownPayment('No');setErrors({ ...errors, isoweDownPayment: !e.target.value ? 'Selection Required' : '' }) }}
                      />
                    </div>
                  </div>

                  <div className='invalid-feedback1 formLabel'>
                      {errors.isoweDownPayment}

                    </div>
                </Form.Group>
              </Row>
              {isoweDownPayment === 'Yes' && <>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='oweDownPaymentToWhom'>
                    <Form.Label className='formLabel'>
                      Who?<span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Control
                      placeholder=''
                      name='oweDownPaymentToWhom'
                      type='text'
                      onChange={(e) => { setOweDownPaymentToWhom(e.target.value); setErrors({ ...errors, oweDownPaymentToWhom: !e.target.value ? 'Selection Required' : '' }) }}
                      value={oweDownPaymentToWhom}
                    />
                    <div className='invalid-feedback1 formLabel'>
                      {errors.oweDownPaymentToWhom}

                    </div>
                  </Form.Group>
                </Row>
              </>
              }
              {isoweDownPayment === 'Yes' && DealerState === 'CA' && (
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12'>
                    <div
                      id='radioGroup1'
                      role='group'
                      aria-labelledby='my-radio-group'
                    >
                      <Form.Label className='formLabel'>
                        Is the money owed for a Deferred Down Payment Plan?
                        <span className='star-required'>&nbsp;*</span>

                      </Form.Label>
                      <div className='d-flex'>
                        <Form.Check
                          name='xDeferredDownPayment'
                          type='radio'
                          label="Yes"
                          id='mobile'
                          checked={xDeferredDownPayment === 'Yes' ? true : false}
                          onChange={() => {
                            setXDeferredDownPayment('Yes');
                            setErrors({ ...errors, xDeferredDownPayment: '' })
                          }}
                          style={{ display: "inline-block", paddingRight: "25px" }}
                        />
                        <Form.Check
                          name='xDeferredDownPayment'
                          type='radio'
                          label="No"
                          id='mobile'
                          style={{ display: "inline-block", paddingRight: "25px" }}

                          checked={xDeferredDownPayment === 'No' ? true : false}
                          onChange={() => {
                            setXDeferredDownPayment('No');
                            setErrors({ ...errors, xDeferredDownPayment: '' })
                          }}
                        />
                      </div>
                      <div className='invalid-feedback1 formLabel'>
                        {errors.xDeferredDownPayment}
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              )}


              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Did you pay any portion of your down payment with a post-dated check?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='isPostOrHoldCheck'
                        type='radio'
                        label="Yes"
                        id='mobile'
                        checked={isPostOrHoldCheck === 'Yes' ? true : false}
                        onChange={() => setIsPostOrHoldCheck('Yes')}
                        style={{ display: "inline-block", paddingRight: "25px" }}

                      />
                      <Form.Check
                        name='isPostOrHoldCheck'
                        type='radio'
                        label="No"
                        id='mobile'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        checked={isPostOrHoldCheck === 'No' ? true : false}
                        onChange={() => setIsPostOrHoldCheck('No')}
                      />

                    </div>
                  </div>
                </Form.Group>
              </Row>

              {isPostOrHoldCheck === 'Yes' && <>

                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='holdCheckAmount'>
                    <Form.Label className='formLabel'>
                      Hold Check Amount
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      placeholder=''
                      name='holdCheckAmount'
                      type='number'
                      onChange={(e) => setHoldCheckAmount(e.target.value)}
                      // onBlur={formik.handleBlur}
                      value={holdCheckAmount}
                    />
                    <div className='invalid-feedback1 formLabel'>
                      {/* {" "}
                    {formik.touched.holdCheckAmount &&
                      formik.errors.holdCheckAmount ? (
                      <div>{formik.errors.holdCheckAmount}</div>
                    ) : null}{" "} */}
                    </div>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='holdCheckDate'>
                    <Form.Label className='formLabel'>
                      Hold Check Date<span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      placeholder='MM/DD/YYYY'
                      name='dob'
                      type='text'
                      data-id='dpicker'
                      value={holdCheckDate}
                      onChange={(e) => { const formatteddate = handleDateChange(e); setHoldCheckDate(formatteddate) }}
                    />
                    {/* <DatePicker
                      placeholderText='mm/dd/yyyy'
                      // selected={formik.values.holdCheckDate}
                      dateFormat='MM/DD/YYYY'
                      className='form-control'
                      name='holdCheckDate'
                      maxDate={maxDate}
                      selected={holdCheckDate ? new Date(holdCheckDate) : null}
                      onChange={(date) =>
                        setHoldCheckDate(date)
                      }
                      value={holdCheckDate}
                    /> */}

                  </Form.Group>
                </Row>
              </>
              }
              {/* <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Do you acknowledge that you are responsible for paying sales tax and it is not financed?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='SalesTaxAcknowledgement'
                        type='radio'
                        label="Yes"
                        id='mobile'
                        checked={isSalesTaxAcknowledgement === 'Yes' ? true : false}
                        onChange={() => setIsSalesTaxAcknowledgement('Yes')}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='SalesTaxAcknowledgement'
                        type='radio'
                        label="No"
                        id='mobile'
                        checked={isSalesTaxAcknowledgement === 'No' ? true : false}
                        onChange={() => setIsSalesTaxAcknowledgement('No')}
                        style={{ display: "inline-block", paddingRight: "25px" }}

                      />

                    </div>
                  </div>
                </Form.Group>
              </Row> */}

              {personalInfoForm.state == 'PA' && <>

                <Row className='mb-3'>
                  <Form.Group as={Col} md='12'>
                    <div
                      id='radioGroup1'
                      role='group'
                      aria-labelledby='my-radio-group'
                    >
                      <Form.Label className='formLabel'>
                        Did you write a check or money order to PennDot for Sales
                        Tax?
                        <span className='star-required'>&nbsp;</span>
                      </Form.Label>

                      <div className='d-flex'>

                        <Form.Check
                          name='checkForPennDotSalesTax'
                          type='radio'
                          label='Yes'
                          id='mobile'
                          checked={isPennDotSalesTax}
                          onChange={() => setIsPennDotSalesTax(true)}
                          // className='mt-4'
                          style={{ display: "inline-block", paddingRight: "25px" }}
                        />
                        <Form.Check
                          name='checkForPennDotSalesTax'
                          type='radio'
                          label='No'
                          id='mobile'
                          style={{ display: "inline-block", paddingRight: "25px" }}
                          checked={!isPennDotSalesTax}
                          onChange={() => setIsPennDotSalesTax(false)}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Row>
              </>
              }
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >

                    <Form.Label className='formLabel'>
                      Did you trade in a vehicle?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='didTradeInVehicle'
                        type='radio'
                        label='Yes'
                        id='mobile'
                        // value='Yes'
                        checked={isTradeInVehicle === 'Yes' ? true : false}
                        onChange={(e) => {
                          setIsTradeInVehicle('Yes')
                          setErrors({ ...errors, isTradeInVehicle: !e.target.value ? 'Selection Required' : '' })
                        }
                        }
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='didTradeInVehicle'
                        type='radio'
                        label='No'
                        id='mobile'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        checked={isTradeInVehicle === 'No' ? true : false}
                        onChange={(e) => {
                          setIsTradeInVehicle('No')
                          setErrors({ ...errors, isTradeInVehicle: !e.target.value ? 'Selection Required' : '' })
                        }}
                      />
                    </div>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.isTradeInVehicle}
                    </div>
                  </div>
                </Form.Group>
              </Row>

              {isTradeInVehicle === 'Yes' && <>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='tradeInYear'>
                    <Form.Label className='formLabel'>
                      Trade in Year
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Select
                      name='tradeInYear'
                      value={tradeInYear}
                      onChange={tradeInYearHandler}
                      // onBlur={formik.handleBlur}
                      style={{ display: "block" }}
                    >
                      <option value='' label='Select an option' />
                      {optionYear && optionYear.length > 0 &&
                        optionYear.map((option) => {
                          const { year, id, vinyearcode } = option;
                          return <option key={id} id={vinyearcode} value={year}>{year}</option>
                        })
                      }
                    </Form.Select>
                    {/* {formik.errors.tradeInYear && (
                    <div className='input-feedback'>
                      {formik.errors.tradeInYear}
                    </div>
                  )} */}
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='tradeInMake'>
                    <Form.Label className='formLabel'>
                      Trade in Make
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Select
                      name='tradeInMake'
                      value={tradeInMake}
                      onChange={(e) => tradeInMakeHandler(e)}
                      data-id='tradeInMake'
                      style={{ display: "block" }}
                    >
                      <option value='' label='Select an option' />
                      {makeModels && makeModels.length > 0 && makeModels.map((makes) => {
                        const { make } = makes;
                        return <option key={make} value={make} >{make}</option>
                      }
                      )}
                    </Form.Select>
                    {/* {formik.errors.tradeInMake && (
                    <div className='input-feedback'>
                      {formik.errors.tradeInMake}
                    </div>
                  )} */}
                  </Form.Group>
                </Row>

                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='tradeInModel'>
                    <Form.Label className='formLabel'>
                      Trade in Model
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Select
                      name='tradeInModel'
                      value={tradeInModel}
                      data-id='tradeInModel'
                      onChange={(e) => setTradeInModel(e.target.value)}
                      style={{ display: "block" }}
                    >
                      <option value='' label='Select an option' />
                      {filteredModels && filteredModels.length > 0 && filteredModels.map((models) => {
                        return <option key={models} value={models} >{models}</option>
                      })}
                    </Form.Select>
                    {/* {formik.errors.tradeInModel && (
                    <div className='input-feedback'>
                      {formik.errors.tradeInModel}
                    </div>
                  )} */}
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId='tradeInLienholder'>
                    <Form.Label className='formLabel'>
                      Trade in Lienholder (if applicable)
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Control
                      placeholder=''
                      name='tradeInLienholder'

                      type='text'
                      value={tradeInLienholder}
                      onChange={(e) => setTradeInLienholder(e.target.value)}
                    />
                    <div className='invalid-feedback1 formLabel'>
                      {/* {" "}
                    {formik.touched.tradeInLienholder &&
                      formik.errors.tradeInLienholder ? (
                      <div>{formik.errors.tradeInLienholder}</div>
                    ) : null}{" "} */}
                    </div>
                  </Form.Group>
                </Row>
              </>}
              <Row style={{ marginBottom: '10px' }}>
                <Col className='d-flex justify-content-between'>
                  <Button type='submit' onClick={onSaveClick}>Save</Button>
                </Col>
              </Row>
              {showMessage && (
                <Row>
                  <NotificationBar variant="success" message="Your data is saved!" />
                </Row>
              )}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
            <NextStepsButton text="Previous" isDisable={false} onNextClick={onPreviousClick} />
            <NextStepsButton text="Next" isDisable={false} onNextClick={onNextClick} />
          </Col>

        </Row>
      </div>
    </Container >
  );
};
export default FinancingInfo;

//Find out if it is ios15version
export default function isiOS15Version() {
  let ver = iOSversionNumber();
  return (
    (ver && ver != -1 && ver.length >= 1 && ver[0] == 15) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  );
}

function iOSversionNumber() {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return -1;
}

import React, { useEffect } from 'react';
import './App.css';
import Routing from './components/Routing';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'
import ErrorBoundary from './ErrorBoundary';
import Header from './components/Header';
//import {store,persistor} from './Redux/Store';

function App(props) {
  const sessionTimeout = process.env.REACT_APP_SESSION_TIMEOUT; // seconds
  const credentials = {
    passive_username: '',
    passive_password: '',
    username: `${process.env.REACT_APP_SDK_USERNAME}`,
    password:
      process.env.REACT_APP_ENVIRONMENT !== 'production'
        ? process.env.REACT_APP_SDK_PASSWORD_DEV.slice(1, -1).replaceAll(
          /\\/g,
          ''
        )
        : process.env.REACT_APP_SDK_PASSWORD,
    passive_subscriptionId: '',
    acas_endpoint: `${process.env.REACT_APP_SDK_ACAS_ENDPOINT}`,
    liveness_endpoint: `${process.env.REACT_APP_SDK_LIVENESS_ENDPOINT}`,
  };

  useEffect(() => {
    loadScript();
    const serviceObjects = document.createElement('script');
    serviceObjects.src = 'https://trial.serviceobjects.com/Resources/AC/JS/ACScriptV1.02.js';
    serviceObjects.async = true;
    serviceObjects.type = 'application/javascript';
    document.body.appendChild(serviceObjects);

    console.log('loadScript loaded');
  }, []);
  
  const onIdle = () => {
    window.location.href = "/";
  }

  useIdleTimer({
    onIdle,
    timeout: sessionTimeout * 1000, // converting seconds to milliseconds
    throttle: 500
  })

  const loadScript = () => {
    window.onAcuantSdkLoaded = function () {
      // window.AcuantJavascriptWebSdk.setUnexpectedErrorCallback((error) => {
      //   //handle the error
      //   console.log('Error', error);
      // });

      init();
    };

    const sdk = document.createElement('script');
    sdk.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/AcuantJavascriptWebSdk.min.js'
        : '/AcuantJavascriptWebSdk.min.js';
    sdk.async = true;
    sdk.type = 'application/javascript';

    const sdk1 = document.createElement('script');
    sdk1.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/AcuantCamera.min.js'
        : '/AcuantCamera.min.js';
    sdk1.async = true;
    sdk1.type = 'application/javascript';

    const sdk2 = document.createElement('script');
    sdk2.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/AcuantPassiveLiveness.min.js'
        : '/AcuantPassiveLiveness.min.js';
    sdk2.async = true;
    sdk2.type = 'application/javascript';

    const sdk3 = document.createElement('script');
    sdk3.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/opencv.min.js'
        : '/opencv.min.js';
    sdk3.async = true;
    sdk3.type = 'application/javascript';

    const sdk4 = document.createElement('script');
    sdk4.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/AcuantInitializerService.min.js'
        : '/AcuantInitializerService.min.js';
    sdk4.async = true;
    sdk4.type = 'application/javascript';

    const sdk5 = document.createElement('script');
    sdk5.src =
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? '/AcuantImageWorker.min.js'
        : '/AcuantImageWorker.min.js';
    sdk5.async = true;
    sdk5.type = 'application/javascript';

    document.body.appendChild(sdk);
    document.body.appendChild(sdk1);
    document.body.appendChild(sdk2);
    document.body.appendChild(sdk3);
    document.body.appendChild(sdk4);

  };

  const init = () => {
    console.log('inside init');
    let tokenBasic = `${credentials.username}:${credentials.password}`;
    let base64Token = btoa(tokenBasic);
    window.AcuantJavascriptWebSdk.initialize(
      base64Token,
      credentials.acas_endpoint,
      {
        onSuccess: function () {
          if (!isOldiOS()) {
            window.AcuantJavascriptWebSdk.startWorkers(initDone); //no list of workers to start means it will start all the workers.
          } else {
            window.AcuantJavascriptWebSdk.startWorkers(initDone, [
              window.AcuantJavascriptWebSdk.ACUANT_IMAGE_WORKER,
            ]); //old ios devices can struggle running metrics. See readme for more info.
          }
        },
        onFail: function (code, description) {
          console.log('intialize failed ' + code + ': ' + description);
        },
      }
    );
  };
  function initDone() {
    // showCameraButton();
    console.log('initialize succeded');
  }
  function isOldiOS() {
    let ua = navigator.userAgent;
    let keyPhrase = 'iPhone OS';
    const keyPhrase2 = 'iPad; CPU OS';
    let index = ua.indexOf(keyPhrase);
    if (index < 0) {
      keyPhrase = keyPhrase2;
      index = ua.indexOf(keyPhrase);
    }
    if (index >= 0) {
      let version = ua.substring(
        index + keyPhrase.length + 1,
        index + keyPhrase.length + 3
      );
      try {
        let versionNum = parseInt(version);
        if (versionNum && versionNum < 13) {
          return true;
        } else {
          return false;
        }
      } catch (_) {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <ErrorBoundary>
      <Provider store={props.store}>
        <PersistGate loading={null} persistor={props.persistor}>
          <ConnectedRouter history={props.routerHistory}>
            <div className='App'>
              <Header />
              <Routing />
            </div>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import axios from "axios";
import { maskedEmail } from "./helpers/Mask";
import { maskedPhone } from "./helpers/Mask";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import "../App.css";
import CustomModal from "./helpers/CustomModal";
import { useDispatch } from "react-redux";
import { setPersonalInfoStatus } from "../Redux/nextForms/NextFormActions";

const Otp1 = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const overallState = useSelector((state) => state);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalShow, setModalShow] = useState(true);
  const {
    ApplicationNumber,
    ApplicantID,
    ApplicantFirstName,
    ApplicantLastName,
    CellPhoneNumber = 'null',
    EmailAddress = 'null',
  } = overallState.otpProcess;
  const [isLoading, setIsLoading] = useState(false);
  const errorPlaceholderText = `The system was unable to produce a response, please try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`;

  useEffect(() => {
    if (!location.state) {
      let timer = setTimeout(() => {
        setModalShow(false);
        history.push('/');
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);

  const handleClose = () => setModalShow(false);

  useEffect(() => {
    if (ApplicationNumber == '' && ApplicantID == '') {
      history.push('/');
    }
  }, []);

  const validate = (values) => {
    const errors = {};

    if (!values.deliveryType) {
      errors.deliveryType = 'Please select an option';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      deliveryType: '',
    },
    validate,
    onSubmit: (values) => {
      if (formik.isValid) {
        const requestBody = {
          OtpDeliveryInfo: {
            Method: values.deliveryType,

            Language: 'EN',
          },

          Persons: [
            {
              Name: {
                FirstName: ApplicantFirstName,

                LastName: ApplicantLastName,
              },

              Phones: [
                {
                  Number: CellPhoneNumber,

                  Context: 'mobile',

                  Country: 'US',
                },
              ],

              Emails: [EmailAddress],

              Context: 'primary',
            },
          ],

          ApplicantID: ApplicantID,

          ApplicationNumber: ApplicationNumber,
        };
        const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNINITIATE_OTP}`;

        //Request header
        const configHeaders = {
          headers: {
            //"Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Credentials": true,
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_BASIC_AUTHTOKEN,
          },
        };

        postData(url, requestBody, configHeaders);
      }
    },
  });

  const postData = async (url, body, headers) => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, body, headers);

      if (response && response.data) {
        const responseData = await response.data;
        setIsLoading(false);
        if (responseData.Status.TransactionStatus === 'pending') {
          history.push('/otp2', responseData);
        } else {
          //Transaction status other than pending in otp1 page
          responseData.Status.TransactionReasonCode
            ? setErrorMessage(
              responseData.Status.TransactionReasonCode.Description
            )
            : setErrorMessage(responseData.Status.TransactionStatus);
          setShowError(true);
          setTimeout(() => history.push('/'), 10000);
        }
      } else {
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(errorPlaceholderText);
        //if no response data, redirect to Applicant Info
        setTimeout(() => history.push('/'), 10000);
      }
    } catch (err) {
      if (err.message) {
        setErrorMessage(errorPlaceholderText);
      } else {
        setErrorMessage(errorPlaceholderText);
      }
      setIsLoading(false);
      setShowError(true);
      setTimeout(() => history.push('/'), 10000);

      throw err;
    }
  };

  return location.state ? (
    <>
      {' '}
      {isLoading ? (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 8, offset: 2 }}>
            <div className='row'>
              <div
                className='d-flex justify-content-center'
                style={{ marginTop: '6rem', marginBottom: '7rem' }}
              >
                <Spinner animation='border' className='spinner-element' />
              </div>
            </div>
          </Col>
        </Container>
      ) : (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
            <div className='info-section-block'>
              <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1'>
                My Auto Loan Portal
              </p>
              <p className='mb-2 offset-md-1 col-md-10 subInfo1'>
                Please choose how would you like to recieve your authorization code.
              </p>
              <Row className='mb-4'>
                <Col md={{ span: 10, offset: 1 }}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className='mb-3'>
                      <Form.Group as={Col} md='12'>
                        <div
                          id='radioGroup'
                          role='group'
                          className='ml-3'
                          aria-labelledby='my-radio-group'

                        >
                          {CellPhoneNumber !== '' && CellPhoneNumber !== null &&
                            CellPhoneNumber != 'null' ? (<><span><b>Text:</b></span>
                              <Form.Check
                                name='deliveryType'
                                type='radio'
                                label={maskedPhone(CellPhoneNumber)}
                                id='mobile'
                                value='T'
                                onChange={formik.handleChange}
                                style={{ paddingBottom: '10px' }}
                              />
                            </>
                          ) : null}
                          {EmailAddress !== '' &&  EmailAddress != 'null'&& EmailAddress!= null ? (<> <span><b>Email:</b></span>


                            <Form.Check
                              name='deliveryType'
                              type='radio'
                              label={maskedEmail(EmailAddress)}
                              id='email'
                              value='E'
                              onChange={formik.handleChange}
                              // className='mt-4'
                              style={{ overflowWrap: 'anywhere' }}

                            /></>
                          ) : null}

                          <div className='invalid-feedback1 formLabel'>
                            {formik.errors.deliveryType ? (
                              <div style={{ margin: '20px 0 0 10px' }}>
                                {formik.errors.deliveryType}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </Form.Group>
                    </Row>
                    <Row>
                      <Col>
                        <p className='mb-2 subInfo1'>
                          If the contact method(s) is inaccurate, please reach
                          out to the Crescent Bank Funding department at
                          <a href='tel:8444622866' style={{ color: '#000', textDecoration: 'none' }}>
                            <span style={{ alignItems: 'center' }}>  844-462-2866</span>
                          </a>                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className='invalid-feedback1 formLabel'>
                          {showError ? (
                            <div className='errorMessage'>{errorMessage}</div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Button className='' type='submit'>
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Container>
      )}{' '}
    </>
  ) : (
    modalShow && (
      <CustomModal
        show={modalShow}
        title='Unauthorized Access'
        text='please fill in the applicant information to access this page'
        handleClose={handleClose}
      />
    )
  );
};

export default Otp1;

import { actionTypes } from "./NextFormActionTypes";

export const setPersonalInfoStatus = (personalInfoStatus) => {
  return {
    type: actionTypes.SET_PERSONAL_INFO_STATUS,
    payload: personalInfoStatus,
  };
};

export const setContactInfoStatus = (contactInfoStatus) => {
  return {
    type: actionTypes.SET_CONTACT_INFO_STATUS,
    payload: contactInfoStatus,
  };
};

export const setResidenceInfoStatus = (residenceInfoStatus) => {
  return {
    type: actionTypes.SET_RESIDENCE_INFO_STATUS,
    payload: residenceInfoStatus,
  };
};

export const setVehicleInfoStatus = (vehicleInfoStatus) => {
  return {
    type: actionTypes.SET_VEHICLE_INFO_STATUS,
    payload: vehicleInfoStatus,
  };
};

export const setFinancingInfoStatus = (financingInfoStatus) => {
  return {
    type: actionTypes.SET_FINANCING_INFO_STATUS,
    payload: financingInfoStatus,
  };
};
export const setContractAckStatus = (contractAckStatus) => {
  return {
    type: actionTypes.SET_CONTRACT_ACK_STATUS,
    payload: contractAckStatus,
  };
};

export const setDefaultProcessingStatus = () => {
  return { type: actionTypes.SET_DEFAULT_PROCESSING_STATUS, payload: {} };
};

import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import '../App.css';
import { useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { setDefaultState } from '../Redux/trueId/TrueIdActions';
import {
  setDefaultApplicantInfoState,
  setApplicantInfo,
} from '../Redux/otpProcess/OtpReducer';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import { setDefaultProcessingStatus } from '../Redux/nextForms/NextFormActions';
import { setDefaultAllFormsInfoState } from '../Redux/nextForms/AllFormsActions';

const ApplicantInfo = () => {
  const numberRef = useRef();
  const history = useHistory();
  const overallState = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const recaptchaRef = useRef();
  const [verified, setVerified] = useState(false);
  const {
    ApplicationNumber,
    ApplicantID,
    ApplicantFirstName,
    ApplicantLastName,
    CellPhoneNumber = 'null',
    EmailAddress = 'null',
  } = overallState.otpProcess;
  useEffect(() => {
    dispatch(setDefaultState());
    dispatch(setDefaultApplicantInfoState());
    dispatch(setDefaultAllFormsInfoState());
    dispatch(setDefaultProcessingStatus());
  }, []);

  const onWheelDisable = () => {
    numberRef.current.blur();
    setTimeout(() => numberRef.current.focus(), 100);
  };

  function recaptchaOnChange(value) {
    if (value !== null) {
      setVerified(true);
    }
  }
  const onExpired = () => {
    setVerified(false);
  };
  function isNumberKey(event) {
    if (!`${event.target.value}${event.key}`.match(/^[0-9]{0,5}$/)) {
      // block the input if result does not match
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }

  const validate = (values) => {
    const errors = {};

    if (!values.lastName) {
      errors.lastName = 'Please enter your last name';
    } else if (values.lastName.length < 2) {
      errors.lastName = 'Must be 2 characters or more';
    }

    if (!values.ssnlastFive) {
      errors.ssnlastFive = 'Please enter your last five of SSN';
    } else if (values.ssnlastFive.toString().length !== 5) {
      errors.ssnlastFive = 'Must be 5 numbers';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      lastName: '',
      ssnlastFive: '',
    },
    validate,
    onSubmit: (values) => {
      if (formik.isValid && verified) {
        let payload = values;

        const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNSELECT_APPLICANT}`;

        const configHeaders = {
          headers: {
            //"Access-Control-Allow-Origin": "*",
            //"Access-Control-Allow-Credentials": true,
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_BASIC_AUTHTOKEN,
          },
        };

        postData(url, payload, configHeaders);
      }
    },
  });

  const postData = async (url, body, headers) => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, body, headers);
      if (response && response.data) {
        const responseData = await response.data;
        setIsLoading(false);
        if (
          (responseData.CellPhoneNumber === '' ||
            responseData.CellPhoneNumber === 'null') &&
          (responseData.EmailAddress === '' ||
            responseData.EmailAddress === 'null')
        ) {
          setShowError(true);
          setErrorMessage(
            `We require additional information to complete this process. Please contact Crescent Bank's Funding Department at 844-462-2866 for assistance.`
          );
          setVerified(false);
        } else if (responseData.Status.TransactionStatus === 'Passed') {
          dispatch(setApplicantInfo(responseData));
          history.push('/otp1', responseData);
        } else if (responseData.Status.TransactionStatus === 'Failed') {
          setShowError(true);
          setErrorMessage(
            `The Personal Information submitted does not match our records. Please update and try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
          );
          setVerified(false);
        } else {
          setShowError(true);
          setErrorMessage(
            `The system was unable to produce a response, please try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
          );
          setVerified(false);
        }
      } else {
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(
          `The Personal Information submitted does not match our records. Please update and try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
        );
        setVerified(false);
      }
    } catch (error) {
      setIsLoading(false);
      setShowError(true);
      setErrorMessage(
        `The system was unable to produce a response, please try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
      );
      setVerified(false);
    }
  };

  return (
    <>
      {' '}
      {isLoading ? (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 8, offset: 2 }}>
            <div className='row'>
              <div
                className='d-flex justify-content-center'
                style={{ marginTop: '6rem', marginBottom: '7rem' }}
              >
                <Spinner animation='border' className='spinner-element' />
              </div>
            </div>
          </Col>
        </Container>
      ) : (
        <Container className='min-vh-100'>
          <Col
            className='inner-wrapper'
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
          >
            <div id='applicantInfo' className='info-section-block'>
              <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1'>
                My Auto Loan Portal
              </p>
              <p className='mb-2 offset-md-1 col-md-10 subInfo1'>
                Please enter your information below to access your account.
              </p>
              <Row className='mb-4'>
                <Col md={{ offset: 1 }}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className='mb-3 mt-1'>
                      <Form.Group as={Col} md='12' controlId='lastName'>
                        <Form.Label className='formLabel'>Last Name</Form.Label>
                        <Form.Control
                          placeholder='Last Name'
                          name='lastName'
                          type='text'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        <div className='invalid-feedback1 formLabel'>
                          {' '}
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div>{formik.errors.lastName}</div>
                          ) : null}{' '}
                        </div>
                      </Form.Group>
                    </Row>
                    <Row className='mb-2'>
                      <Form.Group as={Col} md='12' controlId='ssnlastFive'>
                        <Form.Label className='formLabel'>
                          Last 5 of Social Security Number
                        </Form.Label>
                        <Form.Control
                          placeholder='#####'
                          name='ssnlastFive'
                          type='text'
                          ref={numberRef}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ssnlastFive}
                          onWheel={onWheelDisable}
                          onKeyPress={(event) => {
                            isNumberKey(event);
                          }}
                        />
                        <div className='invalid-feedback1 formLabel'>
                          {' '}
                          {formik.touched.ssnlastFive &&
                          formik.errors.ssnlastFive ? (
                            <div>{formik.errors.ssnlastFive}</div>
                          ) : null}{' '}
                        </div>
                      </Form.Group>
                    </Row>
                    <Row>
                      <ReCAPTCHA
                        className='g-recaptcha'
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                        onChange={recaptchaOnChange}
                        ref={recaptchaRef}
                        onExpired={onExpired}
                      />
                    </Row>
                    <Row>
                      <Col>
                        <div className='invalid-feedback1 formLabel mt-2'>
                          {showError ? (
                            <div className='errorMessage'>{errorMessage}</div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Button type='submit'>Submit</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
              <p className='mb-2 offset-md-1 col-md-10 subInfo1'>
                If you prefer to complete your Auto Loan Process manually,
                please contact Crescent Bank Funding Department at <a style={{color:'#666'}}  href='tel:8444622866'> 844-462-2866</a>

              </p>
            </div>
          </Col>
        </Container>
      )}
    </>
  );
};
export default ApplicantInfo;

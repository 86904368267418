import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { getAllFormsData, saveAllFormsData } from "../../api/Services";
import axios from "axios";
// import '../App.css'
import { setContractAckStatus, setFinancingInfoStatus } from "../../Redux/nextForms/NextFormActions";
import {  getRDPEventLogger } from '../../api/Services';

import {
  saveContractAckInfoForm,
  postAllFormsInfo,
} from "../../Redux/nextForms/AllFormsActions";
import { useSelector, useDispatch } from "react-redux";
import NextStepsButton from "./NextStepsButton";
import ProcessingScreen from "./ProcessingScreen";

const ContractAck = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const dispatch = useDispatch();
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { contractAckInfoForm } = allFormsInfo;
  const { ApplicationNumber, ApplicantID, ApplicationType} = otpProcess;
  const [paymentAmount, setPaymentAmount] = useState(contractAckInfoForm.paymentAmount);
  const [firstPaymentDueDate, setFirstPaymentDueDate] = useState(contractAckInfoForm.firstPaymentDueDate);
  const [liableForPayments, setLiableForPayments] = useState(contractAckInfoForm.liableForPayments);
  const [confirmationOfPaymentAmtDate, setConfirmationOfPaymentAmtDate] = useState(contractAckInfoForm.confirmationOfPaymentAmtDate);
  const [errors, setErrors] = useState({
    liableForPayments: "",
    confirmationOfPaymentAmtDate: '',
  });

  const validate = () => {
    const errors = {};

    if (liableForPayments === "") {
      errors.liableForPayments = "Required selection"
    }
    if (!confirmationOfPaymentAmtDate) {
      errors.confirmationOfPaymentAmtDate = "Required selection"
    }
    setErrors(errors)

    if (errors.liableForPayments || errors.confirmationOfPaymentAmtDate) {
      return false;
    }

    return true
  };
  const onPreviousClick = () => {
    history.push("/financinginfo")
  }
  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
        console.log(eventPayload.eventName);
    });
}

const handleDateChange = (event) => {
  const input = event.target.value;
  let formattedDate = input;

  // Remove any forward slashes from the input
  formattedDate = formattedDate.replace(/\//g, '');

  // Add forward slashes in the appropriate positions (e.g., MM/DD/YYYY)
  if (formattedDate.length > 1) {
    formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
  }
  if (formattedDate.length > 4) {
    formattedDate = formattedDate.slice(0, 5) + '/' + formattedDate.slice(5, 9);
  }
  setFirstPaymentDueDate(formattedDate);
  setErrors({ ...errors, selectedDate: !formattedDate ? 'Please select DOB' : '' })

};

  const submitHandler = (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "customerInterviewCompleted",
      eventDescription: '',
      eventData: "",
  }
    
    if (validate()) {
    
   
  rdpEventHandler(eventPayload)

    saveContractAckInfoForm()
    dispatch({ type:'CUSTOMERINTERVIEW_STATUS_UPDATE', payload: {} });
    history.push("/verifydocument")
}
    }

  const {
    Payment,
    FirstPaymentDate
  } = overallState.otpProcess;
var paymnetDate = FirstPaymentDate!=""?FirstPaymentDate.split(' ')[0]:"" 
function changeDateFormat(inputDate){  // expects Y-m-d
  var splitDate = inputDate.split('-');
  if(splitDate.count == 0){
      return null;
  }

  var year = splitDate[0];
  var month = splitDate[1];
  var day = splitDate[2]; 

  return month + '/' + day + '/' + year;
}

var newFirstPaymentDate = changeDateFormat(paymnetDate);
  const saveContractAckInfoForm = async (currentPage) => {
    const contractAckInfo = {
      paymentAmount,
      firstPaymentDueDate,
      liableForPayments,
      
      confirmationOfPaymentAmtDate,

    };

    const formsData = {
      ...allFormsInfo,
      contractAckInfoForm: contractAckInfo
    };
    const payload = {
      allFormsInfo: formsData,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({
        ...allFormsInfo,
        contractAckInfoForm: contractAckInfo
      })
    );
    const response = await saveAllFormsData(payload);
    return response;

  }

 
  return (
    <Container className='min-vh-100'>
      <div className='mb-3'>
        <ProcessingScreen />
        <Row>
          <h3 className='mt-3 mb-4'>Contract Acknowledgement</h3>
        </Row>

        <Row className='' style={{ marginBottom: "3rem" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Form>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <Form.Label className='formLabel'>
                    <small>
                                              
                        {`Your monthly payment is ${Payment}, which is first due on ${newFirstPaymentDate}.${ApplicationType!=='Individual'? `You acknowledge that you are completely responsible for the entire payment on this loan. 
                        If the co-signer is unable to make payments, you will be required to make full payment, and late or missed payments may 
                        affect your credit report.Once your loan is fully processed, you will
                          receive a welcome letter and an account statement by mail.`:``}
                          Once you receive your welcome package, you will be able to create your online account portal where you can manage your auto loan and make payments. 
                          We also offer the option of enrolling in monthly recurring payments, 
                          so you can rest assure your payment will be processed on-time. If you don't receive your welcome package, 
                          or if you have any questions about your account, please contact Customer Service at (866) 208-8288 or visit cresentbank.com
                      `}
                    </small>
                  </Form.Label>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='paymentAmount'>
                  <Form.Label className='formLabel'>
                    Current Monthly Payment Amount
                    <span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='paymentAmount'
                    type='number'
                    onChange={(e) => setPaymentAmount(e.target.value)}
                    value={paymentAmount}
                  />
                  <div className='invalid-feedback1 formLabel'>

                    {/* {errors.paymentAmount} */}

                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='firstPaymentDueDate'>
                  <Form.Label className='formLabel'>
                    First Payment Due Date
                    <span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Control
                    placeholder='MM/DD/YYYY'
                    name='dob'
                    type='text'
                    data-id='dpicker'
                    value={firstPaymentDueDate} 
                    onChange={handleDateChange}
                  />
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='liableForPayments'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                    Do you acknowledge that all applicants listed on the auto loan contract are liable, responsible and willing to make the payments?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='liableForPayments'
                        type='radio'
                        label='Yes'
                        checked={liableForPayments === 'Yes' ? true : false}
                        onChange={(e) => {
                          setLiableForPayments('Yes')
                          setErrors({ ...errors, liableForPayments: !e.target.value ? 'Selection Required' : '' })

                        }}
                     
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }
                      
                      }
                      />
                      <Form.Check
                        name='liableForPayments'
                        type='radio'
                        label='No'
                        onChange={(e) => {setLiableForPayments('No')
                        setErrors({ ...errors, liableForPayments: !e.target.value ? 'Selection Required' : '' })

                      }
                      }
                        style={{ display: "inline-block", paddingRight: "25px" }}

                        checked={liableForPayments === 'No' ? true : false}
                      // className='mt-4'
                      />
                    </div>

                    <div className='invalid-feedback1 formLabel'>
                    {errors.liableForPayments}

                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div role='group' aria-labelledby='my-radio-group'>
                    <Form.Label className='formLabel'>
                      Please confirm your acknowledgment of the Payment amount and
                      due date.
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Check
                      name='confirmationOfPaymentAmtDate'
                      type='checkbox'
                      checked={confirmationOfPaymentAmtDate}
                      label='I confirm ...'
                      id='confirmationOfPaymentAmtDate'
                      onChange={(e) =>{
                        setConfirmationOfPaymentAmtDate(!confirmationOfPaymentAmtDate)
                        setErrors({ ...errors, confirmationOfPaymentAmtDate: !e.target.checked ? 'Selection Required' : '' })

                      }
                      
                      }
                      // className='mt-4'
                      style={{ textAlign: "left" }}
                    />

                    <div className='invalid-feedback1 formLabel'>
                        {errors.confirmationOfPaymentAmtDate}
                     
                    </div>
                  </div>
                </Form.Group>
              </Row>

              <Row>
                <Col>
                  <Button type='submit' onClick={(e) => submitHandler(e)}>Submit</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
            <NextStepsButton text="Previous" isDisable={false} onNextClick={onPreviousClick} />

          </Col>

        </Row>
      </div>
    </Container>
  );
};


export default ContractAck;

import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ProcessingScreen from "../nextstepComponents/ProcessingScreen";
import { setPersonalInfoStatus } from '../../Redux/nextForms/NextFormActions';
import CustomModal from '../helpers/CustomModal';
import { useSelector } from 'react-redux';

function Result() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const overallState = useSelector((state) => state);
  const [modalShow, setModalShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const responseData =
    location.state && location.state.responseData
      ? location.state.responseData
      : null;
  const {
    ApplicationNumber,
    ApplicantID,
    ApplicantFirstName,
    ApplicantLastName,
    CellPhoneNumber = 'null',
    EmailAddress = 'null',
    TrueIDRequired,
  } = overallState.otpProcess;
  const completionMessage = (
    <div style={{ color: 'black' }}>
      <p className='mb-3 col-md-12 subInfo1'>
        Thank you for completing our authentication process. A funding analyst
        will be reaching out to you soon to complete a Customer Interview. If
        you would like to complete the process sooner, you may also contact
        Crescent Bank's Funding Department at 844-462-2866 during normal business hours. Your application number is {ApplicationNumber}.
      </p>
    </div>
  );
  useEffect(() => {
    if (!location.state) {
      let timer = setTimeout(() => {
        setModalShow(false);
        history.push('/');
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, []);
  useEffect(() => {
    if (responseData && responseData.Status === 'Success') {
      setErrorMessage(completionMessage);
      setShowError(true);
    } else {
      setErrorMessage(`Oops! Something went wrong. Please try again later.`);
      setShowError(true);
      setTimeout(() => history.push('/'), 10000);
    }
  }, []);

  /*useEffect(() => {
    dispatch(setDefaultProcessingStatus());
  }, []);*/

  const handleClose = () => setModalShow(false);

  const proceed = () => {

    dispatch(setPersonalInfoStatus("inProgress"));
    history.push("/personalinfo");
  };
  return location.state ? (
    <Container>
      <div className='min-vh-100'>
        <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
          <div className='info-section-block'>
            <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1' >
              My Auto Loan Portal
            </p>

            <div className='mb-3 offset-md-1 col-md-10 subInfo1 mt-2'>
              <div className='invalid-feedback1 formLabel'>
                {showError ? <div className=''>{errorMessage}</div> : null}
              </div>
            </div>
            {/* <Row>
              <Col>
                {TrueIDRequired ==0 && <Button onClick={proceed}>Proceed</Button>}

              </Col>
            </Row> */}


          </div>
        </Col>
      </div>
    </Container>
  ) : (
    modalShow && (
      <CustomModal
        show={modalShow}
        title='Unauthorized Access'
        text='You dont have access to this page'
        handleClose={handleClose}
      />
    )
  );
}

export default Result;

// import React, { useCallback, useRef, useState } from 'react';
// import Webcam from "react-webcam";




// const Camera = () => {
//     const videoRef = useRef(null);
//     const canvasRef = useRef(null);
//     const [imgSrc, setImgSrc] = useState('');
//     const [sharpness, setSharpness] = useState(null);
//     const [glare, setGlare] = useState(null);
//     const [img, setImg] = useState(null);
//     const webcamRef = useRef(null);
//     const capture = useCallback(() => {
//         const imageSrc = webcamRef.current.getScreenshot();
//         setImg(imageSrc);
//     }, [webcamRef]);
//     const handleStartClick = () => {
//         try {
//             const stream = navigator.mediaDevices.getUserMedia({ video: true });
//             if (videoRef.current) {
//                 videoRef.current.srcObject = stream;
//                 videoRef.current.play(); // This line should play the video on mobile devices
//             }
//         } catch (err) {
//             console.error(err);
//         }
//     };

//     const handleCaptureClick = () => {
//         const context = canvasRef.current.getContext('2d');
//         context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
//         const imgData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);

//         // Compute image sharpness
//         const grayData = new Uint8ClampedArray(imgData.data.length / 4);
//         for (let i = 0, j = 0; i < imgData.data.length; i += 4, j++) {
//             grayData[j] = (imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2]) / 3;
//         }
//         const sharpness = computeSharpness(imgData.data, canvasRef.current.width, canvasRef.current.height);
//         setSharpness(sharpness)
//         // Compute image glare
//         let total = 0;
//         for (let i = 0; i < imgData.data.length; i += 4) {
//             total += imgData.data[i] + imgData.data[i + 1] + imgData.data[i + 2];
//         }
//         const avg = total / (imgData.data.length / 4);
//         console.log(sharpness, avg)
//         setGlare(avg)
//         setImgSrc(canvasRef.current.toDataURL());
//     };

//     const handleStopClick = () => {
//         const stream = videoRef.current.srcObject;
//         if (stream) {
//             const tracks = stream.getTracks();
//             tracks.forEach((track) => {
//                 track.stop();
//             });
//             videoRef.current.srcObject = null;
//         }
//     };

//     const computeSharpness = (data, width, height) => {
//         // let sharpness = 0;
//         // for (let y = 0; y < height - 1; y++) {
//         //     for (let x = 0; x < width - 1; x++) {
//         //         const idx = y * width + x;
//         //         const gx = grayData[idx + 1] - grayData[idx];
//         //         const gy = grayData[idx + width] - grayData[idx];
//         //         sharpness += (gx ** 2) + (gy ** 2);
//         //     }
//         // }
//         const sobel = [
//             [-1, 0, 1],
//             [-2, 0, 2],
//             [-1, 0, 1]
//         ];

//         let sharpness = 0;
//         for (let y = 0; y < height; y++) {
//             for (let x = 0; x < width; x++) {
//                 let index = (y * width + x) * 4;
//                 let gray = (data[index] + data[index + 1] + data[index + 2]) / 3;

//                 let gx = 0;
//                 let gy = 0;

//                 if (x > 0 && x < width - 1 && y > 0 && y < height - 1) {
//                     gx = sobel[0][0] * (data[index - width * 4 - 4] + data[index + width * 4 - 4]) +
//                         sobel[0][1] * (data[index - 4] + data[index + 4]) +
//                         sobel[0][2] * (data[index - width * 4 + 4] + data[index + width * 4 + 4]);

//                     gy = sobel[0][0] * (data[index - width * 4 - 4] + data[index - width * 4 + 4]) +
//                         sobel[1][0] * (data[index - width * 4] + data[index + width * 4]) +
//                         sobel[2][0] * (data[index + width * 4 - 4] + data[index + width * 4 + 4]);
//                 }

//                 let g = Math.sqrt(gx * gx + gy * gy);
//                 sharpness += g / 255;
//             }
//         }

//         sharpness /= width * height;
//         return sharpness;
//     };
//     const videoConstraints = {
//         width: { min: 480 },
//         height: { min: 720 },
//         aspectRatio: 0.6666666667,
//         facingMode: "user"
//     };
//     return (
//         <div>
//             {/* <div>
//                 <video ref={videoRef} autoPlay></video>
//                 <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
//             </div>
//             <div>
//                 <button onClick={handleStartClick}>Start camera</button>
//                 <button onClick={handleCaptureClick}>Capture image</button>
//                 <button onClick={handleStopClick}>Stop camera</button>
//             </div>
//             {imgSrc && <img src={imgSrc} alt="captured" />}
//             {sharpness !== null && <p>Sharpness: {sharpness.toFixed(2)}</p>}
//             {glare !== null && <p>Glare: {glare}</p>} */}
//             {img === null ? (
//                 <>
//                     <Webcam
//                         audio={false}
//                         mirrored={true}
//                         height={400}
//                         width={400}
//                         ref={webcamRef}
//                         screenshotFormat="image/jpeg"
//                         videoConstraints={videoConstraints}
//                     />
//                     <button onClick={capture}>Capture photo</button>
//                 </>
//             ) : (
//                 <>
//                     <img src={img} alt="screenshot" />
//                     <button onClick={() => setImg(null)}>Retake</button>
//                 </>
//             )}
//         </div>
//     );
// };

// export default Camera;
// import React, { useRef, useState } from "react";

// const Camera = () => {
//   const fileInputRef = useRef(null);
//   const [selectedFile, setSelectedFile] = useState(null);

//   const handleFileInputChange = (event) => {
//     const file = event.target.files[0];
//     console.log('file',file,URL.createObjectURL(file))
//     setSelectedFile(URL.createObjectURL(file));
//   };

//   return (
//     <div>
//       <input
//         type="file"
//         accept="image/*"
//         capture="camera"
//         ref={fileInputRef}
//         onChange={handleFileInputChange}
//       />
//       {selectedFile && <img src={selectedFile} alt="Selected file" />}
//     </div>
//   );
// };

// export default Camera;
import React, { useRef, useState } from "react";

const Camera = () => {
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [sharpness, setSharpness] = useState(null);
    const [glareDetected, setGlareDetected] = useState(false);
    const [glare, setGlare] = useState(null);
    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(URL.createObjectURL(file));

        // Calculate sharpness
        const sharpnessCanvas = document.createElement("canvas");
        const sharpnessCtx = sharpnessCanvas.getContext("2d");
        const sharpnessImg = new Image();
        sharpnessImg.onload = () => {
            sharpnessCanvas.width = sharpnessImg.width;
            sharpnessCanvas.height = sharpnessImg.height;
            sharpnessCtx.drawImage(sharpnessImg, 0, 0);

            const imageData = sharpnessCtx.getImageData(0, 0, sharpnessCanvas.width, sharpnessCanvas.height);
            const data = imageData.data;
            let sum = 0;
            for (let i = 0; i < data.length; i += 4) {
                const r = data[i];
                const g = data[i + 1];
                const b = data[i + 2];
                sum += (0.2126 * r + 0.7152 * g + 0.0722 * b);
            }
            const avg = sum / (data.length / 4);
            setSharpness(avg);
        };
        sharpnessImg.src = URL.createObjectURL(file);

        // Check for glare
        const glareCanvas = document.createElement("canvas");
        const glareCtx = glareCanvas.getContext("2d");
        const glareImg = new Image();
        glareImg.onload = () => {
            glareCanvas.width = glareImg.width;
            glareCanvas.height = glareImg.height;
            glareCtx.drawImage(glareImg, 0, 0);

            const imageData = glareCtx.getImageData(0, 0, glareCanvas.width, glareCanvas.height);
            const data = imageData.data;
            let total = 0;
            for (let i = 0; i < data.length; i += 4) {
                total += data[i] + data[i + 1] + data[i + 2];
            }
            const avg = total / (data.length / 4);
            setGlare(avg)
            setGlareDetected(avg > 230);
        };
        glareImg.src = URL.createObjectURL(file);
    };

    return (
        <div>
            <input
                type="file"
                accept="image/*"
                capture="camera"
                ref={fileInputRef}
                onChange={handleFileInputChange}
            />
            {selectedFile && <img src={selectedFile} alt="Selected file" style={{ height: '480px', width: '700px' }} />}
            {sharpness && <p>Sharpness: {sharpness.toFixed(2)}</p>}
            {glare&&<p>glare:{glare.toFixed(2)}</p>}
            {glareDetected && <p>Glare detected!</p>}
        </div>
    );
};

export default Camera;




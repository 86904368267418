import React from 'react';
import Header from './components/Header';
import error from "./images/error.png";
import { getRDPEventLogger } from './api/Services';


export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: '' };
    }

    static getDerivedStateFromError(error) {
        console.log(error)
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        const applicationStore = JSON.parse(localStorage.getItem('persist:root'))
        const {ApplicationNumber, ApplicantID} = JSON.parse(applicationStore.otpProcess)
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        const eventPayload = {
            applicationNumber: ApplicationNumber,
            applicantId: ApplicantID,
            linkToken: " ",
            eventName: "Error",
            eventDescription: `'UI Error'`,
            eventData: {error, info: errorInfo},
          }
        getRDPEventLogger(eventPayload).then(response => console.log('error event ==', response))
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <>            <div className='App'>
                <Header />
                <img src={error} alt='Error Page'/></div>
            </>;
        }

        return this.props.children;
    }
}
import React from "react";
import { Container, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

function Welcome() {
  const history = useHistory();
  // const location = useLocation();

  const beginProcess = () => {
    localforage.removeItem("frontImage");
    localforage.removeItem("backImage");
    history.push("/capturephoto", { front: true });
  };

  return (
    <Container className='min-vh-100'>
      <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
        <div className='info-section-block'>
          <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1'>
            My Auto Loan Portal
          </p>
          <p className='mb-3 offset-md-1 col-md-10 subInfo1'>
            Please have your Driver’s License available before you begin the
            next authentication process.
          </p>
          <p
            className='mb-3 offset-md-1 col-md-10 subInfo1'
            style={{ fontSize: "14px" }}
          >
            <b>Note:</b> Access to your device's camera is required to complete
            the image capture process.
          </p>
          <div className='offset-md-1 col-md-10'>
            <button
              type='text'
              className='btn btn-primary'
              onClick={beginProcess}
            >
              Begin
            </button>
          </div>
        </div>
      </Col>
    </Container>
  );
}

export default Welcome;

import { actionTypes } from "./NextFormActionTypes";
import axios from "axios";

export const savePersonalInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_PERSONAL_INFO_FORM,
    payload: data,
  };
};

export const saveResidenceInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_RESIDENCE_INFO_FORM,
    payload: data,
  };
};
export const saveContactInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_CONTACT_INFO_FORM,
    payload: data,
  };
};
export const saveVehicleInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_VEHICLE_INFO_FORM,
    payload: data,
  };
};
export const saveFinancingInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_VEHICLE_INFO_FORM,
    payload: data,
  };
};
export const saveContractAckInfoForm = (data) => {
  return {
    type: actionTypes.SAVE_VEHICLE_INFO_FORM,
    payload: data,
  };
};

export const postAllFormsInfo = (data) => {
  return {
    type: actionTypes.POST_ALL_FORMS_INFO,
    payload: data
  };
};

export const setDefaultAllFormsInfoState = () => {
  return { type: actionTypes.SET_DEFAULT_ALL_FORMS_INFO_STATE, payload: {} };
};

import axios from "axios";

const options = {
    headers: {
        // "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: process.env.REACT_APP_BASIC_AUTHTOKEN,
    }
}

export const saveAllFormsData = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNINSERT_APPLICANT}`
    try {
        const response = await axios.post(endpoint, payload, options);
        if (response && response.data) return response.data;
        return null;
    } catch (error) {
        console.log("error", error)
        return null;
    }
};

export const getAllFormsData = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNSELECT_CUSTOMER_INTERVIEW}`
    try {
        const response = await axios.post(endpoint, payload, options);
        if (response && response.data) return response.data;
        return null;
    } catch (error) {
        console.log("error", error)
        return null;
    }
}
export const getMakeModel = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GETMAKEMODEL}`
    try {
        const response = await axios.post(endpoint, payload, options);
        if (response && response.data) return response.data;
        return null;
    } catch (error) {
        console.log("error", error)
        return null;
    }
}

export const getMatchingAddress = async (payload) => {
    const endpoint = `${process.env.REACT_APP_SERVICE_OBJECTS_API}BusinessName=${payload.BusinessName}&Address=${payload.ADDRESS}&Address2=${payload.ADDRESS2}&City=${payload.CITY}&State=${payload.STATE}&PostalCode=${payload.POSTALCODE}&LicenseKey=${process.env.REACT_APP_SERVICE_OBJECTS_LICENSE_KEY}`
    try {
        const response = await axios.get(endpoint)
        return response.data;
    }
    catch (error) {
        console.log('error', error)
    }
}
export const validateOTP = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNVALIDATE_OTP}`
    try {
        const response = await axios.post(endpoint, payload, options);
        if (response && response.data) return response.data;
        return null;
    } catch (error) {
        console.log("error", error)
        return null;
    }
}

export const getPinwheelToken = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GETPINWHEEL}`
    try {
        const response = await axios.post(endpoint, payload, options);
        if (response && response.data) return response.data;
        return null;

    }
    catch (error) {
        console.log("error", error)
        return null;
    }
}

export const getRDPEventLogger = async (payload) => {
    const endpoint = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RDPEVENTLOGGER}`
    try {
        const response = await axios.post(endpoint, payload, options)
         if (response && response.status=== 200)  return response.data
        return null;
    }
    catch (error) {
        console.log('error', error)
        return null

    }
}
import React from "react";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ApplicantInfo from "./ApplicantInfo";
import Otp1 from "./Otp1";
import Otp2 from "./Otp2";
import Welcome from "./trueidComponents/Welcome";
import CapturePhoto from "./trueidComponents/CapturePhoto";
import Confirm from "./trueidComponents/Confirm";
import Result from "./trueidComponents/Result";
import "../App.css";
import ScrollToTop from "./helpers/ScrollToTop";
import ContactInfo from "./nextstepComponents/ContactInfo";
import PersonalInfo from "./nextstepComponents/PersonalInfo";
import ResidenceInfo from "./nextstepComponents/ResidenceInfo";
import VehicalInfo from "./nextstepComponents/VehicleInfo";
import FinancingInfo from "./nextstepComponents/FinancingInfo";
import ContractAck from "./nextstepComponents/ContractAck";
import FinalResult from "./nextstepComponents/FinalResult";
import VerifyDocument from "./trueidComponents/AppWithImagePreview/VerifyDocument";
import Camera from "./camera/camera";
import  '../../src/Calldotsac.js';

function Routing() {
  let basename1 =
    process.env.REACT_APP_ENVIRONMENT === "production" ? "/" : "/";
  return (
    <div className='wrapper d-flex flex-column min-vh-100'>
      <BrowserRouter basename={basename1}>
        <ScrollToTop />
        <Switch>
        <Route exact path='/'>
            <ApplicantInfo />
          </Route>
          <Route path='/otp1'>
            <Otp1 />
          </Route>
          <Route path='/otp2'>
            <Otp2 />
          </Route>
          <Route path='/verifydocument'>
            <VerifyDocument />
          </Route>
          <Route path ='/camera'>
            <Camera/>
          </Route>          
          <Route path='/welcome'>
            <Welcome />
          </Route>
          <Route path='/capturephoto'>
            <CapturePhoto />
          </Route>
          <Route path='/confirm'>
            <Confirm />
          </Route>
          <Route path='/result'>
            <Result />
          </Route>         
          <Route path='/personalinfo'>
            <PersonalInfo />
          </Route>
          <Route path='/contactinfo'>
            <ContactInfo />
          </Route>
          <Route path='/residenceinfo'>
            <ResidenceInfo />
          </Route>
          <Route exact path='/vehicleinfo'>
            <VehicalInfo />
          </Route>
          <Route exact path='/financinginfo'>
            <FinancingInfo />
          </Route>
          <Route exact path='/contractack'>
            <ContractAck />
          </Route>
          <Route exact path='/finalresult'>
            <FinalResult />
          </Route>
        </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default Routing;

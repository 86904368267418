import React from "react";
import { Row, Container } from "react-bootstrap";
import FDIC_Logo from "../images/FDIC_Logo.png";
import EqualHousing_Logo from "../images/EqualHousing_Logo.png";

function Footer() {
  return (
    <div className='footer global-menu-background' >
      <Container>
        <Row>
          <div className='col-xs-12 col-lg-4 text-lg-start'>
            <p className='mb-0'>© 2022 - Crescent Bank. All rights reserved.</p>
          </div>
          <div className='col-xs-12 col-lg-4 text-lg-center'>
            <address className='mb-0'>
              P.O. Box 61813, New Orleans, LA 70161
            </address>
          </div>
          <div className='col-xs-12 col-lg-4 text-lg-end'>
            <a href='https://www.fdic.gov' target='_blank' rel='noreferrer'>
              <img src={FDIC_Logo} alt='FDIC Logo' width='40' height='25' />
            </a>
            &nbsp;
            <a href='https://www.hud.gov' target='_blank' rel='noreferrer'>
              <img
                src={EqualHousing_Logo}
                alt='Equal Housing Lender Logo'
                width='26'
                height='26'
              />
            </a>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistCombineReducers } from "redux-persist";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { trueIdreducer } from "./trueId/TrueIdReducer";
import { mainFormReducer } from "./nextForms/MainFormReducer";
import { allFormsReducer } from "./nextForms/AllFormsReducer";

import { otpReducer } from "./otpProcess/OtpReducer";

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whiteList: ["trueIdinfo"],
};

const reducers = {
  trueIdinfo: trueIdreducer,
  processingStatus: mainFormReducer,
  otpProcess: otpReducer,
  totalFormsInfo: allFormsReducer,
  router: connectRouter(history),
};
const persistReducer = persistCombineReducers(persistConfig, reducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  persistReducer,
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);
export const persistor = persistStore(store);

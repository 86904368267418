import { actionTypes } from './TrueIdActionTypes';

export const setDefaultState = () => {
  return { type: actionTypes.SET_DEFAULT_STATE, payload: {}};
};

export const setFrontSide = (frontSide) => {
  return { type: actionTypes.SET_FRONT_SIDE, payload: frontSide };
};

export const setBackSide = (backSide) => {
  return { type: actionTypes.SET_BACK_SIDE, payload: backSide };
};


export const setIsLoading = (isLoading) => {
  return { type: actionTypes.SET_IS_LOADING, payload: isLoading };
};

import { actionTypes } from "./TrueIdActionTypes";
import storage from "redux-persist/lib/storage";
//import { PURGE } from 'redux-persist';
const initialState = {
  frontSide: false,
  backSide: false,
  isLoading: false,
  statusText: "Please upload front side of the id",
  buttonText: "Capture front side",
};

export const trueIdreducer = (state, action) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case actionTypes.SET_FRONT_SIDE:
      return {
        ...state,
        frontSide: action.payload,
      };

    case actionTypes.SET_BACK_SIDE:
      return {
        ...state,
        backSide: action.payload,
      };
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actionTypes.SET_DEFAULT_STATE:
      storage.removeItem("persist:root");
      return initialState;
   
    default:
      return state;
  }
};

import React from 'react'
import Alert from 'react-bootstrap/Alert';
import "../../App.css";
const NotificationBar = ({ variant, message }) => {
    return (
        <div className="notify" style={{}}>
            <Alert variant={variant}>
                {message}
            </Alert>
        </div>
    )
}

export default NotificationBar
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import { setContactInfoStatus } from "../../Redux/nextForms/NextFormActions";
import { saveAllFormsData } from "../../api/Services";
import { isZipValid } from "../helpers/Mask";
import { postAllFormsInfo } from "../../Redux/nextForms/AllFormsActions";
import { useSelector, useDispatch } from "react-redux";
import ProcessingScreen from "./ProcessingScreen";
import NextStepsButton from "./NextStepsButton";
import AutoLoanContactInfo from "./AutoLoanContactInfo";
import NumberFormat from "react-number-format";
import { getRDPEventLogger } from '../../api/Services';
import DOTSGlobalAddressComplete from '../../Calldotsac.js';
import NotificationBar from "./NotificationBar";

const ContactInfo = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { contactInfoForm } = allFormsInfo;
  const { ApplicationNumber, ApplicantID } = otpProcess;
  const dispatch = useDispatch();
  const [contactMethod, setContactMethod] = useState(contactInfoForm.preferredContactMethod);
  const [homePhone, setHomePhone] = useState(contactInfoForm.homePhone);
  const [cellPhone, setCellPhone] = useState(contactInfoForm.cellPhone);
  const [workPhone, setWorkPhone] = useState(contactInfoForm.workPhone);
  const [email, setEmail] = useState(contactInfoForm.emailAddress);
  const [maskedHomePhone, setMaskedHomePhone] = useState(contactInfoForm.homePhone);
  const [isPhysicalAddressSame, setIsPhysicalAddressSame] = useState(contactInfoForm.isPhysicalAddress);
  const [rideShare, setRideShare] = useState(contactInfoForm.rideShare);

  const [isPhoneNumberSame, setIsPhoneNumberSame] = useState(contactInfoForm.isPhoneNumberSame);
  const [address, setAddress] = useState(contactInfoForm.billingStreetAddress)
  const [state, setState] = useState(contactInfoForm.billingAddressState);
  const [city, setCity] = useState(contactInfoForm.billingAddressCity)
  const [zipCode, setZipCode] = useState(contactInfoForm.billingAddressZipCode)
  const [employmentStatus, setEmployeeStatus] = useState(contactInfoForm.employmentStatus);
  const [timeMonths, setTimeMonths] = useState(contactInfoForm.monthsEmployed);
  const [timeYears, setTimeYears] = useState(contactInfoForm.yearsEmployed);
  const [employerfullAddress, setEmployerFullAddress] = useState(contactInfoForm.employerfullAddress);
  const [showMessage, setShowMessage] = useState(false);

  const [errors, setErrors] = useState({
    contactMethod: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    email: '',
    isPhysicalAddressSame: '',
    // employerfullAddress: '',
    // employmentStatus: '',
    time: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    rideShare:''
  });

  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 2000);
  }, [showMessage]);
  useEffect(() => {

    DOTSGlobalAddressComplete.load();

    // Assign listeners to the text boxes
    DOTSGlobalAddressComplete.listen("populate", function (address, variations) {
      setEmployerFullAddress(address.FormattedAddress.replace(/\n/g, " ").toUpperCase());
    });
  }, [employerfullAddress]);


  const onPreviousClick = () => {
    history.push("/personalinfo")



  }
  const saveContactInfoForm = async (currentPage) => {

    const contactInfo = {

      homePhone,
      cellPhone,
      workPhone,
      emailAddress: email,
      billingStreetAddress: address,
      billingAddressZipCode: zipCode,
      billingAddressCity: city,
      billingAddressState: state,
      isPhysicalAddress: isPhysicalAddressSame,
      isPhoneNumberSame,
      preferredContactMethod: contactMethod,
      employmentStatus: employmentStatus,
      monthsEmployed: timeMonths,
      yearsEmployed: timeYears,
      employerfullAddress,
      rideShare
    };

    const formsData = {
      ...allFormsInfo,
      contactInfoForm: contactInfo
    };
    const payload = {
      allFormsInfo: formsData,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({
        ...allFormsInfo,
        contactInfoForm: contactInfo
      })
    );
    const response = await saveAllFormsData(payload);
    return response;
  }

  const onSaveClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "contactInfoSaved",
      eventDescription: '',
      eventData: "",
    }
    rdpEventHandler(eventPayload)
    saveContactInfoForm('contactinfo').then(res => {
      if (res === "Success") {
        setShowMessage(true)
      }
    });
  }
  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
    });
  }



  const employerAddress = (e) => {
    const inputAddress = document.getElementById('iAddress1');
    setEmployerFullAddress(inputAddress.value);
    // setErrors({ ...errors, employerfullAddress: !e.target.value ? 'Please enter Employer full address' : '' })
  }


  const onNextClick = async (e) => {

    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "contactInfoCompleted",
      eventDescription: '',
      eventData: "",
    }

    e.preventDefault();
    if (validate()) {
      rdpEventHandler(eventPayload)

      saveContactInfoForm('residenceinfo').then(res => {
        if (res === "Success") {
          const { contactInfo: { status } } = processingStatus;
          if (status !== "completed") {
            dispatch(setContactInfoStatus("completed"));
          }
          eventPayload.eventName = 'residenceInfoStarted'
          rdpEventHandler(eventPayload)
          history.push("/residenceinfo")
        }
      });
    }
  }
  const validate = () => {
    const errors = {};
    if (!contactMethod) {
      errors.contactMethod = 'Please enter Prefered Contact Method';
    }
    if (employmentStatus === "2" && workPhone==="") {
      errors.workPhone = "Please enter work phone."
    }
    if (!homePhone) {
      errors.homePhone = "Please enter home phone";
    } else if (homePhone.length < 10) {
      errors.homePhone = "Must enter valid home phone ";
    }
    if (isPhysicalAddressSame === "") {
      errors.isPhysicalAddressSame = "Selection Required"
    }
    if (rideShare === "") {
      errors.rideShare = "Selection Required"
    }
    if (isPhysicalAddressSame === "No") {
      if (!address) {
        errors.address = "Please enter street address";
      }

      if (!zipCode) {
        errors.zipCode = "Please enter ZipCode";
      } else if (!isZipValid(zipCode)) {
        errors.zipCode = "Please enter valid zipCode";
      }

      if (!city) {
        errors.city = "Please enter City";
      }
      if (!state) {
        errors.state = "Please select State";
      }
    }
    if (!employmentStatus) {
      errors.employmentStatus = 'Please select Employment status'
    }

    if (employmentStatus === "2" &&((timeMonths === "" && timeYears === "") || (timeMonths === '0' && timeYears === '0'))) {
      errors.time = "Please select time worked with primary employer"
    }

    if (!employerfullAddress) {
      errors.employerfullAddress = 'Please enter employer full address'
    }

    setErrors(errors)

    if (errors.workPhone || errors.homePhone || errors.employmentStatus || errors.isPhysicalAddressSame || errors.address || errors.zipCode || errors.city || errors.state
      || errors.timeMonths || errors.timeYears || errors.employerfullAddress || errors.time||errors.rideShare
    ) {
      return false;
    }

    return true
  };



  return (
    <Container>
      <div className='mb-3'>
        <ProcessingScreen />
        <Row>
          <h3 className='mt-3 mb-4'> Contact Information Form</h3>
        </Row>

        <Row className='' style={{ marginBottom: "3rem" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Form >
              <AutoLoanContactInfo />

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='preferredContactMethod'>
                  <Form.Label className='formLabel'>
                    Preferred Contact Method
                    <span>&nbsp;</span>
                  </Form.Label>
                  <Form.Select
                    name='preferredContactMethod'
                    data-id='contactmethod'
                    value={contactMethod}
                    onChange={(e) => {
                      setContactMethod(e.target.value);
                      setErrors({ ...errors, contactMethod: !e.target.value ? 'Please select contact method' : '' })
                    }}
                    // onBlur={formik.handleBlur}
                    style={{ display: "block" }}
                  >
                    <option value=''>Choose an option</option>
                    <option value='4'>Email</option>
                    <option value='1'>Home Phone</option>
                    <option value='0'>Undefined</option>
                    <option value='2'>Mobile Phone</option>
                    <option value='3'>Work Phone</option>
                  </Form.Select>
                  <div className='invalid-feedback1 formLabel'>
                    {errors.contactMethod}
                  </div>

                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='homePhone'>
                  <Form.Label className='formLabel'>
                    Home Phone<span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <NumberFormat
                    name='homePhone'
                    // placeholder='###-##-####'
                    className='form-control'
                    value={maskedHomePhone}
                    data-id='homePhone'
                    format='(###) ###-####'
                    mask='_'
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setMaskedHomePhone(formattedValue);
                      setHomePhone(value);
                      setErrors({ ...errors, homePhone: !value ? 'Please enter home phone' : '' })
                    }}
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.homePhone}

                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div role='group' aria-labelledby='my-radio-group'>
                    <div style={{ display: "flex" }} id="cellphone">
                      <Form.Label className='formLabel' style={{ marginRight: '30px' }}>
                        Cell Phone
                      </Form.Label>
                      <Form.Check
                        name='sameAsHomePhone'
                        type='checkbox'
                        label='Check if same as home phone'
                        id='sameAsHomePhone'
                        checked={isPhoneNumberSame}
                        onChange={(e) => setIsPhoneNumberSame(!isPhoneNumberSame)}
                      // className='mt-4'

                      />
                    </div>
                    <NumberFormat
                      name='cellPhone'
                      className='form-control'
                      value={isPhoneNumberSame ? homePhone : cellPhone}
                      data-id='cellPhone'
                      format='(###) ###-####'
                      mask='_'
                      onValueChange={(values) => {
                        const { formattedValue, value } = values;
                        setCellPhone(value);
                        // setErrors({ ...errors, cellPhone: !value ? 'Please enter cell phone' : '' })
                      }}
                    />

                    <div className='invalid-feedback1 formLabel'>
                      {errors.cellPhone}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='emailAddress'>
                  <Form.Label className='formLabel'>
                    Email Address<span>&nbsp;</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='emailAddress'
                    type='text'
                    data-id='email'
                    onChange={(e) => {
                      setEmail(e.target.value);
                      // setErrors({ ...errors, email: !e.target.value ? 'Please enter email' : '' })
                    }}
                    value={email}
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.email}

                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} >
                  <Form.Label className='formLabel' md='8'>
                    Is your mailing address the same as your current physical
                    address?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <div className='d-flex'>
                    <Form.Check
                      name='isMailingAddressDifferent'
                      type="radio"
                      label='Yes'
                      id='same'
                      value='Yes'
                      onChange={(e) => {
                        setIsPhysicalAddressSame("Yes")
                        setErrors({ ...errors, isPhysicalAddressSame: !e.target.value ? 'Selection Required' : '' })

                      }
                      }
                      // className='mt-4'
                      style={{ display: "inline-block", paddingRight: "25px" }}
                      checked={isPhysicalAddressSame === 'Yes' ? true : false}
                    />
                    <Form.Check
                      name='isMailingAddressDifferent'
                      type='radio'
                      label='No'
                      id='notSame'
                      value='No'
                      style={{ display: "inline-block", paddingRight: "20px" }}
                      onChange={(e) => {
                        setIsPhysicalAddressSame("No")
                        setErrors({ ...errors, isPhysicalAddressSame: !e.target.value ? 'Selection Required' : '' })
                      }
                      }
                      // className='mt-4'
                      checked={isPhysicalAddressSame === 'No' ? true : false}
                    />

                  </div>

                  <div className='invalid-feedback1 formLabel'>
                    {errors.isPhysicalAddressSame}
                  </div>

                </Form.Group>
              </Row>
              <Row>
                <Col>
                  <div className='invalid-feedback1 formLabel'>
                  </div>
                </Col>
              </Row>

              {isPhysicalAddressSame === "No" && <> <Row className='mb-3'>
                <div> <h5 style={{ textAlign: "center", marginBottom: "1rem" }}>
                  <b>Current Mailing Address</b>
                </h5></div>

                <Form.Group as={Col} md='12'  >
                  <Form.Label className='formLabel'  >
                    Street Address<span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    placeholder='street name'
                    name='billingStreetAddress'
                    type='text'

                    className="AddressInputs"
                    autoComplete="new-password"
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setErrors({ ...errors, address: !e.target.value ? 'Please enter street address' : '' })

                    }}
                    value={address}
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.address}

                  </div>
                </Form.Group>
              </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' className="AddressInputBlock">
                    <Form.Label className='formLabel AddressInputBlock' >
                      Zip<span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Control
                      placeholder='12345 or 12345-6789'
                      name='billingAddressZipCode'
                      type='text'
                      // value={isPhysicalAddressSame ? personalInfoForm.zipCode : zipCode}
                      // data-id='zipcode'
                      value={zipCode}
                      className="AddressInputs"
                      onChange={(e) => {
                        setZipCode(e.target.value);
                        setErrors({ ...errors, zipCode: !e.target.value ? 'Please enter zipcode' : '' })

                      }}

                    />
                    <div className='invalid-feedback1 formLabel'>

                      {errors.zipCode}
                    </div>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' >
                    <Form.Label className='formLabel'>
                      City<span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Control
                      placeholder='city'
                      name='billingAddressCity'
                      type='text'
                      data-id='city'
                      value={city}
                      className="AddressInputs"
                      onChange={(e) => {
                        setCity(e.target.value);
                        setErrors({ ...errors, city: !e.target.value ? 'Please enter city' : '' })
                      }}
                    />
                    <div className='invalid-feedback1 formLabel'>
                      {errors.city}

                    </div>
                  </Form.Group>
                </Row>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' >
                    <Form.Label className='formLabel'>
                      State
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Control
                      name='state'
                      type='text'
                      className="AddressInputs"
                      data-id='state'
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                        setErrors({ ...errors, state: !e.target.value ? 'Please select State' : '' })

                      }}
                      style={{ display: "block" }}
                    >
                      {/* <option value='' label='Select a State' /> */}
                      {/* <USAStateList /> */}
                    </Form.Control>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.state}

                    </div>
                  </Form.Group>
                </Row>
              </>}

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='employmentStatus'>
                  <Form.Label className='formLabel'>
                    Employment Status
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Select
                    name='employmentStatus'
                    data-id='employeestatus'
                    value={employmentStatus}
                    onChange={(e) => {
                      setEmployeeStatus(e.target.value);
                      setErrors({ ...errors, employmentStatus: !e.target.value ? 'Please select Employment Status' : '' })

                    }}
                    style={{ display: "block" }}
                  >
                    <option value='' >Choose an option</option>
                    <option value='2' >Employed</option>
                    <option value='3' >Retired</option>
                    <option value='4' >Self-Employed</option>
                    <option value='5' >Military</option>
                    <option value='6' >Student</option>
                    <option value='7' >Unemployed</option>
                    <option value='8' >Other</option>
                    <option value='9' >Undefined</option>

                  </Form.Select>
                  <div className='invalid-feedback1 formLabel'>
                    {errors.employmentStatus}
                  </div>
                </Form.Group>
              </Row>
              {employmentStatus === "2" && <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='workPhone'>
                  <Form.Label className='formLabel'>
                    Work Phone<span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <NumberFormat
                    name='workPhone'
                    format='(###) ###-####'
                    className='form-control'
                    mask='_'
                    data-id='wphone'
                    value={workPhone}
                    onValueChange={(values) => {
                      const { formattedValue, value } = values;
                      setWorkPhone(formattedValue);
                      setErrors({ ...errors, workPhone: !value ? 'Please enter work phone' : '' })

                    }}
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.workPhone}
                  </div>
                </Form.Group>
              </Row>}
             { employmentStatus === "2" && <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='monthsEmployed'>
                  <Form.Label className='formLabel'>
                    How long have you worked for this employer ?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <div className='d-flex'>
                    <Form.Select
                      name='yearsEmployed'
                      style={{ width: "20%", marginRight: "10px" }}
                      data-id='years'
                      value={timeYears}
                      onChange={(e) => {
                        setTimeYears(e.target.value);
                        setErrors({ ...errors, time: !e.target.value ? 'Select years' : '' })

                      }}
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10+</option>
                    </Form.Select>
                    <Form.Label className='formLabel' style={{ width: "20%", marginTop: "10px" }}>
                      years
                    </Form.Label>

                    <Form.Select
                      name='monthsEmployed'
                      style={{ width: "20%", marginRight: "10px" }}
                      data-id='months'
                      value={timeMonths}
                      onChange={(e) => {
                        setTimeMonths(e.target.value);
                        setErrors({ ...errors, time: !e.target.value ? 'Select time worked with primary employer' : '' })

                      }}
                    >
                      <option value='0'>0</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                    </Form.Select>
                    <Form.Label className='formLabel' style={{ width: "20%", marginTop: "10px" }}>
                      months
                    </Form.Label>



                  </div>
                </Form.Group>
                <div className='invalid-feedback1 formLabel' style={{ marginTop: "5px" }}>
                  {errors.time}
                </div>
              </Row>}
              <Row className='mb-3' id="employeraddress">
                <Form.Group as={Col} md='12' >
                  <Form.Label className='formLabel ' id="lAddress2">
                    Employer Full Address
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <Form.Control
                    id="iAddress1"
                    className="AddressInputs"
                    autoComplete="new-password"
                    placeholder=''
                    name='employerfullAddress'
                    type='text'
                    data-id='employerfulladdress'
                    value={employerfullAddress}
                    onInput={(e) => { employerAddress(e) }}
                  />
                  <div className='invalid-feedback1 formLabel'>
                    {errors.employerfullAddress}

                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} >
                  <Form.Label className='formLabel' md='8'>
                    Will the vehicle be used for any ride share service, such as Uber or Lyft?
                    <span className='star-required'>&nbsp;*</span>
                  </Form.Label>
                  <div className='d-flex'>
                    <Form.Check
                      name='rideShare'
                      type="radio"
                      label='Yes'
                      id='same'
                      value='Yes'
                      onChange={(e) => {
                        setRideShare("Yes")
                        setErrors({ ...errors, rideShare: !e.target.value ? 'Selection Required' : '' })

                      }
                      }
                      // className='mt-4'
                      style={{ display: "inline-block", paddingRight: "25px" }}
                      checked={rideShare === 'Yes' ? true : false}
                    />
                    <Form.Check
                      name='rideShare'
                      type='radio'
                      label='No'
                      id='notSame'
                      value='No'
                      style={{ display: "inline-block", paddingRight: "20px" }}
                      onChange={(e) => {
                        setRideShare("No")
                        setErrors({ ...errors, rideShare: !e.target.value ? 'Selection Required' : '' })
                      }
                      }
                      // className='mt-4'
                      checked={rideShare === 'No' ? true : false}
                    />

                  </div>

                  <div className='invalid-feedback1 formLabel'>
                    {errors.rideShare}
                  </div>

                </Form.Group>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Col className='d-flex justify-content-between'>
                  <Button type='submit' onClick={onSaveClick}>Save</Button>
                </Col>
              </Row>
              {showMessage && <Row>
                <NotificationBar variant="success" message="Your data is saved!" />
              </Row>}
            </Form>
          </Col>
        </Row>
        <Row>
          <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
            <NextStepsButton text="Previous" isDisable={false} onNextClick={onPreviousClick} />
            <NextStepsButton text="Next" isDisable={false} onNextClick={onNextClick} />
          </Col>

        </Row>
      </div>
    </Container >
  );
};
export default ContactInfo;

import React from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

function CustomModal(props) {
  return (
    <div>
      <Modal
        show
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header onClick={props.handleClose} closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div>{props.text}</div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CustomModal;

import React, { useEffect, useState } from 'react'
import { Col, Container } from 'react-bootstrap'
import { FcCameraIdentification, FcLibrary, FcInfo } from 'react-icons/fc';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PinwheelModal from '@pinwheel/react-modal';
import { getPinwheelToken, getRDPEventLogger } from '../../../api/Services';
import Modal from 'react-bootstrap/Modal';
import '../../../Calldotsac.js';


function VerifyDocument() {


    const history = useHistory();
    const overallState = useSelector((state) => state);
    const {
        otpProcess,
    } = overallState;
    const { ApplicationNumber, ApplicantID, ApplicantFirstName, ApplicantLastName, TrueIDComplete, TrueIDRequired, trueIDStatus, pinwheelStatus, PinwheelRequired, CustomerInterviewRequired, customerInterviewStatus, DocumentUploadComplete, isLoanFunded } = otpProcess;
    const dispatch = useDispatch();
    const [pinwheelResponse, setPinwheelResponse] = useState('');
    const [showPinwheel, setShowPinwheel] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const payload = {
        applicantId: ApplicantID, //ApplicantID,
        applicationNumber: ApplicationNumber //ApplicationNumber,
    };
    const application = () => {
        const eventPayload = {
            applicationNumber: ApplicationNumber,
            applicantId: ApplicantID,
            linkToken: pinwheelResponse.token,
            eventName: "customerInterviewStarted",
            eventDescription: '',
            eventData: payload
        }
        rdpEventHandler(eventPayload)
        history.push("/personalinfo");
    }
    const identificationHandler = () => {
        history.push("/welcome");

    }
    const IncomeHandler = () => {
        setShowPinwheel(!showPinwheel);
    }

    const beginBtnHandler = () => {
        var data = pinwheelStatus + 1;
        if (data <= 2) {
            setShowPinwheel(!showPinwheel)
        } else {
            setModalShow(false)
        }

    };
    const secondEmployer = () => {
        setModalShow(!modalShow);
        beginBtnHandler();
    }


    const rdpEventHandler = (eventPayload) => {
        getRDPEventLogger(eventPayload).then((res) => {
        });
    }
    const handleClose = () => setModalShow(!modalShow);
    const updatePinwheelStatus = () => {
        dispatch({ type: 'PINWHEEL_STATUS_UPDATE', payload: {} });
    }
    const getPinwheelTokens = () => {
        getPinwheelToken(payload).then((res) => {
            if (res && res.data) {
                setPinwheelResponse(res.data);
            }
        });
    }
    useEffect(() => {
        getPinwheelTokens();

    }, []);

    const onEvent = (eventName, payload) => {
        const eventPayload = {
            applicationNumber: ApplicationNumber,
            applicantId: ApplicantID,
            linkToken: pinwheelResponse.token,
            eventName: eventName,
            eventDescription: '',
            eventData: payload
        }
        switch (eventName) {

            case 'exit':
                getPinwheelTokens();
                beginBtnHandler();
                rdpEventHandler(eventPayload);
                break;
            case 'success':
                updatePinwheelStatus();
                rdpEventHandler(eventPayload);
                if (pinwheelStatus < 1) setModalShow(!modalShow);
                break;
            default:
                rdpEventHandler(eventPayload);
                break;

        }
    };
    return (
        <div>
            <Container >
                <div className='min-vh-100'>
                    <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
                        <div className='info-section-block'>
                            <p className='mb-3 offset-md-1 col-md-10 subInfo1' style={{ paddingTop: '10px', fontWeight: 'bold', fontSize: '15px', textAlign: 'center' }}>{!isLoanFunded ? 'Providing Auto Loans to customers for over 30 years' : `Welcome Back ${ApplicantFirstName}`}</p>
                            <p className='mb-3 offset-md-1 col-md-10 subInfo1' style={{ textAlign: 'start' }}>
                                {!isLoanFunded ? `Welcome ${ApplicantFirstName}, the Crescent Bank family looks forward to working with you. Your Reference number is ${ApplicationNumber}` :     <>Your verification process has been completed. Please check out <a href="https://cbtno.com/myAutoLoans/Enrollment/">Enrollment</a> to create a customer account and manage your Auto Loan online. Please reach out to our Auto Loan Customer Service department at <a href="tel:1-844-462-2866">1-844-462-2866</a> for additional questions.</>}
                            </p>
                            {!isLoanFunded  ?

                                <div className='container-fluid'>
                                    {TrueIDRequired && TrueIDRequired == 1  ? <div className='text-center p-0'  >

                                        <div onClick={() => identificationHandler()} style={trueIDStatus === "passed" || trueIDStatus === "pending" || TrueIDComplete ? { pointerEvents: 'none' } : {}} disabled={trueIDStatus === "passed" || TrueIDComplete}>
                                            <input type='radio' value='Identification' className='form-check-input hidden' disabled={trueIDStatus === "passed" || trueIDStatus === "pending" || TrueIDComplete} />
                                            <label className='form-check-label smart-form-radio-response-with-icon'>
                                                <FcCameraIdentification size={40} style={{ margin: '10px', transition: 'all .3s' }} />
                                                <div className='responseText smart-form-icon-text sf-icon-text-double'>Identification</div>
                                                <div style={{ fontSize: '15px', padding: '5px' }}>
                                                    <a style={{ textDecoration: "none" }} className={` ${trueIDStatus === "passed" || trueIDStatus === "pending" || TrueIDComplete ? 'status-complete' : 'status-begin'}`} >
                                                        Status: {trueIDStatus === "passed" ? 'Completed' : trueIDStatus === "failed" ? 'Not Started' : 'Pending'}
                                                    </a>
                                                </div>

                                            </label>
                                        </div>

                                    </div> : null}


                                    {PinwheelRequired && PinwheelRequired == 1 ? <div className='text-center'>
                                        <div onClick={() => IncomeHandler()} style={pinwheelStatus >= 2 ? { pointerEvents: 'none' } : {}} disabled={pinwheelStatus >= 2}>
                                            <input type='radio' value='Identification' className='form-check-input hidden' disabled={pinwheelStatus >= 2} />

                                            <label className='form-check-label smart-form-radio-response-with-icon' >
                                                <FcLibrary size={40} style={{ margin: '10px', transition: 'all .3s' }} />
                                                <div className='responseText smart-form-icon-text sf-icon-text-double'>Employment and Income</div>
                                                <div style={{ fontSize: '15px', padding: '5px' }}>
                                                    <a style={{ textDecoration: "none" }} className={` ${pinwheelStatus == 0 ? 'status-begin' : pinwheelStatus == 1 ? 'status-complete' : 'status-complete'}`} >

                                                        Status: {pinwheelStatus == 0 ? 'Not Started' : pinwheelStatus == 1 ? 'Completed' : 'Completed'}
                                                    </a>
                                                </div>
                                            </label>
                                        </div>
                                    </div> : null}

                                    {modalShow && <div
                                        className="modal show"
                                        style={{ display: 'block', position: 'initial', top: '30% !important' }}
                                    ><Modal show={modalShow} onHide={handleClose}>
                                            <Modal.Body style={{ textAlign: 'center' }}>Would you like to connect a second employer ?</Modal.Body>
                                            <Modal.Footer>
                                                <button
                                                    type="text"
                                                    className="btn btn-primary small-btn"
                                                    style={{ fontSize: 'smaller' }}
                                                    onClick={secondEmployer}
                                                    disabled={!pinwheelResponse && pinwheelResponse.token}
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    type="text"
                                                    className="btn btn-primary smallBtn"
                                                    style={{ fontSize: 'smaller' }}
                                                    onClick={handleClose}
                                                >
                                                    No
                                                </button>
                                            </Modal.Footer>
                                        </Modal></div>}

                                    {pinwheelResponse && pinwheelResponse.token && <PinwheelModal linkToken={pinwheelResponse.token} open={showPinwheel} onEvent={onEvent} />}

                                    {/* <div className='text-center'>
                                    <div >
                                        <input type='radio' value='Identification' className='form-check-input  hidden ' />
                                        <label className='form-check-label smart-form-radio-response-with-icon'>
                                            <FcTodoList size={40} style={{ margin: '10px', transition: 'all .3s' }} />
                                            <div className='responseText smart-form-icon-text sf-icon-text-double'>Upload Documents</div>

                                            <div style={{ fontSize: '15px', padding: '5px' }}>
                                                <a style={{textDecoration:"none"}} className={`status-begin ${isDocumentUploaded ? 'status-complete' : 'status-begin'}`} >

                                                    Status: {isDocumentUploaded ? 'Completed' : 'Not Started'}
                                                </a>

                                            </div>
                                        </label>
                                    </div>
                                </div> */}
                                    {CustomerInterviewRequired && CustomerInterviewRequired == 1 ?
                                        <div className='text-center'>
                                            <div onClick={() => application()} style={customerInterviewStatus == "complete" ? { pointerEvents: 'none' } : {}} disabled={customerInterviewStatus == "complete"}>
                                                <input type='radio' value='Identification' className='form-check-input  hidden ' disabled={pinwheelStatus >= 2} />
                                                <label className='form-check-label smart-form-radio-response-with-icon'>
                                                    <FcInfo size={40} style={{ margin: '10px', transition: 'all .3s' }} />
                                                    <div className='responseText smart-form-icon-text sf-icon-text-double'>Application Information</div>
                                                    <div style={{ fontSize: '15px', padding: '5px' }}>
                                                        <a style={{ textDecoration: "none" }} className={` ${customerInterviewStatus === "notstarted" || customerInterviewStatus === "inprogress" ? 'status-begin' : 'status-complete'}`} >
                                                            Status: {customerInterviewStatus === 'notstarted' ? 'Not Started' : customerInterviewStatus === 'inprogress' ? 'In Progress' : 'Completed'}
                                                        </a></div>
                                                </label>
                                            </div>
                                        </div>
                                        : null}
                                    {/* {ApplicantLastName=="Varma"&&<button onClick={()=>history.push("/camera")}>camera</button>} */}
                                </div>
                            :null}

                        </div>
                    </Col>
                </div>
            </Container>

        </div>
    )
}

export default VerifyDocument
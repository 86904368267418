//mask email
export const maskedEmail = (emailString) => {
  // if (emailString) {
    let temp = emailString.indexOf("@");
    let emailArr = emailString.split("");
    let tempArr = [];
    let maskEmail;
    tempArr = emailArr.map((a1, index) => {
      if (index >= 1 && index <= temp - 2) {
        return [...tempArr, "*"];
      } else {
        return [...tempArr, a1];
      }
    });
    maskEmail = tempArr.join("");
    return maskEmail;
  // }
  // else return "";
};

export function maskedPhone(phoneString) {

  const formattedPhone = phoneString ? `(***)-***-${phoneString.substr(6, 4)}` : '';
  return formattedPhone;
}
export const isZipValid = (str) => {
  const regexp = /^[0-9]{5}(?:-[0-9]{4})?$/;
  return regexp.test(str);
};
export function isValidSSN(value) {

  var re = /^([0-6]\d{2}|7[0-6]\d|77[0-2])([ \-]?)(\d{2})\2(\d{4})$/;
  if (!re.test(value)) {
    return false;
  }
  var temp = value;
  if (value.indexOf("-") !== -1) {
    temp = value.split("-").join("");
  }
  if (value.indexOf(" ") !== -1) {
    temp = value.split(" ").join("");
  }
  if (temp.substring(0, 3) === "000") {
    return false;
  }
  if (temp.substring(3, 5) === "00") {
    return false;
  }
  if (temp.substring(5, 9) === "0000") {
    return false;
  }
  return true;
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
//import '../App.css'
import { useSelector, useDispatch } from "react-redux";
import ProcessingScreen from "./ProcessingScreen";
import NextStepsButton from "./NextStepsButton";
import { postAllFormsInfo, saveVehicleInfoForm } from "../../Redux/nextForms/AllFormsActions";
import AutoLoanContactInfo from "./AutoLoanContactInfo";

import { setVehicleInfoStatus } from "../../Redux/nextForms/NextFormActions";
import { getAllFormsData, getMakeModel, saveAllFormsData } from "../../api/Services";
import { useEffect } from "react";
import { getRDPEventLogger } from '../../api/Services';
import NotificationBar from "./NotificationBar";

const VehicleInfo = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const dispatch = useDispatch();
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { vehicleInfoForm } = allFormsInfo;

  const { ApplicationNumber, ApplicantID, ApplicationType } = otpProcess;

  const [vehicleCondition, setVehicleCondition] = useState(vehicleInfoForm.collateralVehicleCondition);
  const [posessionOfVehicleApplicant, setposessionofvehicleApplicant] = useState(vehicleInfoForm.posessionOfVehicleApplicant);
  const [posessionOfVehicleJoint, setposessionofvehicleJoint] = useState(vehicleInfoForm.posessionOfVehicleJoint);
  const [vehicleLocation, setVehicleLocation] = useState(vehicleInfoForm.vehicleLocation);
  const [year, setYear] = useState(vehicleInfoForm.collateralYear);
  const [make, setMake] = useState(vehicleInfoForm.collateralMake);
  const [model, setModel] = useState(vehicleInfoForm.collateralModel);
  const [color, setColor] = useState(vehicleInfoForm.collateralColor);
  const [isprimaryDriver, setIsPrimaryDriver] = useState(vehicleInfoForm.isprimaryDriverOfVehicle);

  const [primaryDriverName, setPrimaryDriverName] = useState(vehicleInfoForm.primaryDriverOther);

  const [isPDAutoLoan, setIsPDAutoLoan] = useState(vehicleInfoForm.isDriverOnContract);
  const [pdRelationshipWithYou, setPDRelationshipWithYou] = useState(vehicleInfoForm.driverRelationshipToApplicant);
  const [isPrimaryDriverToggle, setIsPrimaryDriverToggle] = useState(vehicleInfoForm.isPrimaryDriverToggle)
  const [isPrimaryPayer, setIsPrimaryPayer] = useState(vehicleInfoForm.isprimaryPayerofVehicle);
  const [primaryPayerName, setPrimaryPayerName] = useState(vehicleInfoForm.primaryPayerOther);
  const [isPPAutoLoan, setIsPPAutoLoan] = useState(vehicleInfoForm.isPayerOnContract);
  const [ppRealationshipWithYou, setPPRelationshipWithYou] = useState(vehicleInfoForm.payerRelationshipToApplicant);
  const [filteredModels, setFilteredModels] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  let optionYears = [
    { year: 2029, id: 43, vinyearcode: "W" },
    { year: 2028, id: 44, vinyearcode: "V" },
    { year: 2027, id: 45, vinyearcode: "T" },
    { year: 2026, id: 46, vinyearcode: "S" },
    { year: 2025, id: 47, vinyearcode: "R" },
    { year: 2024, id: 48, vinyearcode: "P" },
    { year: 2023, id: 49, vinyearcode: "N" },
    { year: 2022, id: 1, vinyearcode: "M" },
    { year: 2021, id: 2, vinyearcode: "L" },
    { year: 2020, id: 3, vinyearcode: "K" },
    { year: 2019, id: 4, vinyearcode: "J" },
    { year: 2018, id: 5, vinyearcode: "H" },
    { year: 2017, id: 6, vinyearcode: "G" },
    { year: 2015, id: 7, vinyearcode: "F" },
    { year: 2014, id: 8, vinyearcode: "E" },
    { year: 2013, id: 9, vinyearcode: "D" },
    { year: 2012, id: 10, vinyearcode: "C" },
    { year: 2011, id: 11, vinyearcode: "B" },
    { year: 2010, id: 12, vinyearcode: "A" },
    { year: 2009, id: 13, vinyearcode: "9" },
    { year: 2008, id: 14, vinyearcode: "8" },
    { year: 2007, id: 15, vinyearcode: "7" },
    { year: 2006, id: 16, vinyearcode: "6" },
    { year: 2005, id: 17, vinyearcode: "5" },
    { year: 2004, id: 18, vinyearcode: "4" },
    { year: 2003, id: 19, vinyearcode: "3" },
    { year: 2002, id: 20, vinyearcode: "2" },
    { year: 2001, id: 21, vinyearcode: "1" },
    { year: 2000, id: 22, vinyearcode: "Y" },
    { year: 1999, id: 23, vinyearcode: "X" },
    { year: 1998, id: 24, vinyearcode: "W" },
    { year: 1997, id: 25, vinyearcode: "V" },
    { year: 1996, id: 26, vinyearcode: "T" },
    { year: 1995, id: 27, vinyearcode: "S" },
    { year: 1994, id: 28, vinyearcode: "R" },
    { year: 1993, id: 29, vinyearcode: "P" },
    { year: 1992, id: 30, vinyearcode: "N" },
    { year: 1991, id: 31, vinyearcode: "M" },
    { year: 1990, id: 32, vinyearcode: "L" },
    { year: 1989, id: 33, vinyearcode: "K" },
    { year: 1988, id: 34, vinyearcode: "J" },
    { year: 1987, id: 35, vinyearcode: "H" },
    { year: 1986, id: 36, vinyearcode: "G" },
    { year: 1995, id: 37, vinyearcode: "F" },
    { year: 1984, id: 38, vinyearcode: "E" },
    { year: 1983, id: 39, vinyearcode: "D" },
    { year: 1982, id: 40, vinyearcode: "C" },
    { year: 1981, id: 41, vinyearcode: "B" },
    { year: 1980, id: 42, vinyearcode: "A" },

  ];
  const [optionYear, setOptionYear] = useState([]);
  const [makeModels, setMakeModels] = useState([]);

  const [errors, setErrors] = useState({
    isvehicleCondition: "",
    vehiclePossession: "",
    vehicleLocation: "",
    isprimaryDriver: '',
    isPrimaryPayer: '',
    primaryDriverName: ''
  });

  useEffect(() => {
    let currentYear = new Date()
    let filteredYears = optionYears.filter(item => item.year <= currentYear.getFullYear());
    if (year) {
      let selectedYear = optionYears.find(item => item.year === Number(year));
      getUniqueMakeModels(selectedYear.vinyearcode)
    }
    setOptionYear(filteredYears)
  }, []);

  useEffect(() => {

    if (isprimaryDriver === 'Yes') {
      setIsPDAutoLoan(null);
      setPrimaryDriverName('');
      setPDRelationshipWithYou('');
    }
    if (isPrimaryPayer === 'Yes') {
      setPrimaryPayerName('');
      setIsPPAutoLoan(null);
    }
  }, [isprimaryDriver, isPrimaryPayer])


  const onPreviousClick = () => {
    history.push("/residenceinfo")
  }
  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 2000);
  }, [showMessage]);
  const saveVehicleInfoForm = async (currentPage) => {

    const vehicleInfo = {
      collateralVehicleCondition: vehicleCondition,
      posessionOfVehicleApplicant,
      posessionOfVehicleJoint,
      vehicleLocation,
      collateralYear: year,
      collateralMake: make,
      collateralModel: model,
      collateralColor: color,
      isprimaryDriverOfVehicle: isprimaryDriver,
      isPrimaryDriverToggle,
      primaryDriverOther: primaryDriverName,
      isDriverOnContract: isPDAutoLoan,
      driverRelationshipToApplicant: pdRelationshipWithYou,
      isprimaryPayerofVehicle: isPrimaryPayer,
      primaryPayerOther: primaryPayerName,
      isPayerOnContract: isPPAutoLoan,
      payerRelationshipToApplicant: ppRealationshipWithYou,

    };

    const formsData = {
      ...allFormsInfo,
      vehicleInfoForm: vehicleInfo
    };
    const payload = {
      allFormsInfo: formsData,
      currentPage,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({
        ...allFormsInfo,
        vehicleInfoForm: vehicleInfo
      })
    );
    const response = await saveAllFormsData(payload);
    return response;
  }

  const onSaveClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "vehicleInfoSaved",
      eventDescription: '',
      eventData: "",
    }
    rdpEventHandler(eventPayload)
    saveVehicleInfoForm('vehicleInfo').then(res => {
      if (res === "Success") {
        setShowMessage(true)
      }
    });
  }
  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
      console.log(eventPayload.eventName);
    });
  }
  const onNextClick = (e) => {
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "residenceInfoCompleted",
      eventDescription: '',
      eventData: "",
    }
    e.preventDefault();
    if (validate()) {
      rdpEventHandler(eventPayload)

      saveVehicleInfoForm('financinginfo').then(res => {
        if (res === "Success") {
          const { vehicleInfo: { status } } = processingStatus;
          if (status !== "completed") {
            dispatch(setVehicleInfoStatus('completed'));
          }
          eventPayload.eventName = 'financingInfoStarted'
          rdpEventHandler(eventPayload)
          history.push("/financinginfo")
        }
      });

    }

  }

  const validate = () => {
    const errors = {};

    if (vehicleCondition === "") {
      errors.vehicleCondition = "Required selection";
    }
    if (!posessionOfVehicleApplicant && !posessionOfVehicleJoint) {
      errors.vehiclePossession = "Required selection";
    }
    if ((posessionOfVehicleApplicant=="No" || posessionOfVehicleJoint=="No") && vehicleLocation=="") {
      errors.vehicleLocation = "Required selection";
    }
    if (isprimaryDriver === "") {
      errors.isprimaryDriver = "Required selection";
    }

    if (isprimaryDriver === "No" && primaryDriverName === "") {
      errors.primaryDriverName = "Please enter Primary Driver name";
    }

    if (isPrimaryPayer === "") {
      errors.isPrimaryPayer = "Required selection";
    }

    if (isPrimaryPayer === "No" && primaryPayerName === '') {
      errors.primaryPayerName = "Please enter Primary Payer name ";
    }
     setErrors(errors)

    if (errors.vehicleCondition || errors.vehiclePossession || errors.vehicleLocation || errors.isprimaryDriver || errors.primaryDriverName || errors.isPrimaryPayer || errors.primaryPayerName) {
      return false;
    }

    return true
  };



  const selectedYear = (e) => {
    const index = e.target.selectedIndex;
    const ele = e.target.childNodes[index];
    const code = ele.getAttribute('id');
    const value = ele.getAttribute('value')
    if (e.target.value === "")
      return;
    setYear(value)
    setModel('')
    setMake('')
    getUniqueMakeModels(code)
  }
  const getUniqueMakeModels = (code) => {
    const payload = {
      code
    }
    getMakeModel(payload).then(res => {
      if (res) {


        var uniqueMakeModels = [];

        res.map((list) => {
          if (!(uniqueMakeModels).some((x) => x.make == list.make)) {
            let makeModelObject = {
              make: '', model: []
            }
            makeModelObject.make = list.make
            uniqueMakeModels.push(makeModelObject)
          }
        });
        uniqueMakeModels.map(makemodel => {
          var filteredmakes = res.filter(makeModelList => {
            return makemodel.make == makeModelList.make
          })
          filteredmakes.map(x => makemodel.model.push(x.model));
        })
        if (make) {
          var models = uniqueMakeModels.find((makeModel) => {
            return makeModel.make == make
          })
          setFilteredModels(models ? models.model : [])
        }
        setMakeModels(uniqueMakeModels)
      }
    }

    );
  }

  const makeHandler = (e) => {
    setMake(e.target.value)
    var models = makeModels.find((makeModel) => {
      return makeModel.make == e.target.value
    })
    setFilteredModels(models.model)

  }

  const modelHandler = (e) => {
    setModel(e.target.value)

  }

  return (
    <Container className='min-vh-100'>
      <div className='mb-3'>
        <ProcessingScreen />
        <Row>
          <h3 className='mt-3 mb-4'> Vehicle Information Form</h3>
        </Row>

        <Row className='' style={{ marginBottom: "3rem" }}>
          <Col md={{ span: 6, offset: 3 }}>
            <Form >
              <AutoLoanContactInfo />
              {/* <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <Form.Label className='formLabel'>
                    <small>
                      Please bare with us as we confirm information from your Auto
                      Loan Contracts. Having your contract available will speed up
                      this process
                    </small>
                  </Form.Label>
                </Form.Group>
              </Row> */}

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      {ApplicationType === "Joint"
                        ? "Do you or the CoApplicant have possession of the vehicle?"
                        : "Do you have possession of the vehicle?"
                      }
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='vehiclePossession'
                        type='radio'
                        label='Yes'
                        id='yes'
                        checked={ApplicationType === "Joint" ? posessionOfVehicleJoint === 'Yes' : posessionOfVehicleApplicant === 'Yes'}
                        value="New"
                        onChange={(e) => {
                          if (ApplicationType === "Joint") {
                            setposessionofvehicleJoint("Yes");
                            setposessionofvehicleApplicant("");
                            setVehicleLocation("")
                          } else {
                            setposessionofvehicleApplicant("Yes");
                            setposessionofvehicleJoint("");
                            setVehicleLocation("")
                          }
                          setErrors({ ...errors, vehiclePossession: !e.target.value ? "Selection Required" : '' })
                        }}
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='vehiclePossession'
                        type='radio'
                        checked={ApplicationType === "Joint" ? posessionOfVehicleJoint === 'No' : posessionOfVehicleApplicant === 'No'}
                        label='No'
                        id='no'
                        onChange={(e) => {
                          if (ApplicationType === "Joint") {
                            setposessionofvehicleJoint("No");
                            setposessionofvehicleApplicant("");
                            setVehicleLocation("")
                          } else {
                            setposessionofvehicleApplicant("No");
                            setposessionofvehicleJoint("")
                            setVehicleLocation("")
                          }
                          setErrors({ ...errors, vehiclePossession: !e.target.value ? "Selection Required" : '' })
                        }}
                        style={{ display: "inline-block", paddingRight: "20px" }}
                      />
                    </div>

                    <div className='invalid-feedback1 formLabel'>
                      {errors.vehiclePossession}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              {(posessionOfVehicleJoint === "No" || posessionOfVehicleApplicant === "No") && (
                <Row className='mb-3'>
                  <Form.Group
                    as={Col}
                    md='12'
                    controlId='vehicleLocation'
                  >
                    <Form.Label className='formLabel'>
                      Where is the vehicle?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <Form.Select className="form"
                      name='vehicleLocation'
                      value={vehicleLocation}
                      onChange={(e) => {
                        setVehicleLocation(e.target.value)
                      }}
                    >
                      <option value='' label='Select an option' />
                      <option value='1'>The dealer still has the vehicle</option>
                      <option value='2'>A third party, not the dealer, is in possession of the vehicle</option>
                      <option value='3'>The vehicle is in a repair shop</option>
                      <option value='4'>Other</option>
                    </Form.Select>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.vehicleLocation}
                    </div>
                  </Form.Group>
                </Row>
              )}

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Is the vehicle new or used?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='collateralVehicleCondition'
                        type='radio'
                        label='New'
                        id='new'
                        checked={vehicleCondition === 'New'}
                        value="New"
                        onChange={(e) => {
                          setVehicleCondition("New")
                          setErrors({ ...errors, vehicleCondition: !e.target.value ? 'Selection Required' : '' })

                        }}

                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='collateralVehicleCondition'
                        type='radio'
                        checked={vehicleCondition === 'Used'}
                        label='Used'
                        id='used'
                        onChange={(e) => {
                          setVehicleCondition('Used')
                          setErrors({ ...errors, vehicleCondition: !e.target.value ? 'Selection Required' : '' })
                        }}
                        style={{ display: "inline-block", paddingRight: "20px" }}
                      />
                    </div>

                    <div className='invalid-feedback1 formLabel'>
                      {errors.vehicleCondition}
                    </div>
                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId=''>
                  <Form.Label className='formLabel'>
                    Year
                    <span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Select className="form"
                    name='collateralYear'
                    value={year}
                    data-id='year'
                    style={{ display: "block" }}
                    onChange={(e) => selectedYear(e)}
                  >
                    <option value='' label='Select an option' />
                    {optionYear && optionYear.length > 0 &&
                      optionYear.map((option) => {
                        const { year, id, vinyearcode } = option;
                        return <option key={id} id={vinyearcode} value={year} >{year}</option>
                      })}

                  </Form.Select>

                  <div className='input-feedback'>
                    {/* {!year && <div>Required Year </div>} */}
                  </div>

                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId=''>
                  <Form.Label className='formLabel'>
                    Make
                    <span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Select className="form"
                    name='collateralMake'
                    value={make}
                    onChange={(e) => makeHandler(e)}
                    data-id='make'
                    style={{ display: "block" }}
                  >
                    <option value='' label='Select an option' />
                    {makeModels && makeModels.length > 0 && makeModels.map((makes) => {
                      const { make } = makes;
                      return <option key={make} value={make}>{make}</option>
                    }
                    )}

                  </Form.Select>
                  <div className='input-feedback'>
                    {/* {!make && <div>Required Year </div>} */}
                  </div>
                </Form.Group>
              </Row>
              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId='collateralModel'>
                  <Form.Label className='formLabel'>
                    Model
                    <span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Select className="form"
                    name='collateralModel'
                    value={model}
                    onChange={(e) => { modelHandler(e) }}
                    data-id='model'
                    style={{ display: "block" }}
                  >
                    <option value='' label='Select an option' />
                    {filteredModels && filteredModels.length > 0 && filteredModels.map((models) => {
                      return <option value={models} >{models}</option>
                    })}



                  </Form.Select>
                  <div className='input-feedback'>
                    {/* {!model && <div>Required Year </div>} */}
                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12' controlId=''>
                  <Form.Label className='formLabel'>
                    Color<span className='star-required'>&nbsp;</span>
                  </Form.Label>
                  <Form.Control
                    placeholder=''
                    name='collateralColor'
                    type='text'
                    onChange={(e) => { setColor(e.target.value) }}
                    data-id='color'
                    value={color}
                  />
                  <div className='invalid-feedback1 formLabel'>
                  </div>
                </Form.Group>
              </Row>

              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel' >
                      Will you be the primary driver of the vehicle?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='primaryDriverOfVehicle'
                        type='radio'
                        label='Yes'
                        // value='Yes'
                        onChange={(e) => {
                          setIsPrimaryDriver("Yes");
                          setErrors({ ...errors, isprimaryDriver: !e.target.value ? 'Selection Required' : '' })
                        }}
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        checked={isprimaryDriver === 'Yes' ? true : false}
                      />
                      <Form.Check
                        name='primaryDriverOfVehicle'
                        type='radio'
                        label='No'
                        // value='No'
                        style={{ display: "inline-block", paddingRight: "25px" }}

                        onChange={(e) => {
                          setIsPrimaryDriver("No");
                          setErrors({ ...errors, isprimaryDriver: !e.target.value ? 'Selection Required' : '' })
                        }}
                        // className='mt-4'

                        checked={isprimaryDriver === 'No' ? true : false} />

                    </div>
                    <div className='invalid-feedback1 formLabel'>
                      {errors.isprimaryDriver}
                    </div>
                  </div>
                </Form.Group>
              </Row>

              {isprimaryDriver === "No" && <>
                <Row className='mb-3'>
                  <Form.Group as={Col} md='12' controlId=''>
                    <Form.Label className='formLabel'>
                      What is the name of the primary driver of the vehicle ?
                      <span className='star-required'>&nbsp;*</span>
                    </Form.Label>

                    <Form.Control
                      name='primaryDriverOther'
                      type='text'
                      onChange={(e) => {
                        setPrimaryDriverName(e.target.value);
                        setErrors({ ...errors, primaryDriverName: !e.target.value ? 'Please enter amount' : '' })
                      }}
                      data-id='primarydriver'
                      value={primaryDriverName}

                    />

                    <div className='invalid-feedback1 formLabel'>
                      {errors.primaryDriverName}
                    </div>
                  </Form.Group>
                </Row>
              </>}
              {isprimaryDriver === "No" && <><Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Is this person on the Auto Loan contract?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <div className='d-flex'>

                      <Form.Check
                        name='isDriverOnContract'
                        type='radio'
                        label='Yes'
                        id='Contract'
                        value='Yes'
                        checked={isPDAutoLoan === 'Yes' ? true : false}
                        onChange={(e) => { setIsPDAutoLoan('Yes') }}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                      <Form.Check
                        name='isDriverOnContract'
                        type='radio'
                        label='No'
                        id='Contract'
                        value='No'
                        checked={isPDAutoLoan === 'No' ? true : false}
                        onChange={(e) => { setIsPDAutoLoan('No') }}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Row>
                <Row className='mb-3'>
                  <Form.Group
                    as={Col}
                    md='12'
                    controlId='driverRelationshipToApplicant'
                  >
                    <Form.Label className='formLabel'>
                      What is their relationship to you?
                      <span className='star-required'>&nbsp;</span>
                    </Form.Label>
                    <Form.Select className="form"
                      name='driverRelationshipToApplicant'
                      value={pdRelationshipWithYou}
                      onChange={(e) => {
                        setPDRelationshipWithYou(e.target.value)
                      }}
                    >
                      <option value='0'>Select an option</option>
                      <option value='1'>Co-borrower</option>
                      <option value='5'>Employer</option>
                      <option value='4'>Friends</option>
                      <option value='6'>Other</option>
                      <option value='2'>Parents</option>
                      <option value='3'>Relative</option>
                      <option value='207' >Spouse</option>
                    </Form.Select>
                    <div className='input-feedback'>
                      {/* {!relationship && <div> Please select the Relationship from the list </div>} */}
                    </div>
                  </Form.Group>
                </Row>
              </>}


              <Row className='mb-3'>
                <Form.Group as={Col} md='12'>
                  <div
                    id='radioGroup1'
                    role='group'
                    aria-labelledby='my-radio-group'
                  >
                    <Form.Label className='formLabel'>
                      Will you be making the payments on this loan ? If not, indicate who will be responsible below.
                      <span className='star-required'>&nbsp;* &nbsp; &nbsp;</span>
                    </Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        name='primaryPayerofVehicle'
                        type='radio'
                        label='Yes'
                        id='Yes'
                        checked={isPrimaryPayer === 'Yes' ? true : false}
                        onChange={(e) => {
                          setIsPrimaryPayer('Yes')
                          setErrors({ ...errors, isPrimaryPayer: !e.target.value ? 'Selection Required' : '' })
                        }}
                        // className='mt-4'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                      // value='Yes'
                      />
                      <Form.Check
                        name='primaryPayerofVehicle'
                        type='radio'
                        label='No'
                        id='No'
                        style={{ display: "inline-block", paddingRight: "25px" }}
                        checked={isPrimaryPayer === 'No' ? true : false}
                        onChange={(e) => {
                          setIsPrimaryPayer('No')
                          setErrors({ ...errors, isPrimaryPayer: !e.target.value ? 'Selection Required' : '' })
                        }}
                      />
                    </div>
                  </div>
                </Form.Group>
                <div className='invalid-feedback1 formLabel'>
                  {/* <div style={{ margin: "20px 0 0 10px" }}> */}
                  {errors.isPrimaryPayer}
                  {/* </div> */}
                </div>
              </Row>


              {isPrimaryPayer === 'No' && (
                <>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12' controlId=''>
                      <Form.Label className='formLabel'>
                        What is the name of the person who will be primarily responsible for making payments?
                        <span className='star-required'>&nbsp;*</span>
                      </Form.Label>
                      <Form.Control
                        placeholder=''
                        name='primaryPayerOther'
                        type='text'
                        onChange={(e) => {
                          setPrimaryPayerName(e.target.value);
                          setErrors({ ...errors, primaryPayerName: !e.target.value ? 'Please enter primary payer' : '' })
                        }}

                        // value={formik.values.primaryPayerOther}
                        value={primaryPayerName}
                        data-id='primarypayer'

                      />
                      <div className='invalid-feedback1 formLabel'>
                        {errors.primaryPayerName}
                      </div>
                    </Form.Group>
                  </Row>

                  <Row className='mb-3'>
                    <Form.Group as={Col} md='12'>
                      <div
                        id='radioGroup1'
                        role='group'
                        aria-labelledby='my-radio-group'
                      >
                        <Form.Label className='formLabel'>
                          Is this person on the Auto Loan contract?
                          <span className='star-required'>&nbsp;</span>
                        </Form.Label>
                        <div className='d-flex'>
                          <Form.Check
                            name='isPayerOnContract'
                            type='radio'
                            label='Yes'
                            checked={isPPAutoLoan === 'Yes' ? true : false}
                            onChange={() => { setIsPPAutoLoan('Yes') }}
                            style={{ display: "inline-block", paddingRight: "25px" }}
                          />
                          <Form.Check
                            name='isPayerOnContract'
                            type='radio'
                            label='No'
                            checked={isPPAutoLoan === 'No' ? true : false}
                            onChange={() => { setIsPPAutoLoan('No') }}
                            style={{ display: "inline-block", paddingRight: "25px" }}
                          />

                        </div>

                        {/* <div className='invalid-feedback1 formLabel'>
                        <div style={{ margin: "20px 0 0 10px" }}>

                        </div>
                      </div> */}
                      </div>
                    </Form.Group>
                  </Row>
                  <Row className='mb-3'>
                    <Form.Group
                      as={Col}
                      md='12'
                      controlId='payerRelationshipToApplicant'
                    >
                      <Form.Label className='formLabel'>
                        What is their relationship to you?
                        <span className='star-required'>&nbsp;</span>
                      </Form.Label>
                      <Form.Select className="form"
                        name='payerRelationshipToApplicant'
                        value={ppRealationshipWithYou}
                        onChange={(e) => { setPPRelationshipWithYou(e.target.value) }}
                        data-id='relationshipwithyou'
                        style={{ display: "block" }}
                      >
                        <option value='0'  >Select an option</option>
                        <option value='1' >Co-borrower</option>
                        <option value='5' >Employer</option>
                        <option value='4' >Friends</option>
                        <option value='6'  >Other</option>
                        <option value='2'>Parents</option>
                        <option value='3'  >Relative</option>
                        <option value='207' >Spouse</option>
                      </Form.Select>
                      <div className='input-feedback'>

                      </div>

                    </Form.Group>
                  </Row>
                </>
              )
              }
              <Row style={{ marginBottom: '10px' }}>
                <Col className='d-flex justify-content-between'>
                  <Button type='submit' onClick={onSaveClick}>Save</Button>
                </Col>
              </Row>
              {showMessage && <Row>
                <NotificationBar variant="success" message="Your data is saved!" />
              </Row>}
            </Form>
          </Col>
        </Row >
        <Row>
          <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
            <NextStepsButton text="Previous" isDisable={false} onNextClick={onPreviousClick} />
            <NextStepsButton text="Next" isDisable={false} onNextClick={onNextClick} />
          </Col>

        </Row>
      </div >
    </Container >
  );
};
export default VehicleInfo;

export const actionTypes = {
  //COMPLETED:'completed'
  SET_PERSONAL_INFO_INPROGRESS: "SET_PERSONAL_INFO_INPROGRESS",
  SET_PERSONAL_INFO_STATUS: "SET_PERSONAL_INFO_STATUS",
  SET_CONTACT_INFO_STATUS: "SET_CONTACT_INFO_STATUS",
  SET_RESIDENCE_INFO_STATUS: "SET_RESIDENCE_INFO_STATUS",
  SET_VEHICLE_INFO_STATUS: "SET_VEHICLE_INFO_STATUS",
  SET_FINANCING_INFO_STATUS: "SET_FINANCING_INFO_STATUS",
  SET_CONTRACT_ACK_STATUS: "SET_CONTRACT_ACK_STATUS",
  SAVE_PERSONAL_INFO_FORM: "SAVE_PERSONAL_INFO_FORM",
  SAVE_RESIDENCE_INFO_FORM: "SAVE_RESIDENCE_INFO_FORM",
  SAVE_VEHICLE_INFO_FORM: "SAVE_VEHICLE_INFO_FORM",
  SAVE_CONTACT_INFO_FORM: "SAVE_CONTACT_INFO_FORM",
  SAVE_FINANCING_INFO_FORM: "SAVE_FINANCING_INFO_FORM",
  SAVE_CONTRACT_ACK_INFO_FORM: "SAVE_CONTRACT_ACK_INFO_FORM",

  POST_ALL_FORMS_INFO: "POST_ALL_FORMS_INFO",
  //default state
  SET_DEFAULT_PROCESSING_STATUS: "SET_DEFAULT_PROCESSING_STATUS",
  SET_DEFAULT_ALL_FORMS_INFO_STATE: "SET_DEFAULT_ALL_FORMS_INFO_STATE"
};

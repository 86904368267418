import React from "react";
import { Col, Container, Row } from "react-bootstrap";


const FinalResult =() => {

    
    return (
        <Container>
        <div className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
            <div className='info-section-block'>
              <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1' >
                My Auto Loan Portal
              </p>
  
              <div className='mb-3 offset-md-1 col-md-10 subInfo1 mt-2'>
                <div className='invalid-feedback1 formLabel'>
                <div style={{ color: 'black' }}>
          <p className='mb-3 col-md-12 subInfo1'>
            Thank you for completing our authentication process. A funding analyst
            will be reaching out to you soon to complete a Customer Interview. If
            you would like to complete the process sooner, you may also contact
            Crescent Bank's Funding Department at 844-462-2866 during normal business hours. </p>
        </div>
                  {/* {showError ? <div className=''>{errorMessage}</div> : null} */}
                </div>
              </div>
              <Row>
                <Col>
                  {/* {TrueIDRequired ==0 && <Button onClick={proceed}>Proceed</Button>} */}
                </Col>
              </Row>
  
  
            </div>
          </Col>
        </div>
      </Container>
    )
}

export default FinalResult;
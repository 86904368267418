import { actionTypes } from "./NextFormActionTypes";

import { useSelector } from "react-redux";
import { BsNodePlusFill } from "react-icons/bs";

const initialState = {
  allFormsInfo: {
    personalInfoForm: {
      city: "",
      dob: "",
      firstName: "",
      lastName: "",
      middleName: "",
      ssn: "",
      state: "",
      street: "",
      suffix: "",
      zipCode: "",
    },
    residenceInfoForm: {
      residenceStatus: "",
      residenceStatusOther: "",
      // responsibleForEntirePayment: false,
      monthlyRentPayment: "",
      amountResponsibleFor: "",
      isEntireMonthlyPayment: '',
    },
    vehicleInfoForm: {
      collateralVehicleCondition: "",
      collateralYear: "",
      collateralMake: "",
      collateralModel: "",
      collateralColor: "",
      isprimaryDriverOfVehicle: "",
      primaryDriverOther: "",
      isDriverOnContract: "",
      driverRelationshipToApplicant: "",
      isprimaryPayerofVehicle: "",
      primaryPayerOther: "",
      isPayerOnContract: "",
      payerRelationshipToApplicant: "",
      posessionOfVehicleJoint: "",
      PosessionofvehicleApplicant: "",
      vehicleLocation: ""
    },
    contactInfoForm: {
      homePhone: "",
      cellPhone: "",
      workPhone: "",
      isPhysicalAddress: '',
      isPhoneNumberSame: false,
      emailAddress: "",
      billingStreetAddress: "",
      billingAddressZipCode: "",
      billingAddressCity: "",
      billingAddressState: "",
      preferredContactMethod: "",
      employmentStatus: "",
      monthsEmployed: "",
      yearsEmployed: "",
      employerfullAddress: "",
      rideShare:""
    },
    contractAckInfoForm: {
      paymentAmount: "",
      firstPaymentDueDate: "",
      isPaymentMadeToDealer: "",
      agreeToMakePaymentsToDealer: "",
      liableForPayments: "",
      confirmationOfPaymentAmtDate: false,
    },
    financingInfoForm: {
      contractSignLocation: "",
      downPaymentAmount: "",
      isPaidInFull: "",
      // whenWillItBePaid: "",
      isRebateObatined: "",
      rebateAmount: "",
      isCashDownFromRebate: "",
      isoweDownPayment: "",
      oweDownPaymentToWhom: "",
      isPostOrHoldCheck: "",
      holdCheckAmount: "",
      holdCheckDate: "",
      isSalesTaxAcknowledgement: "",
      isPennDotSalesTax: "No",
      isTradeInVehicle: "",
      tradeInYear: "",
      tradeInMake: "",
      tradeInModel: "",
      tradeInLienholder: "",
      fullPaymentDate: "",
      amountNotPaid: ""
    },

  },
};

export const allFormsReducer = (state, action) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case actionTypes.SAVE_PERSONAL_INFO_FORM:
      return {

        ...state,
        // ...state.personalInfoForm,

        allFormsInfo: {
          personalInfoForm: {
            ...state.personalInfoForm,

            city: action.payload.city,
            dob: action.payload.dob,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            middleName: action.payload.middleName,
            ssn: action.payload.ssn,
            state: action.payload.state,
            street: action.payload.street,
            suffix: action.payload.suffix,

            zipCode: action.payload.zipCode,
            //contact info
          },
        }
      };
    case actionTypes.SAVE_RESIDENCE_INFO_FORM:
      return {
        ...state,
        allFormsInfo: {

          residenceInfoForm: {
            ...state.residenceInfoForm,
            residenceStatus: action.payload.residenceStatus,
            responsibleForEntirePayment:
              action.payload.responsibleForEntirePayment,
            monthlyRentPayment: action.payload.monthlyRentPayment,
            rentAmountResponsibleFor: action.payload.rentAmountResponsibleFor,
          },
        }
      };
    case actionTypes.SAVE_VEHICLE_INFO_FORM:
      return {
        ...state,
        allFormsInfo: {
          vehicleInfoForm: {
            ...state.vehicleInfoForm,
            collateralVehicleCondition: action.payload.collateralVehicleCondition,
            collateralYear: action.payload.collateralYear,
            collateralMake: action.payload.collateralMake,
            collateralModel: action.payload.collateralModel,
            collateralColor: action.payload.collateralColor,
            primaryDriverOfVehicle: action.payload.primaryDriverOfVehicle,
            primaryDriverOther: action.payload.primaryDriverOther,
            isDriverOnContract: action.payload.isDriverOnContract,
            driverRelationshipToApplicant:
              action.payload.driverRelationshipToApplicant,
            primaryPayerofVehicle: action.payload.primaryPayerofVehicle,
            primaryPayerOther: action.payload.primaryPayerOther,
            isPayerOnContract: action.payload.isPayerOnContract,
            payerRelationshipToApplicant:
              action.payload.payerRelationshipToApplicant,
            PosessionofvehicleApplicant: action.payload.PosessionofvehicleApplicant,
            PosessionofvehicleJoint: action.payload.posessionOfVehicleJoint,
            vehicleLocation: action.payload.vehicleLocation
          },
        }
      };
    case actionTypes.SAVE_CONTACT_INFO_FORM:
      return {
        ...state,
        allFormsInfo: {
          contactInfoForm: {
            ...state.contactInfoForm,
            homePhone: action.payload.homePhone,
            cellPhone: action.payload.cellPhone,
            workPhone: action.payload.workPhone,
            emailAddress: action.payload.emailAddress,
            billingStreetAddress: action.payload.billingStreetAddress,
            billingAddressZipCode: action.payload.billingAddressZipCode,
            billingAddressCity: action.payload.billingAddressCity,
            billingAddressState: action.payload.billingAddressState,
            preferredContactMethod: action.payload.preferredContactMethod,
            employmentStatus: action.payload.employmentStatus,
            monthsEmployed: action.payload.monthsEmployed,
            yearsEmployed: action.payload.yearsEmployed,
            employerfullAddress: action.payload.employerfullAddress,
            rideShare:action.payload.rideShare
          },
        }
      };
    case actionTypes.SAVE_FINANCING_INFO_FORM:
      return {
        ...state,
        allFormsInfo: {
          financingInfoForm: {
            ...state.financingInfoForm,
            contractSignLocation: action.payload.contractSignLocation,
            downPaymentAmount: action.payload.downPaymentAmount,
            isPaidInFull: action.payload.isPaidInFull,
            whenWillItBePaid: action.payload.whenWillItBePaid,
            amountNotPaid: action.payload.amountNotPaid,
            rebateAmount: action.payload.rebateAmount,
            isCashDownFromRebate: action.payload.isCashDownFromRebate,
            oweDownPayment: action.payload.oweDownPayment,
            oweDownPaymentToWhom: action.payload.oweDownPaymentToWhom,
            isPostOrHoldCheck: action.payload.isPostOrHoldCheck,
            holdCheckAmount: action.payload.holdCheckAmount,
            holdCheckDate: action.payload.holdCheckDate,
            SalesTaxAcknowledgement: action.payload.SalesTaxAcknowledgement,
            checkForPennDotSalesTax: action.payload.checkForPennDotSalesTax,
            didTradeInVehicle: action.payload.didTradeInVehicle,
            tradeInYear: action.payload.tradeInYear,
            tradeInMake: action.payload.tradeInMake,
            tradeInModel: action.payload.tradeInModel,
            tradeInLienholder: action.payload.tradeInLienholder,
            isRebateObtained: action.payload.isRebateObtained,
            fullPaymentDate: action.payload.fullPaymentDate
          },
        }
      };
    case actionTypes.SAVE_CONTRACT_ACK_INFO_FORM:
      return {
        ...state,
        allFormsInfo: {
          contractAckInfoForm: {
            ...state.contractAckInfoForm,
            paymentAmount: action.payload.paymentAmount,
            firstPaymentDueDate: action.payload.firstPaymentDueDate,
            isPaymentMadeToDealer: action.payload.isPaymentMadeToDealer,
            agreeToMakePaymentsToDealer:
              action.payload.agreeToMakePaymentsToDealer,
            liableForPayments: action.payload.liableForPayments,
            confirmationOfPaymentAmtDate:
              action.payload.confirmationOfPaymentAmtDate,
          },
        }
      };
    case actionTypes.POST_ALL_FORMS_INFO:
      return {
        ...state,
        allFormsInfo: action.payload,
      };

    case actionTypes.SET_DEFAULT_ALL_FORMS_INFO_STATE:
      return initialState;

    default:
      return state;
  }
};

import React from 'react'
import { Form, Container, Row, Col, Button } from "react-bootstrap";

function AutoLoanContactInfo() {
    return (
        <Row className='mb-3'>
            <Form.Group as={Col} md='12'>
                <Form.Label className='formLabel'>
                    <small>
                        Please provide the information below to help us finalize your auto loan.
                    </small>
                </Form.Label>
            </Form.Group>
        </Row>
    )
}

export default AutoLoanContactInfo
import React from 'react'
import banklogo from '../images/CrescentLogoNew.png';
import { Container, Row, Col } from 'react-bootstrap'
// import { FcPhone } from 'react-icons/fc';
import { FaPhoneAlt, FaUserAlt } from 'react-icons/fa';
import '../App.css';

function Header() {
    return (
        <div className="header-new">
            <div className='car-header global-menu-background'>
                <h2 className='container autoLoanTitle'>
                    My Auto Loans
                </h2>
            </div>

            <Container>
                <div className='bank-logo'>
                    <div>
                        <Row className="bank-logo-header">
                            <img src={banklogo} alt="crescent-bank-logo" style={{ "maxWidth": "240px" }} />
                        </Row>
                    </div>
                    <div style={{ display: 'flex', marginTop: '15px' }}>
                        <div>
                            <a className='phone-number' href='tel:8444622866'>

                                <FaPhoneAlt size={15} style={{ marginRight: '0.5rem', fill: '#37aa46', position: 'relative', top: '5px' }} />
                                <span style={{ display: 'flex', alignItems: 'center' }}>  844-462-2866</span>
                            </a>
                        </div>
                        {/* <div style={{paddingLeft:'0.5rem'}}><FaUserAlt size={25}/></div> */}
                    </div>
                </div>

            </Container>

        </div>
    )
}

export default Header

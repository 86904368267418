var fields = [
    { element: "iAddress1", field: "Address1", mode: window.so.fieldMode.SEARCH | window.so.fieldMode.POPULATE },
    { element: "iAddress2", field: "Address2", mode: window.so.fieldMode.POPULATE },
    { element: "iAddress3", field: "Address3", mode: window.so.fieldMode.POPULATE },
    { element: "iUnits", field: "SubPremise", mode: window.so.fieldMode.POPULATE },
    { element: "iLocality", field: "Locality", mode: window.so.fieldMode.POPULATE },
    { element: "iAdminArea", field: "AdminArea", mode: window.so.fieldMode.POPULATE },
    { element: "iPostal", field: "PostalCode", mode: window.so.fieldMode.POPULATE },
    { element: "iCountry", field: "Country", mode: window.so.fieldMode.POPULATE }
 ];
 
 
 window.options = { key: process.env.REACT_APP_SERVICE_OBJECTS_LICENSE_KEY, setBiasToIP: true, isTrial: process.env.REACT_APP_SERVICE_OBJECTS_ISTRAIL };
  var DOTSGlobalAddressComplete = new window.so.Address(fields, window.options);
  export default DOTSGlobalAddressComplete;
import { findAllByDisplayValue } from "@testing-library/react";

export const actionTypes = {
  SET_APPLICANT_INFO: 'SET_APPLICANT_INFO',
  SET_DEFAULT_APPLICANT_INFO_STATE: 'SET_DEFAULT_APPLICANT_INFO_STATE',
  TRUE_ID_COMPLETE: 'TRUE_ID_COMPLETE',
  DOCUMENT_UPLOAD_COMPLETE: 'DOCUMENT_UPLOAD_COMPLETE',
  PINWHEEL_STATUS_UPDATE: 'PINWHEEL_STATUS_UPDATE',
  CUSTOMERINTERVIEW_STATUS_UPDATE: 'CUSTOMERINTERVIEW_STATUS_UPDATE',


}
export const setApplicantInfo = (responseObject) => {
  return { type: actionTypes.SET_APPLICANT_INFO, payload: responseObject };
};
export const setDefaultApplicantInfoState = () => {
  return { type: actionTypes.SET_DEFAULT_APPLICANT_INFO_STATE, payload: {} };
};

const initialState = {
  ApplicationNumber: '',
  ApplicantID: '',
  CellPhoneNumber: '',
  EmailAddress: '',
  ApplicantFirstName: '',
  ApplicantLastName: '',
  FirstPaymentDate: '',
  Payment: '',
  TrueIDRequired: '',
  isCoApplicant: '',
  ApplicationType: '',
  TransactionStatus: '',
  trueIDStatus: '',
  pinwheelStatus: 0,
  PinwheelRequired: '',
  CustomerInterviewRequired: '',
  isTrueIDComplete: false,
  customerInterviewStatus: 'notstarted',
  isLoanfunded:false,
  DealerState:""
};


export const otpReducer = (state, action) => {


  if (!state) {
    state = initialState;
  }
  switch (action.type) {

    case actionTypes.SET_APPLICANT_INFO:
      return {
        ...state,
        ApplicationNumber: action.payload.ApplicationNumber,
        ApplicantID: action.payload.ApplicantID,
        CellPhoneNumber: action.payload.CellPhoneNumber,
        EmailAddress: action.payload.EmailAddress,
        ApplicantFirstName: action.payload.ApplicantFirstName,
        ApplicantLastName: action.payload.ApplicantLastName,
        FirstPaymentDate: action.payload.FirstPaymentDate,
        Payment: action.payload.Payment,
        TrueIDRequired: action.payload.TrueIDRequired,
        isCoApplicant: action.payload.isCoApplicant,
        ApplicationType: action.payload.ApplicationType,
        TransactionStatus: action.payload.Status.TransactionStatus,
        CustomerInterviewRequired: action.payload.CustomerInterviewRequired,
        customerInterviewStatus: action.payload.customerInterviewStatus,
        PinwheelRequired: action.payload.PinwheelRequired,
        trueIDStatus: action.payload.trueIDStatus,
        pinwheelStatus: parseInt(action.payload.pinwheelStatus),
        isLoanFunded: action.payload.isLoanFunded,
        DealerState:action.payload.DealerState

      };
    case actionTypes.TRUE_ID_COMPLETE:
      return { ...state, trueIDStatus: "pending", TrueIDComplete: true };

    case actionTypes.PINWHEEL_STATUS_UPDATE:
      return { ...state, pinwheelStatus: state.pinwheelStatus + 1 }

    case actionTypes.DOCUMENT_UPLOAD_COMPLETE:
      return { ...state, DocumentUploadComplete: true };

    case actionTypes.CUSTOMERINTERVIEW_STATUS_UPDATE:
      return { ...state, customerInterviewStatus: "complete" }

    case actionTypes.SET_DEFAULT_APPLICANT_INFO_STATE:
      return initialState;


    default:
      return state;
  }
};
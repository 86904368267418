import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { Form, Container, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import "../App.css";
import CustomModal from "./helpers/CustomModal";
import { getAllFormsData } from "../api/Services";
import { useSelector, useDispatch } from "react-redux";
import { postAllFormsInfo } from "../Redux/nextForms/AllFormsActions";
import { setDefaultProcessingStatus } from "../Redux/nextForms/NextFormActions";

const Otp2 = () => {
  const history = useHistory();
  const location = useLocation();
  const overallState = useSelector((state) => state);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [modalShow, setModalShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { personalInfoForm } = allFormsInfo;
  const { ApplicationNumber, ApplicantID, TrueIDRequired } = otpProcess;
  // const { ApplicationNumber, ApplicantID } = overallState.otpProcess;

  const dispatch = useDispatch();


  useEffect(() => {
    if (!location.state) {
      history.push('/');
    }

  }, []);

  const handleClose = () => setModalShow(false);
  const validate = (values) => {
    const errors = {};
    if (!values.passCode) {
      errors.passCode = 'Please enter the authentication code';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      passCode: '',
    },
    validate,
    onSubmit: (values) => {
      if (formik.isValid) {
        const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNVALIDATE_OTP}`;
        //Request header
        const options = {
          headers: {
            //'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: process.env.REACT_APP_BASIC_AUTHTOKEN,
          },
        };

        const payload = {
          ApplicantID: ApplicantID,

          ApplicationNumber: ApplicationNumber,

          passcode: values.passCode,
        };
        postData(url, payload, options);
      }
    },
  });
  const goBackOtp1Page = () => {
    history.push('/otp1', {
      ApplicantID: ApplicantID,
      ApplicationNumber: ApplicationNumber,
    });
  };
  const postData = async (url, body, headers) => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, body, headers);

      if (response && response.data) {
        const responseData = await response.data;
        setIsLoading(false);

        if (responseData.Status.TransactionStatus === "passed") {

          const payload = {
            applicationNumber: ApplicationNumber,
            applicantId: ApplicantID
          }

          await getAllFormsData(payload).then(res => {
            if (res && res.CustomerInterviewData) {
              const data = JSON.parse(res.CustomerInterviewData);
              dispatch(postAllFormsInfo(data.allFormsInfo));
              dispatch(setDefaultProcessingStatus());
            }

          }) 
          history.push(`/verifydocument`, responseData)
        }

        else if (responseData.Status.TransactionStatus === "pending") {
          setShowError(true);
          setErrorMessage(
            'The code submitted is incorrect, please resubmit with correct code.'
          );
        } else if (responseData.Status.TransactionStatus === 'failed') {
          setShowError(true);
          setErrorMessage(
            <span>
              Too many incorrect attempts. Please click &nbsp;
              <span className='pointer' onClick={goBackOtp1Page}>
                <b>here</b>
              </span>
              &nbsp;to reinitiate the Authentication process.
            </span>
          );
        } else {
          setIsLoading(false);
          setShowError(true);
          responseData.Status.TransactionReasonCode
            ? setErrorMessage(
              responseData.Status.TransactionReasonCode.Description
            )
            : setErrorMessage(responseData.Status.TransactionStatus);
        }
      } else {
        setIsLoading(false);
        setShowError(true);
        setErrorMessage(
          `The system was unable to produce a response, please try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
          );
        }
      } catch (err) {
      setIsLoading(false);
      
      setErrorMessage(
        `The system was unable to produce a response, please try again. If you continue to experience unexpected issues, you can try again later or contact Crescent Bank Funding Department at 844-462-2866 for immediate assistance.`
      );
      setShowError(true);
      throw err;
    }
  };
  return location.state ? (
    <>
      {' '}
      {isLoading ? (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 8, offset: 2 }}>
            <div className='row'>
              <div
                className='d-flex justify-content-center'
                style={{ marginTop: '6rem', marginBottom: '7rem' }}
              >
                <Spinner animation='border' className='spinner-element' />
              </div>
            </div>
          </Col>
        </Container>
      ) : (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 6, offset: 3 }}>
            <div className='info-section-block'>
              <p className='mt-3 mb-1 offset-md-1 col-md-10 subTitle1'>
                My Auto Loan Portal
              </p>
              <p className='mb-2 offset-md-1 col-md-10 subInfo1'>
                Please enter the authentication code you received.
              </p>
              <Row className='mb-4'>
                <Col md={{ span: 8, offset: 1 }}>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row className='mb-2'>
                      <Form.Group as={Col} md='12' controlId='passCode'>
                        <Form.Label className='formLabel'>
                          Authentication Code
                        </Form.Label>
                        <Form.Control
                          placeholder='Authentication Code'
                          name='passCode'
                          type='text'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.passCode}
                        />
                        <div className='invalid-feedback1 formLabel'>
                          {' '}
                          {formik.touched.passCode && formik.errors.passCode ? (
                            <div>{formik.errors.passCode}</div>
                          ) : null}{' '}
                        </div>
                        <Row className='mt-3'>
                          <Form.Label className='resendOtp'>
                            If you have not received your authentication code,
                            please click&nbsp;
                            <span className='pointer' onClick={goBackOtp1Page}>
                              <b>here</b>
                            </span>
                            &nbsp;to resend.
                          </Form.Label>
                        </Row>
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col>
                        <div className='invalid-feedback1 formLabel'>
                          {showError ? (
                            <div className='errorMessage'>{errorMessage}</div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Button className='' type='submit' >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Container>
      )}
    </>
  ) : (
    modalShow && (
      <CustomModal
        show={modalShow}
        title='Unauthorized Access'
        text='please fill the applicant information to access this page'
        handleClose={handleClose}
      />
    )
  );
};
export default Otp2;

import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Container, Col, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  setIsLoading,
  setFrontSide,
  setBackSide,
} from '../../Redux/trueId/TrueIdActions';
import Spinner from 'react-bootstrap/Spinner';
import isiOS15Version from '../helpers/CheckIos15versionDevice';
import idPicture from '../../id-pic.png';
import '../../App.css';
import localforage from 'localforage';
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";

function CapturePhoto() {
  const history = useHistory();
  const dispatch = useDispatch();

  const overallState = useSelector((state) => state);
  const { isLoading } = overallState.trueIdinfo;
  const {
    ApplicationNumber,
    ApplicantID,
    ApplicantFirstName,
    ApplicantLastName,
    CellPhoneNumber,
    EmailAddress,
  } = overallState.otpProcess;

  const cameraRef = useRef();
  const playerRef = useRef();
  const acuantCanvasRef = useRef();
  const newImageRef = useRef();

  const [placeHolder, setPlaceHolder] = useState('block');
  const [visualElements, setVisualElements] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [showAutoCapture, setShowAutoCapture] = useState(true);
  let liveCaptureFailed = false;
  var currentResult = {};
  const location = useLocation();
  let orient = '';

  const front = location.state ? location.state.front : false;
  const back = location.state ? location.state.back : false;
  const handleClose = () => setShowModal(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sharpness, setSharpness] = useState(null);
  const [glareDetected, setGlareDetected] = useState(false);
  const [glare, setGlare] = useState(null);

  useEffect(() => {
    const nonIOSDevice = /iphone|ipod|ipad/.test(window.navigator.userAgent.toLowerCase())
    window.loadAcuantSdk();
    if (!nonIOSDevice) {
      setShowAutoCapture(false)
    }
    // if (isiOS15Version()) {
    //   setShowAutoCapture(false);
    // }
  }, []);

  function closeCamera() {
    //console.log(cameraRef.current);
    //cameraRef.current.style.display="none"
  }

  function openCamera() {
    startCamera();
  }

  const options = {
    text: {
      NONE: 'ALIGN',
      SMALL_DOCUMENT: 'MOVE CLOSER',
      GOOD_DOCUMENT: null, //null countdown
      BIG_DOCUMENT: 'TOO CLOSE',
      CAPTURING: 'CAPTURING',
      TAP_TO_CAPTURE: 'TAP TO CAPTURE',
    },
  };

  var cameraCallback = {
    onCaptured: function (response) {
      //set loading icon true
      dispatch(setIsLoading(true));
      if (response) {
        //cameraRef.current.style.display = 'none';
        console.log('cameracallback-oncaptured', response);
      } else {
        console.log('cameracallback-oncaptured error, please try again');
      }
    },
    onCropped: function (response) {
      //set loading icon false
      dispatch(setIsLoading(false));
      if (response) {
        console.log('cameracallback-oncropped', response);
        closeCamera();
        drawImageOnResult(response);
      } else {
        console.log('cameracallback-oncropped error,please try again');
        startCamera();
        // or push to /welcome page
      }
      // closeCamera();
    },
    onFrameAvailable: function (response) {
      //get each frame if needed
      //console.log('cameracallback-onFrameAvailable', response);
    },
  };
  const onError = (error, code) => {
    cameraRef.current.style.display = 'none';
    // liveCaptureFailed = true;
    // console.log(error);
    // history.push('/welcome');
    console.error(error, code);
    liveCaptureFailed = true;
    setVisualElements(true);
    if (code === window.AcuantJavascriptWebSdk.REPEAT_FAIL_CODE) { //live capture was started after previous failure leading to manual capture, show any elements you may have hidden as the user can cancel the prompt and could otherwise end up on a blank page.
      // cameraBtn.style.display = "block";
    } else {
      // modal.style.display = "block";
      switch (code) {
        case window.AcuantJavascriptWebSdk.SEQUENCE_BREAK_CODE: //camera froze/crashed; usually happens due to an ios 15 bug
          // modalText.innerHTML = "Live Camera failed. Start manual capture.";
          setErrorMessage(`Live Camera failed. Start manual capture.`)
          setShowAutoCapture(false)
          break;
        case window.AcuantJavascriptWebSdk.START_FAIL_CODE: //camera not supported or permission not granted
          // modalText.innerHTML = "Live Camera failed to open. Start manual capture.";
          setErrorMessage(`Live Camera failed to open. Start manual capture.`)
          setShowAutoCapture(false)
          break;
        default: //unknown error (shouldn't happen)
          // modalText.innerHTML = "Unknown camera failure. Start manual capture.";
          setErrorMessage(`Unknown camera failure. Start manual capture.`)
          setShowAutoCapture(false)
          break;
      }

    }
    //Manual capture button displayed in the modal, incase of error
    //setShowModal(true);
  };

  function startCamera() {
    // if (window.AcuantCameraUI) {
    currentResult = {};
    // window.loadAcuantSdk();

    if (
      window.AcuantCamera.isCameraSupported &&
      !window.AcuantCamera.isIOSWebview &&
      !liveCaptureFailed
    ) {
      //Hide visual elements while camera opens
      setVisualElements(false);
      cameraRef.current.style.display = 'block';
      window.AcuantCameraUI.start(cameraCallback, onError, options);
    } else {
      startManualCapture();
    }
    // }
  }

  function startManualCapture() {
    setShowError(false);
    window.AcuantCamera.startManualCapture({
      onCaptured: function (response) {
        if (window.innerHeight < window.innerWidth) {
          orient = 'landscape';
        }
        //set loading icon true
        dispatch(setIsLoading(true));
        if (response) {
          console.log('startManualCapture-onCaptured', response);
        } else {
          console.log('startManualCapture-onCaptured error please try again');
        }
      },
      onCropped: function (response) {
        //set loading icon false
        if (response && response.image) {
          console.log('startManualCapture-onCropped', response);
          drawImageOnResult(response);
          // console.log('startManualCapture-onCropped', response);
        } else {
          // closeCamera();
          setShowError(true);
          console.log('startManualCapture-onCropped else cond', response);
          // startCamera();
        }
        closeCamera();
        dispatch(setIsLoading(false));

      },

      onFrameAvailable: function (response) {
        //this is optional
        console.log(response, '');
        //Use only if you plan to display custom UI elements in addition to what is already displayed by the camera.
        // response = {
        //   type: Number,
        //   dimensions: Object,
        //   dpi: Number,
        //   isCorrectAspectRatio: Boolean,
        //   points: Array,
        //   state: (Number) => {
        //     NO_DOCUMENT: 0,
        //     SMALL_DOCUMENT: 1,
        //     BIG_DOCUMENT: 2,
        //     GOOD_DOCUMENT: 3;
        //   },
        // };
      },
    });
  }

  /* function end() {
       window.AcuantJavascriptWebSdk.end();
     }*/

  function drawImageOnResult(result) {
    if (result && result.image) {
      var image = new Image();
      image.onload = function () { };
      image.width = result.image.width;
      image.height = result.image.height;
      image.src = result.image.data;
      currentResult = result.image.data;
      //setPlaceHolder('none');
      const metrics = {
        glare: result.glare,
        sharpness: result.sharpness,
        dpi: result.dpi,
        // orient: orient,
      };

      if (front) {
        dispatch(setFrontSide(true));
        console.log('file1',currentResult)
        localforage.setItem('frontImage', currentResult);
        history.push('/confirm', { frontConfirm: true, metrics: metrics });
      }
      if (back) {
        dispatch(setBackSide(true));
        localforage.setItem('backImage', currentResult);
        history.push('/confirm', { backConfirm: true, metrics: metrics });

      }
    }
  }

  const buttonText = 'Auto Capture';
  const statusText = front
    ? "Front of Driver's License"
    : "Back of Driver's License";
    const handleFileInputChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.addEventListener("load", function() {
        // Convert the file to base64 and log the result
        const base64String = reader.result.replace("data:", "").replace(/^.+,/, "");
        console.log(base64String);
      });
    
      // Read the selected file as a data URL
      reader.readAsDataURL(file);
      console.log('our image',file,URL.createObjectURL(file))
      setSelectedFile(URL.createObjectURL(file));

      // Calculate sharpness
      const sharpnessCanvas = document.createElement("canvas");
      const sharpnessCtx = sharpnessCanvas.getContext("2d");
      const sharpnessImg = new Image();
      sharpnessImg.onload = () => {
          sharpnessCanvas.width = sharpnessImg.width;
          sharpnessCanvas.height = sharpnessImg.height;
          sharpnessCtx.drawImage(sharpnessImg, 0, 0);

          const imageData = sharpnessCtx.getImageData(0, 0, sharpnessCanvas.width, sharpnessCanvas.height);
          const data = imageData.data;
          let sum = 0;
          for (let i = 0; i < data.length; i += 4) {
              const r = data[i];
              const g = data[i + 1];
              const b = data[i + 2];
              sum += (0.2126 * r + 0.7152 * g + 0.0722 * b);
          }
          const avg = sum / (data.length / 4);
          setSharpness(avg);
      };
      sharpnessImg.src = URL.createObjectURL(file);

      // Check for glare
      const glareCanvas = document.createElement("canvas");
      const glareCtx = glareCanvas.getContext("2d");
      const glareImg = new Image();
      glareImg.onload = () => {
          glareCanvas.width = glareImg.width;
          glareCanvas.height = glareImg.height;
          glareCtx.drawImage(glareImg, 0, 0);

          const imageData = glareCtx.getImageData(0, 0, glareCanvas.width, glareCanvas.height);
          const data = imageData.data;
          let total = 0;
          for (let i = 0; i < data.length; i += 4) {
              total += data[i] + data[i + 1] + data[i + 2];
          }
          const avg = total / (data.length / 4);
          setGlare(avg)
          setGlareDetected(avg > 230);
      };
      glareImg.src = URL.createObjectURL(file);
  };
  return (
    <>
      {isLoading ? (
        <Container className='min-vh-100'>
          <Col
            className='inner-wrapper'
            xs={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
          >
            <div className='row'>
              <div
                className='d-flex justify-content-center'
                style={{ marginTop: '6rem' }}
              >
                <Spinner animation='border' className='spinner-element' />
              </div>
            </div>
          </Col>
        </Container>
      ) : (
        <div className='offset-md-3 col-md-6 min-vh-100'>
          {visualElements ? (
            <>
              <div className='row'>
                <div
                  className='col-md-12 subTitle1'
                  style={{ margin: '40px 0 20px 0', fontSize: '24px' }}
                >
                  {statusText}
                </div>
              </div>
              {showError && <div><span style={{ color: "red", marginTop: "-4px" }}>
                <IoWarningOutline />
              </span>
                <span>
                  &nbsp;Image quality did not meet minimum requirements.
                  Please recapture.
                </span></div>}
              <div className='row'>
                <div className='col-md-12'>
                  <img
                    style={{ display: placeHolder }}
                    className='offset-2 col-8 offset-md-3 col-md-6 mt-4'
                    src={idPicture}
                    alt='placeHolder'
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className='row'>
            <div className='col-md-12'>
              <img
                ref={newImageRef}
                style={{ display: 'none' }}
                className='offset-2 col-8 offset-md-4 col-md-4 mt-4'
                src=''
                alt='result'
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <div id="acuant-camera"></div>
            </div>
          </div>
          <div
            className='mt-5'
            ref={cameraRef}
            id='result-id'
            style={{ display: 'none' }}
          >

            <div className='row' >
              <div className='col-md-12 text-center' >
                {/* <div id="acuant-camera"></div> */}
                <div className=''>
                  <img width='100%'
                    id="result-id-img"
                    height='auto' style={{ display: "block" }}
                    ref={acuantCanvasRef}
                  ></img>

                </div>
              </div>
            </div>
          </div>
          {visualElements ? (
            <>
              <div style={{ alignItems: "center", paddingTop: "5px" }}>
                {errorMessage}
              </div>
              <div>
              {/* <input
                type='file'
                accept="image/*"
                name='Manual Capture'
                capture="camera"
                ref={fileInputRef}
                onChange={handleFileInputChange}
                className='btn btn-primary mt-4 mb-4 trueIdButton'

            /> */}
                <label
                  className='btn btn-primary mt-4 mb-4 trueIdButton'
                  onClick={startManualCapture}
                >
                  Manual Capture
                </label>
              </div>

              {/* {
              showAutoCapture ? (
                <div>
                  <label
                    className='btn btn-primary mt-4 mb-4 trueIdButton'
                    onClick={openCamera}
                  >
                    {buttonText}
                  </label>
                </div>
              ) : (
                <>

                  <div style={{ alignItems: "center", paddingTop: "5px" }}>
                    {errorMessage}
                  </div>
                  <div>
                    <label
                      className='btn btn-primary mt-4 mb-4 trueIdButton'
                      onClick={startManualCapture}
                    >
                      Manual Capture
                    </label>
                  </div>
                </>
              )} */}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default CapturePhoto;

import React, { useState, useEffect, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { Form, Container, Row, Col, Button, FormControl } from "react-bootstrap";
import "../../App.css";
import DatePicker from "react-datepicker";
import { isZipValid, isValidSSN } from "../helpers/Mask";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setDefaultProcessingStatus, setPersonalInfoStatus,
} from "../../Redux/nextForms/NextFormActions";
import USAStateList from "./USAStateList";
import NumberFormat from "react-number-format";
import NextStepsButton from "./NextStepsButton";
import ProcessingScreen from "./ProcessingScreen";
import { postAllFormsInfo, savePersonalInfoForm } from "../../Redux/nextForms/AllFormsActions";
import { getAllFormsData, saveAllFormsData, getMatchingAddress } from "../../api/Services";
import AutoLoanContactInfo from "./AutoLoanContactInfo";
import NotificationBar from "./NotificationBar";
import DOTSGlobalAddressComplete from '../../Calldotsac.js';
import { getRDPEventLogger } from '../../api/Services';

const PersonalInfo = () => {
  let history = useHistory();
  const overallState = useSelector((state) => state);
  const { totalFormsInfo: { allFormsInfo }, otpProcess, processingStatus } = overallState;
  const { personalInfoForm } = allFormsInfo;
  const { ApplicationNumber, ApplicantID } = otpProcess;
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(personalInfoForm.firstName);
  const [middleName, setMiddleName] = useState(personalInfoForm.middleName);
  const [lastName, setLastName] = useState(personalInfoForm.lastName);
  const [suffix, setSuffix] = useState(personalInfoForm.suffix);
  const [selectedDate, setSelectedDate] = useState(personalInfoForm.dob);
  const [ssn, setSSN] = useState(personalInfoForm.ssn);
  const [address, setAddress] = useState(personalInfoForm.street);
  const [state, setState] = useState(personalInfoForm.state);
  const [city, setCity] = useState(personalInfoForm.city);
  const [zipCode, setZipCode] = useState(personalInfoForm.zipCode);
  const [maxDate, setMaxDate] = useState(new Date());
  const [showMessage, setShowMessage] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [addressesList, setAddressesList] = useState([]);
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    selectedDate: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
  });
  useEffect(() => {
    DOTSGlobalAddressComplete.load();
    DOTSGlobalAddressComplete.listen("populate", function (address, variations) {
      setAddress(address.Address1);
      setErrors({ ...errors, address: !address.Address1 ? 'Please enter address' : '' })

      setCity(address.Locality);
      setZipCode(address.PostalCode);
      setState(address.AdminArea);
    });
  }, [])
  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 2000);
  }, [showMessage]);

  //TODO remove current from the parameter
  const savePersonalInfoForm = async (currentPage) => {
    const personalInfo = {
      city,
      dob: selectedDate,
      firstName,
      lastName,
      middleName,
      ssn,
      state,
      street: address,
      suffix,
      zipCode,
    };


    const payload = {
      allFormsInfo: {
        ...allFormsInfo,
        personalInfoForm: personalInfo
      },
      currentPage,
      applicantId: ApplicantID,
      applicationNumber: ApplicationNumber
    }
    dispatch(
      postAllFormsInfo({ ...allFormsInfo, personalInfoForm: personalInfo })
    );
    const response = await saveAllFormsData(payload);
    return response;
  }

  const onSaveClick = async (e) => {
    e.preventDefault();
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "personalInfoSaved",
      eventDescription: '',
      eventData: "",
    }
    rdpEventHandler(eventPayload)
    savePersonalInfoForm('personalinfo').then(res => {

      if (res === "Success") {
        setShowMessage(true)
      }
    });
  }
  const rdpEventHandler = (eventPayload) => {
    getRDPEventLogger(eventPayload).then((res) => {
      console.log(eventPayload.eventName);
    });
  }
  const onNextClick = () => {
    const inputAddress = document.getElementById('iAddress1');
    const inputCity = document.getElementById('iLocality');
    const inputZipCode = document.getElementById('iPostal');
    const inputState = document.getElementById('iAdminArea');
    setAddress(inputAddress.value);
    setCity(inputCity.value);
    setZipCode(inputZipCode.value);
    setState(inputState.value);
    const eventPayload = {
      applicationNumber: ApplicationNumber,
      applicantId: ApplicantID,
      linkToken: " ",
      eventName: "personalInfoCompleted",
      eventDescription: '',
      eventData: "",
    }

    if (validate()) {
      rdpEventHandler(eventPayload)
      savePersonalInfoForm('contactinfo').then(res => {
        if (res === "Success") {
          const { personalInfo: { status } } = processingStatus;
          if (status !== "completed") {
            dispatch(setPersonalInfoStatus("completed"));
          }
          eventPayload.eventName = 'contactInfoStarted'
          rdpEventHandler(eventPayload)

          history.push('/contactinfo');
        } else {
          // show error message
        }
      });
    }
  }
  const addressHandleChange = (e) => {
    const inputAddress = document.getElementById('iAddress1');
    setAddress(inputAddress.value);
  }
  const ziphandler = (e) => {
    setZipCode(e.target.value);
    setErrors({ ...errors, zipCode: !e.target.value ? 'Please enter Zipcode' : '' })

  }

  const cityHandler = (e) => {
    setCity(e.target.value);
    setErrors({ ...errors, city: !e.target.value ? 'Please enter City' : '' })
  }



  const stateHandler = (e) => {
    setState(e.target.value);
    setErrors({ ...errors, state: !e.target.value ? 'Please enter State' : '' })
  }



  const validate = () => {
    const errors = {};

    if (!firstName) {
      errors.firstName = "Please enter your first name";
    } else if (firstName.length < 2) {
      errors.firstName = "Must be 2 characters or more";
    }

    if (!lastName) {
      errors.lastName = "Please enter your last name";
    } else if (lastName.length < 2) {
      errors.lastName = "Must be 2 characters or more";
    }


    if (!selectedDate) {
      errors.selectedDate = "Please enter your date of birth";
    }

    if (!ssn) {
      errors.ssn = "Please enter a valid SSN";
    }
    //current physical address

    if (address === '') {
      errors.address = "Please enter your street address";
    }
    else if (address.length < 6) {
      errors.address = "Please enter more that 5 digits";
    }

    if (zipCode === '') {
      errors.zipCode = "Please enter your zipCode";
    } else if (!isZipValid(zipCode)) {
      errors.zipCode = "Please enter a valid zipCode";
    }

    if (!city) {
      errors.city = "Please enter your city";
    }
    if (!state) {
      errors.state = "Please enter your state";
    }
    setErrors(errors)

    if (errors.firstName || errors.lastName || errors.suffix || errors.selectedDate
      || errors.ssn || errors.address || errors.zipCode || errors.city || errors.state) {
      return false;
    }

    return true
  };
  const handleDateChange = (event) => {
    const input = event.target.value;
    let formattedDate = input;

    // Remove any forward slashes from the input
    formattedDate = formattedDate.replace(/\//g, '');

    // Add forward slashes in the appropriate positions (e.g., MM/DD/YYYY)
    if (formattedDate.length > 1) {
      formattedDate = formattedDate.slice(0, 2) + '/' + formattedDate.slice(2);
    }
    if (formattedDate.length > 4) {
      formattedDate = formattedDate.slice(0, 5) + '/' + formattedDate.slice(5, 9);
    }
    setSelectedDate(formattedDate);
    setErrors({ ...errors, selectedDate: !formattedDate ? 'Please select DOB' : '' })

  };
  const formatSSN = (value) => {
    const inputValue = value;
    const cleanedSSN = inputValue.replace(/\D/g, '');
    const formattedSSN = cleanedSSN.replace(/^(\d{0,3})(\d{0,2})(\d{0,4})$/, (match, p1, p2, p3) => {
      let formattedValue = '';
      if (p1) formattedValue += p1;
      if (p2) formattedValue += `-${p2}`;
      if (p3) formattedValue += `-${p3}`;
      return formattedValue;
    });
    setSSN(formattedSSN);
  };
  return (<Container>
    <div className='mb-3'>
      <ProcessingScreen />
      <Row>
        <h3 className='mt-3 mb-4'> Personal Information Form</h3>
      </Row>

      <Row className='' style={{ marginBottom: "3rem" }}>
        <Col md={{ span: 6, offset: 3 }}>
          <Form >
            <AutoLoanContactInfo />

            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='firstName'>
                <Form.Label className='formLabel'>
                  First Name<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='first name'
                  name='firstName'
                  type='text'
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setErrors({ ...errors, firstName: !e.target.value ? 'Please enter first name' : '' })
                  }}
                  data-id='fname'
                  value={firstName}
                  className='bg-img-none bg-transparent'
                />
                <div className='invalid-feedback1 formLabel'>

                  {errors.firstName}

                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='middleName'>
                <Form.Label className='formLabel'>
                  Middle Name<span >&nbsp;</span>
                </Form.Label>
                <Form.Control
                  placeholder='middle name'
                  name='middleName'
                  type='text'
                  data-id='mname'
                  onChange={(e) => {
                    setMiddleName(e.target.value);
                  }}
                  value={middleName}
                />
                <div className='invalid-feedback1 formLabel'>
                  {errors.middleName}

                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='lastName'>
                <Form.Label className='formLabel'>
                  Last Name<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='last name'
                  name='lastName'
                  type='text'
                  data-id='lname'
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setErrors({ ...errors, lastName: !e.target.value ? 'Please enter last name' : '' })

                  }}
                />
                <div className='invalid-feedback1 formLabel'>
                  {errors.lastName}
                </div>
              </Form.Group>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='suffix'>
                <Form.Label className='formLabel'>
                  Suffix
                  <span className='star-required'>&nbsp;</span>
                </Form.Label>
                {/* <select //className="form"
                  name='suffix'
                  value={suffix}
                  onChange={(e) => {
                    setSuffix(e.target.value);
                  }}
                  data-id='suffix'
                //style={{ display: "block" }}
                >
                  <option value='None' label='None' />
                  <option value='JR' label='JR' />
                  <option value='SR' label='SR' />
                  <option value='I' label='I' />
                  <option value='II' label='II' />
                  <option value='III' label='III' />
                  <option value='IV' label='IV' />
                  <option value='V' label='V' />
                </select> */}
                <Form.Select value={suffix}
                  onChange={(e) => {
                    setSuffix(e.target.value);
                  }}>
                  <option value='None'>None</option>
                  <option value='JR' >JR</option>
                  <option value='SR' >SR</option>
                  <option value='I'>I</option>
                  <option value='II' >II</option>
                  <option value='III'>III</option>
                  <option value='IV' >IV</option>
                  <option value='V' >V</option>
                </Form.Select>
                <div className='invalid-feedback1 formLabel'>
                  {errors.suffix}
                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='dob'>
                <Form.Label className='formLabel'>
                  Date Of Birth<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='MM/DD/YYYY'
                  name='dob'
                  type='text'
                  data-id='dpicker'
                  value={selectedDate}
                  onChange={handleDateChange}
                />
                <div className='invalid-feedback1 formLabel'>
                  {errors.selectedDate}
                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' controlId='ssn'>
                <Form.Label className='formLabel'>
                  SSN<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <FormControl
                  type="text"
                  name='ssn'
                  placeholder='###-##-####'
                  className='form-control'
                  value={ssn}
                  data-id='ssn'
                  maxLength={11}
                  onChange={(e) => {
                    formatSSN(e.target.value)
                    setErrors({ ...errors, ssn: e.target.value.length < 9 ? 'Please enter valid SSN' : '' })
                  }
                  }
                />

                <div className='invalid-feedback1 formLabel'>
                  {errors.ssn}

                </div>
              </Form.Group>
            </Row>
            <Row>
              <h5 style={{ textAlign: "center", marginBottom: "1rem" }}>
                <b>Current Physical Address</b>
              </h5>
            </Row>

            <Row className='mb-3'>
              <Form.Group as={Col} md='12'
                className="AddressInputBlock"
              >
                <Form.Label className='formLabel'
                  id="lAddress2"
                >
                  Street Address<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='street name'
                  name='street'
                  type='text'

                  onInput={(e) => {
                    addressHandleChange(e)
                  }}

                  value={address}
                  data-id='address'
                  id="iAddress1"
                  className="AddressInputs"
                  autoComplete="new-password"
                />

                <div className='invalid-feedback1 formLabel'>

                  {errors.address}
                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12'
                // controlId='zipCode'
                id="sPostal" className="AddressInputBlock">
                <Form.Label className='formLabel AddressLabels' id="lPostal">
                  Zip<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='12345 or 12345-6789'
                  name='zipCode'
                  type='text'
                  maxLength={10}
                  data-id='zipcode'
                  onChange={(e) => ziphandler(e)}                    // onBlur={formik.handleBlur}
                  value={zipCode}
                  id="iPostal"
                  className="AddressInputs"
                />
                <div className='invalid-feedback1 formLabel'>
                  {errors.zipCode}
                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' id="sLocality" className="AddressInputBlock">
                <Form.Label className='formLabel' id="lAdminArea" >
                  City<span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  placeholder='city'
                  name='city'
                  type='text'
                  data-id='city'
                  id="iLocality"
                  className="AddressInputs"
                  onInput={(e) => {
                    cityHandler(e)

                  }} value={city}

                />
                <div className='invalid-feedback1 formLabel'>
                  {errors.city}

                </div>
              </Form.Group>
            </Row>
            <Row className='mb-3'>
              <Form.Group as={Col} md='12' id="sAdminArea" className="AddressInputBlock" >
                <Form.Label className='formLabel AddressLabels' id="lAdminArea" >
                  State
                  <span className='star-required'>&nbsp;*</span>
                </Form.Label>
                <Form.Control
                  name='state'
                  type='text'
                  value={state}
                  id="iAdminArea"
                  className="AddressInputs"
                  onInput={(e) => {
                    stateHandler(e)

                  }} style={{ display: "block" }}

                >

                </Form.Control>

                <div className='invalid-feedback1 formLabel'>
                  {errors.state}
                </div>
              </Form.Group>
            </Row>



            <Row style={{ marginBottom: '10px' }}>
              <Col className='d-flex justify-content-between'>
                <Button type='submit' onClick={onSaveClick}>Save</Button>
              </Col>
            </Row>
            {showMessage && <Row>
              <NotificationBar variant="success" message="Your data is saved!" />
            </Row>}
          </Form>
        </Col>
      </Row>
      <Row>
        <Col className='offset-md-3 col-md-6 d-flex justify-content-between'>
          <NextStepsButton text="Previous" isDisable={true} />
          <NextStepsButton text="Next" isDisable={false} onNextClick={onNextClick} />
        </Col>

      </Row>
    </div>
  </Container >
  );
}
export default PersonalInfo;

import { actionTypes } from "./NextFormActionTypes";

const initialState = {
  // trueId: {
  //   status: "completed",
  //   title: "True Id",
  // },
  personalInfo: {
    status: "inProgress",
    title: "Personal Info",
  },
  contactInfo: {
    status: "begin",
    title: "Contact Info & Employment",
  },
  residenceInfo: {
    status: "begin",
    title: "Residence",
  },
  vehicleInfo: {
    status: "begin",
    title: "Vehicle & Payments",
  },
  financingInfo: {
    status: "begin",
    title: "Financing Details",
  },
  contractAck: {
    status: "begin",
    title: "Contract Acknowledgement",
  },
};

export const mainFormReducer = (state, action) => {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case actionTypes.SET_PERSONAL_INFO_STATUS:
      const updatedContactStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.contactInfo.status;

      return {
        ...state,
        personalInfo: {
          ...state.personalInfo,
          status: action.payload,
        },
        contactInfo: {
          ...state.contactInfo,
          status: updatedContactStatus,
        },
      };

    case actionTypes.SET_CONTACT_INFO_STATUS:
      const updatedResidenceStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.residenceInfo.status;
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          status: action.payload,
        },
        residenceInfo: {
          ...state.residenceInfo,
          status: updatedResidenceStatus,
        },
      };

    case actionTypes.SET_RESIDENCE_INFO_STATUS:
      const updatedVehicleStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.vehicleInfo.status;
      return {
        ...state,
        contactInfo: {
          ...state.contactInfo,
          status:
            action.payload === "begin"
              ? "inProgress"
              : state.contactInfo.status,
        },
        residenceInfo: {
          ...state.residenceInfo,
          status: action.payload,
        },
        vehicleInfo: {
          ...state.vehicleInfo,
          status: updatedVehicleStatus,
        },
      };
    case actionTypes.SET_VEHICLE_INFO_STATUS:
      const updatedFinancingStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.financingInfo.status;
      return {
        ...state,
        residenceInfo: {
          ...state.residenceInfo,
          status:
            action.payload === "begin"
              ? "inProgress"
              : state.residenceInfo.status,
        },
        vehicleInfo: {
          ...state.vehicleInfo,
          status: action.payload,
        },
        financingInfo: {
          ...state.financingInfo,
          status: updatedFinancingStatus,
        },
      };
    case actionTypes.SET_FINANCING_INFO_STATUS:
      const updatedContractStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.contractAck.status;
      return {
        ...state,
        vehicleInfo: {
          ...state.vehicleInfo,
          status:
            action.payload === "begin"
              ? "inProgress"
              : state.vehicleInfo.status,
        },
        financingInfo: {
          ...state.financingInfo,
          status: action.payload,
        },
        contractAck: {
          ...state.contractAck,
          status: updatedContractStatus,
        },
      };

    case actionTypes.SET_CONTRACT_ACK_STATUS:
      /*const updatedContractStatus =
        action.payload === "completed"
          ? "inProgress"
          : state.contractAck.status;*/
      return {
        ...state,
        financingInfo: {
          ...state.financingInfo,
          status:
            action.payload === "begin"
              ? "inProgress"
              : state.financingInfo.status,
        },
        contractAck: {
          ...state.contractAck,
          status: action.payload,
        },
        /* contractAck: {
          ...state.contractAck,
          status: updatedContractStatus,
        },*/
      };

    case actionTypes.SET_DEFAULT_PROCESSING_STATUS:
      return initialState;

    default:
      return state;
  }
};

import React from 'react';
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NextStepsButton = ({ text, isDisable, onNextClick }) => {

    const history = useHistory();
    
    return (
        <div>
            <Button
                type='text'
                disabled={isDisable}
                style={{ backgroundColor: "grey" }}
                onClick={onNextClick}
            >
                {text}
            </Button>
        </div>
    );
}

export default NextStepsButton;
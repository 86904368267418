import { useHistory, useLocation } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setFrontSide, setBackSide } from "../../Redux/trueId/TrueIdActions";
import isiOS15Version from "../helpers/CheckIos15versionDevice";
import localforage from "localforage";
import { useState, useEffect } from "react";
import { IoWarningOutline, IoInformationCircleOutline } from "react-icons/io5";
import CustomModal from "../helpers/CustomModal";
import "../../App.css";

const Confirm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const overallState = useSelector((state) => state);
  const { ApplicationNumber, ApplicantID } = overallState.otpProcess;
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const frontConfirm = location.state ? location.state.frontConfirm : false;
  const backConfirm = location.state ? location.state.backConfirm : false;
  let validated = "";
  if (location.state) {
    validated = checkMetrics(location.state.metrics);
    //?'null':'Image quality did not meet minimum requirements. Please recapture'
  }

  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [metricsValidation, setMetricsValidation] = useState(true);
  const [rotatePic, setRotatePic] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const orient = location?.state?.metrics?.orient || "";
  const modalData = (
    <ul>
      <li>Ensure all texts are visible</li>
      <li>
        Align the image properly (not tilted or skewed), and all the 4 corners
        are covered while taking the picture.
      </li>
      <li>Ensure that there is no glare or shaded areas on the image.</li>
      <li>
        Make sure that your image is in the right format (JPG or PNG) and at
        least 300dpi.
      </li>
      <li>
        Make sure the image is captured with minimal background (likewise that
        the whole of the document image can be seen and is not partly obscured
        in any way).
      </li>
    </ul>
  );

  function checkMetrics(result) {
    return result.sharpness >= 25 && result.glare >= 25;
  }

  useEffect(() => {

    if (isiOS15Version() && orient === "landscape") {

      setRotatePic(true);
    }
  }, [orient]);

  useEffect(() => {
    if (!frontImage) {
      getFrontImage();
    }
    if (!backImage) {
      getBackImage();
    }
    setMetricsValidation(validated);
  }, [frontImage, backImage]);

  const handleClose = () => setModalShow(false);

  function goWithButtonText() {
    if (backConfirm) {
      /*start Api call*/
      const formattedFront = frontImage.split("base64,")[1];
      const formattedBack = backImage.split("base64,")[1];
      const requestBody = {
        Document: {
          Front: formattedFront,
          Back: formattedBack,
        },
        ApplicationNumber: ApplicationNumber,
        ApplicantID: ApplicantID,
      };
      const url = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LNSTORAGE_IMAGE}`;
      //Request header
      const configHeaders = {
        headers: {
          //"Access-Control-Allow-Origin": "*",
          // "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: process.env.REACT_APP_BASIC_AUTHTOKEN,
        },
      };
      postData(url, requestBody, configHeaders);

      /*end Api call*/
    }

    if (frontConfirm) {
      history.push("/capturephoto", { back: true });
    }
  }

  const postData = async (url, body, headers) => {
    try {
      setIsLoading(true);
      const response = await axios.post(url, body, headers);
      if (response && response.data) {
        const responseData = await response.data;

        setIsLoading(false);
        window.AcuantJavascriptWebSdk.endWorkers();
        dispatch({ type:'TRUE_ID_COMPLETE', payload: {} });
        history.push("/verifydocument", { responseData: responseData })

      }
    } catch (error) {

      window.AcuantJavascriptWebSdk.endWorkers();
      setIsLoading(false);
      history.push("/result", { error: JSON.parse(JSON.stringify(error)) });
    }
  };

  const retry = () => {
    if (frontConfirm) {
      dispatch(setFrontSide(false));
      localforage.setItem("frontImage", "");
      history.push("/capturephoto", { front: true });
    }
    if (backConfirm) {
      dispatch(setBackSide(false));
      localforage.setItem("backImage", "");
      history.push("/capturephoto", { back: true });
    }
  };

  const getFrontImage = async () => {
    const fImage = await localforage.getItem("frontImage");
    setFrontImage(fImage);
  };

  const getBackImage = async () => {
    const bImage = await localforage.getItem("backImage");
    setBackImage(bImage);
  };

  return (
    <>
      {isLoading ? (
        <Container className='min-vh-100'>
          <Col className='inner-wrapper' md={{ span: 8, offset: 2 }}>
            <div className='row' style={{ fontSize: "12px" }}>
              <div className='d-flex justify-content-center'>
                Please wait, while we process your information.
              </div>
            </div>
            <div className='row'>
              <div
                className='d-flex justify-content-center'
                style={{ marginTop: "6rem", marginBottom: "7rem" }}
              >
                <Spinner animation='border' className='spinner-element' />
              </div>
            </div>
          </Col>
        </Container>
      ) : (
        <div className='container min-vh-100'>
          <div>
            <p className='subTitle1' style={{ fontSize: "24px" }}>
              {frontConfirm
                ? "Front of Driver's License"
                : "Back of Driver's License"}
            </p>

            <div className='mb-2 offset-md-1 col-md-10 subInfo1 mt-1'>
              {metricsValidation ? null : (
                <>
                  <span style={{ color: "red", marginTop: "-4px" }}>
                    <IoWarningOutline />
                  </span>
                  <span>
                    &nbsp;Image quality did not meet minimum requirements.
                    Please recapture.
                  </span>
                  <br />
                  <span
                    className='info-icon'
                    onClick={() => {
                      setModalShow(!modalShow);
                    }}
                  >
                    <IoInformationCircleOutline />
                  </span>
                  <span>
                    &nbsp; Please click on the info icon for instructions.
                  </span>{" "}
                  <div className='mb-2 offset-md-1 col-md-10 subInfo1 mt-1'>
                    {modalShow && (
                      <CustomModal
                        show={modalShow}
                        title='Image upload/capture instructions'
                        text={modalData}
                        handleClose={handleClose}
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='row'>
            <div className={`col-md-12 ${rotatePic ? "rotate" : ""}`}>
              {frontConfirm ? (
                <img
                  src={frontImage}
                  className='col-8 offset-md-0 col-md-4 mt-4'
                  alt='resultfront'
                />
              ) : (
                <img
                  src={backImage}
                  className='col-8 offset-md-0 col-md-4 mt-4'
                  alt='resultback'
                />
              )}
            </div>
          </div>

          {metricsValidation ? (
            <>
              <div className='row'>
                <div className='col-md-12'>
                  <label
                    className='btn btn-primary trueIdButton mt-4 mb-1'
                    onClick={goWithButtonText}
                  >
                    &nbsp;Continue&nbsp;
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <label
                    className='btn btn-primary mt-4 mb-3 trueIdButton'
                    onClick={retry}
                  >
                    Recapture
                  </label>
                </div>
              </div>
            </>
          ) : (
            <div className='row'>
              <div className='col-md-12'>
                <label
                  className='btn btn-primary mt-4 mb-3 trueIdButton'
                  onClick={retry}
                >
                  Recapture
                </label>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Confirm;
